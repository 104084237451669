import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { getReferredUserDeposits, getUserDetails } from "../services/authservices"


function Team() {
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    botStatus: "DeActive",
    displayname: null,
    referralCount: 0,
    directReferralCount: 0,
    indirectReferralCount: 0,
    profits: 0,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
            referralCount: data.referralCount,
            directReferralCount: data.directReferralCount,
            indirectReferralCount: data.indirectReferralCount,
            botStatus: data.botStatus,
            profits: data.profits
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
            referralCount: 0,
            directReferralCount: 0,
            indirectReferralCount: 0,
            profits: 0,
          });
        }
      } catch (error) {
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
          referralCount: 0,
          directReferralCount: 0,
          indirectReferralCount: 0,
          profits: 0,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        referralCount: 0,
        directReferralCount: 0,
        indirectReferralCount: 0,
        profits: 0,
      });
    }
    setLoading(false);
  };
  const [teamData, setTeamData] = useState({
    totalTeamAUM: 0,
    directMemberAUM: 0,
    indirectMemberAUM: 0,
  });

  const fetchReferredUserDeposits = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const data = await getReferredUserDeposits(token);
      if (data) {
        setTeamData({
          totalTeamAUM: data.totalDeposits || 0,
          directMemberAUM: data.directDeposits || 0,
          indirectMemberAUM: data.indirectDeposits || 0,

        });
      }
    } catch (error) {
      console.error("Error fetching referred user deposits:", error);
    }
  };

  useEffect(() => {
    fetchReferredUserDeposits();
  }, []);

  useEffect(() => {
    fetchUserDetails();

  }, []);
  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="team-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <div className="row team_main">
                <div className="col-lg-4 team_left_col_top">
                  <span className="team_top_left">Manage Your Team</span>
                </div>
                <div className="col-lg-8 m-top-20 pad_team_460">
                  <div className="team_top_right">
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">Team Members</span>
                        {user.referralCount != null && user.referralCount != undefined ? (
                        <span className="team_topinner_subhead">{user.referralCount}</span>
                        ) : (
                          <span className="team_topinner_subhead">0</span>
                        )}
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Cumulative profit
                        </span>
                        {user.profits != null && user.profits != undefined ? (
                        <span className="team_topinner_subhead">{user.profits.toFixed(6)}</span>
                        ) : (
                          <span className="team_topinner_subhead">0.00</span>
                        )}
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Direct members
                        </span>
                        {user.directReferralCount != null && user.directReferralCount != undefined ? (
                        <span className="team_topinner_subhead">{user.directReferralCount}</span>
                        ) : (
                          <span className="team_topinner_subhead">0</span>
                        )}
                      </div>
                    </div>
                    <div className="team_top_right_inmain">
                      <div className="team_top_right_leftcolor"></div>
                      <div className="team_topright_inner">
                        <span className="team_topinner_head">
                          Indirect members
                        </span>
                        {user.indirectReferralCount != null && user.indirectReferralCount != undefined ? (
                        <span className="team_topinner_subhead">{user.indirectReferralCount}</span>
                        ) : (
                          <span className="team_topinner_subhead">0</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="team_second_main">
                <div className="row ">
                  <div className="col-lg-4 pad-0-560">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Total Team AUM
                        </span>
                      </div>
                      {teamData.totalTeamAUM != null && teamData.totalTeamAUM != undefined ? (
                      <span className="refer_count_number">{teamData.totalTeamAUM.toFixed(2)} USDT</span>
                      ) : (
                        <span className="refer_count_number">0.00 USDT</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 pad-0-560 m-top-20">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />{" "}
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Direct Member AUM
                        </span>
                      </div>
                      {teamData.directMemberAUM != null && teamData.directMemberAUM != undefined ? (
                      <span className="refer_count_number">{teamData.directMemberAUM.toFixed(2)} USDT</span>
                      ) : (
                        <span className="refer_count_number">0.00 USDT</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 pad-0-560 m-top-20">
                    <div className="team_secondin_box">
                      <div className="team_secondin_box_head">
                        <img
                          src={require("../assests/icons/refer-teamember.png")}
                          alt="Refer"
                          className="refer_icon_inbox icons-light"
                        />{" "}
                        <img
                          src={require("../assests/icons/refer-teamember-dark.webp")}
                          alt="Refer"
                          className="refer_icon_inbox icons-dark"
                        />
                        <span className="top_startgy_inbottomadvance">
                          {" "}
                          Indirect Members AUM
                        </span>
                      </div>
                      {teamData.indirectMemberAUM != null && teamData.indirectMemberAUM != undefined ? (
                      <span className="refer_count_number">{teamData.indirectMemberAUM.toFixed(2)} USDT</span>
                      ) : (
                        <span className="refer_count_number">0.00 USDT</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="refer_third_main pb-0">
                    <span className="home_botstatus_span"> Members Rank</span>
                    <div className="table-responsive table-borderless home_recent_table">
                      <table className="table">
                        <thead>
                          <th className="text_left">SI.no</th>
                          <th>Name</th>
                          <th className="text_right">Rank</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <span> No Records Found</span>
                            </td>
                          </tr> */}
                          {/* <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr>
                          <tr className="border_bottom_none">
                            <td className="text_left">
                              <span>01</span>
                            </td>
                            <td>
                              <span> Kathryn Murphy</span>
                            </td>
                            <td className="text_right">
                              <span>Rank 01</span>
                            </td>
                          </tr> */}
                        {/* </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
