import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import authService from "../services/authservices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import {ScaleLoader} from 'react-spinners'

function Assets() {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalBalanceusd, setTotalBalanceusd] = useState(0);
  const [profitData, setProfitdata] = useState(null);
  const [fuelWallet, setFuelWallet] = useState(0);
  const [fuelWalletUsd, setFuelWalletUsd] = useState(0);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [depositHistory, setDepositHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const combinedHistory = [
    ...depositHistory.map((item) => ({ ...item, type: "Deposit" })),
    ...withdrawalHistory.map((item) => ({ ...item, type: "Withdrawal" })),
  ];
  
  
  combinedHistory.sort((a, b) => {
    if (a.type === "Deposit" && b.type === "Withdrawal") return -1;
    if (a.type === "Withdrawal" && b.type === "Deposit") return 1;
  
    return new Date(b.timestamp || b.date) - new Date(a.timestamp || a.date);
  });
  
  const totalRecords = combinedHistory.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentRecords = combinedHistory.slice(startIndex, endIndex);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await authService.getDepositHistory();
        if (response.status === true) {
          setDepositHistory(response.data);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    const fetchAssets = async () => {
      try {
        const response = await authService.getAssets();
        if (response.status === true && response.totalBalanceUSDT !== undefined) {
          console.log(response.totalBalanceUSDT,"response.totalBalanceUSDT")
          setTotalBalance(parseFloat(response?.totalBalanceUSDT));
          setTotalBalanceusd(parseFloat(response?.totalBalanceUSDTusd));
          setFuelWallet(parseFloat(response?.userWalletFuelAmount));
          setFuelWalletUsd(parseFloat(response?.totalInUSD));
          setProfitdata(response?.profits)
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    const fetchWithdrawalHistory = async () => {
      try {
        setLoading(true);
        const response = await authService.getWithdrawalHistory();
        if (response.status === true) {
          setWithdrawalHistory(response.data);
          setLoading(false);
        } else {
          console.error("Unexpected response format:", response);
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching withdrawal history:", error);
        setLoading(false)
      }
    };

    // Call the APIs once when the component is mounted
    fetchBalance();
    fetchWithdrawalHistory();
    fetchAssets();
  }, []);
  const handleCopy = (txID) => {
    if (handleCopy.toastVisible) return;
  
    handleCopy.toastVisible = true; 
  
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);
  
    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        })
        .finally(() => {
          setTimeout(() => {
            handleCopy.toastVisible = false;
          }, 2000); 
        });
    }, 300); 
  };
  

  const handlePageChange = (event, value) => {
    setCurrentPage(value);

  };

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="asset_row_resp">
        <div className="container">
          <div className="home_main">
            <div className="row asset_main">
              <div className="col-xl-4 col-lg-6 col-md-8 col-sm-9">
                <div className="asset_top_center">
                  <span className="asset_top_head">
                    Take Control of Your Future with Assets Today
                  </span>
                  <div className="asset_top_btns">
                    <Link to="/deposit">
                      <button className="asset_top_deposit">Deposit</button>
                    </Link>
                    <Link to="/withdraw">
                      <button className="asset_top_Withdraw">Withdraw</button>
                    </Link>
                    <Link to="/transferwallet">
                      <button className="asset_top_Withdraw">Transfer</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="asset_second_main">
                  <div className="asset_second_left">
                    <span className="footer_head">Total Value</span>
                    <div className="home_user_name">
                      <span className="name_16_31bd65">{totalBalance.toFixed(4)} USDT</span>
                      <span className="home_userbln_head">~{totalBalanceusd.toFixed(2)} USD</span>
                    </div>
                  </div>
                  <div className="asset_second_left">
                    <span className="footer_head">Fuel Wallet</span>
                    <div className="home_user_name">
                      <span className="name_16_31bd65">{fuelWallet.toFixed(4)} USDT</span>
                      <span className="home_userbln_head">~{fuelWalletUsd.toFixed(2)} USD</span>
                    </div>
                  </div>
                  <span className="asset_slash">|</span>
                  <div className="asset_second_right">
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">Today’s PNL</span>
                      <span className="footer_head">{((profitData?.todayProfit) ? profitData?.todayProfit : '0.00')}%</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">Yesterday’s PNL</span>
                      <span className="footer_head">{((profitData?.yesterdayProfit ? profitData?.yesterdayProfit : '0.00'))}%</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">30 Days PNL</span>
                      <span className="footer_head">{((profitData?.monthlyProfit) ? profitData?.monthlyProfit : '0.00')}%</span>
                    </div>
                    <div className="asset_second_insmall">
                      <span className="home_userbln_head">Overall PNL</span>
                      <span className="footer_head">{((profitData?.overallProfit) ? profitData?.overallProfit : '0.00')}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="asset_third_main pb-0">
                  <span className="home_botstatus_span">Histories</span>
                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                      <div className="loader_main loader_mainChange">
                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text_left">Currency</th>
                              <th>Amount</th>
                              <th>Type</th>
                              <th>Transaction ID</th>
                              <th>Date And Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentRecords.length > 0 ? (
                              currentRecords.map((record, index) => (
                                <tr key={`record-${index}`}>
                                  <td>
                                    <div className="home_recent_firstdata">
                                      <img
                                        src={require("../assests/usdt-logo.png")}
                                        alt="USDT"
                                        className="usdt_home_recent"
                                      />
                                      <span>USDT</span>
                                    </div>
                                  </td>
                                  <td>
                                    <span>{record.amount}</span>
                                  </td>
                                  <td>
                                    <span>{record.type}</span>
                                  </td>
                                  <td>
                                    <div className="home_uid_main">
                                      <span>
                                        {record.txID && record.txID !== "Pending"
                                          ? record.txID
                                          : record.transactionId && record.transactionId !== "Pending"
                                            ? record.transactionId
                                            : "-"}
                                      </span>
                                      {(record.txID && record.txID !== "Pending") ||
                                        (record.transactionId && record.transactionId !== "Pending") ? (
                                        <i
                                          className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                          onClick={() => handleCopy(record.txID || record.transactionId)}
                                        ></i>
                                      ) : null}
                                    </div>
                                  </td>

                                  <td>
                                    <span>
                                      {new Date(record.timestamp || record.date).toLocaleString()}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  <span className="depo_no_record">
                                    No assets history available.
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {totalPages > 1 && (
                          <div className="pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                size="small"
                                sx={{
                                  "& .MuiPaginationItem-root": {
                                      color: "#fff",
                                  },
                                  "& .Mui-selected": {
                                      backgroundColor: "#ffc630 !important",
                                      color: "#000",
                                      "&:hover": {
                                          backgroundColor: "#ffc630",
                                          color: "#000",
                                      },
                                  },
                                  "& .MuiPaginationItem-ellipsis": {
                                      color: "#fff",
                                  },
                                  "& .MuiPaginationItem-icon": {
                                      color: "#fff",
                                  },
                              }}
                              />
                            </Stack>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assets;
