import React, { useEffect } from "react";
import useState from "react-usestateref";

import authService from "../services/authservices";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import dayjs from "dayjs";

function SupportHistory() {

    const [supportHistory, setSupportHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPageSp, setCurrentPageSp] = useState(1);
    const [totalPagesSp, setTotalPagesSp] = useState(0);
    const [limitSp, setLimitSp] = useState(10);



    useEffect(() => {
        fetchSupportHistory(currentPageSp, limitSp);
    }, [currentPageSp, limitSp]);


    const fetchSupportHistory = async (page, limit) => {
        try {
            const response = await authService.getsupport(page, limit);
            if (response.status) {
                setSupportHistory(response.data);
                setTotalPagesSp(Math.ceil(response.total / limit));
            } else {
                console.error("Failed to fetch support history");
            }
        } catch (error) {
            console.error("Error fetching support history:", error);
        }
    };
    const handlePageChangeSp = (event, value) => {
        setCurrentPageSp(value);
    };

    return (

        <div className="recent_depo_main">
            <div className="manage_btn_row">
                <span className="home_right_marketspan"> Support Histories</span>
                <Link to="/support">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">Back to support</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                </Link>
            </div>
            <div className="recent_depo_tablemain pb-0">
                <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                        <p className="depo_no_record">Loading...</p>
                    ) : (
                        <>
                         <table className="table">
                            <thead>
                                <tr>
                                    <th>Ticket ID</th>
                                    <th>Date & Time</th>
                                    <th>Subject</th>
                                    <th>Category</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {supportHistory.length > 0 ? (
                                    supportHistory.slice(0, 5).map((ticket) => (
                                        <tr key={ticket._id}>
                                            <td><span>{ticket._id}</span></td>
                                            <td><span>{ticket.created_at}</span></td>
                                            <td><span>{ticket.subject}</span></td>
                                            <td><span>{ticket.category}</span></td>
                                            <td><span>{ticket.status === "0" ? "Open" : "Closed"}</span></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No support tickets found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {totalPagesSp > 1 && (
                                                                                  <div className="pagination history_pagination_all">
                                                                                      <Stack spacing={2}>
                                                                                          <Pagination
                                                                                              count={totalPagesSp}
                                                                                              page={currentPageSp}
                                                                                              onChange={handlePageChangeSp}
                                                                                              size="small"
                                                                                              sx={{
                                                                                                "& .MuiPaginationItem-root": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .Mui-selected": {
                                                                                                    backgroundColor: "#ffc630 !important",
                                                                                                    color: "#000",
                                                                                                    "&:hover": {
                                                                                                        backgroundColor: "#ffc630",
                                                                                                        color: "#000",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiPaginationItem-ellipsis": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .MuiPaginationItem-icon": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                            }}
                                                                                          />
                                                                                      </Stack>
                                                                                  </div>
                                                                              )}
                        </>
                       
                        
                    )}
                </div>
            </div>
        </div>
    )
}



export default SupportHistory;