import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useNavigate } from "react-router-dom";
import { verifyAuth } from "../services/authservices";

function TermsCondition() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const advanceSectionRef = useRef(null);
  const [userAuth, setAuth, userAuthref] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (advanceSectionRef.current) {
        const sectionTop = advanceSectionRef.current.offsetTop;
        const sectionHeight = advanceSectionRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    checkuserAuth();
  }, [0]);

  const checkuserAuth = async () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      try {
        const data = await verifyAuth(token);
        if (data && data.status) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error("Error auth user details:", error);
        setAuth(false);
      }
    } else {
      setAuth(false);
    }
  };
  useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

  const nav_page = () => {
    if(!userAuthref.current){
      navigate("/register");
    }else{
      navigate("/home");
    }
  };

  return (
    <>
      <main className="wyscale_landing_main">
        <section>
          <Header />
        </section>
        <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content" id="terms-condition">
            <h2>Term’s and conditions</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                    Welcome to WYscale Crypto Mutual Fund, a website-hosted user interface (the "Interface" or "App") provided by WYscale ("we", "our", or "us"). The Interface provides access to a decentralised investment opportunity on various blockchain that allows users to trade/invest in certain digital assets. The Interface is one, but not the exclusive, means of accessing the Protocol.
                    </p>
                    <p>
                    This Terms of Service Agreement (the "Agreement") explains the terms and conditions by which you may access and use the Interface. You must read this Agreement carefully. By accessing or using the Interface, you signify that you have read, understand, and agree to be bound by this Agreement in its entirety. If you do not agree, you are not authorized to access or use the Interface and should not use the Interface.{" "}
                    </p>
                    <h3>Service Introduction </h3>
                    <h4>
                    NOTICE:
                    </h4>

                    <p>
                    Please read this Agreement carefully as it governs your use of the Interface. This Agreement contains important information, including a binding arbitration provision and a class action waiver, both of which impact your rights as to how disputes are resolved. The Interface is only available to you — and you should only access the Interface — if you agree completely with these terms.
                    </p>

                    <h5>
                    Modification of this Agreement:{" "}
                    </h5>
                    <p>We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any modifications, we will notify you by updating the date at the top of the Agreement and by maintaining a current version of the Agreement at [ ]. All modifications will be effective when they are posted, and your continued accessing or use of the Interface will serve as confirmation of your acceptance of those modifications. If you do not agree with any modifications to this Agreement, you must immediately stop accessing and using the Interface.</p>

                    <h5>
                    Eligibility:{" "}
                    </h5>
                    <p>
                    To access or use the Interface, you must be able to form a legally binding contract with us. Accordingly, you represent that you are at least the age of majority in your jurisdiction (e.g., eighteen years old) and have the full right, power, and authority to enter into and comply with the terms and conditions of this Agreement on behalf of yourself and any company or legal entity for which you may access or use the Interface.{" "}
                    </p>

                    <p>
                    You further represent that you are not (a) the subject of economic or trade sanctions administered or enforced by any governmental authority or otherwise designated on any list of prohibited or restricted parties or (b)you represent that your access and use of the Interface will fully comply with all applicable laws and regulations, and that you will not access or use the Interface to conduct, promote, or otherwise facilitate any illegal activity.{" "}
                    </p>
                    
                    <h4 className="mt-4">
                      {" "}
                      Additional Rights{" "}
                    </h4>

                    <p>
                    We reserve the following rights, which do not constitute obligations of ours: (a) with or without notice to you, to modify, substitute, eliminate or add to the Interface; (b) to review, modify, filter, disable, delete and remove any and all content and information from the Interface; and (c) to cooperate with any law enforcement, court or government investigation or order or third party requesting or directing that we disclose information or content or information that you provide.
                    </p>

                    <h4>Assumption of Risk </h4>
                    <p>
                    By accessing and using the Interface, you represent that you are financially and technically sophisticated enough to understand the inherent risks associated with using cryptographic and blockchain-based systems, and that you have a working knowledge of the usage and intricacies of digital assets such as bitcoin (BTC), ether (ETH), and other digital tokens such as those following the Ethereum Token Standard (ERC-20). In particular, you understand that blockchain-based transactions are irreversible.
                    </p>

                    <p>
                    You further understand that the markets for these digital assets are highly volatile due to factors including (but not limited to) adoption, speculation, technology, security, and regulation. You acknowledge and accept that the cost and speed of transacting with cryptographic and blockchain-based systems such as Ethereum are variable and may increase dramatically at any time. You further acknowledge and accept the risk that your digital assets may lose some or all of their value while they are supplied to the Protocol through the Interface, you may suffer loss due to the fluctuation of prices of tokens in a trading pair or liquidity pool, and, especially in expert modes, experience significant price slippage and cost. You further acknowledge that we are not responsible for any of these variables or risks, do not own or control the Protocol, and cannot be held liable for any resulting losses that you experience while accessing or using the Interface. Accordingly, you understand and agree to assume full responsibility for all of the risks of accessing and using the Interface to interact with the Protocol.
                    </p>
                    

                    <h4>Entire Agreement </h4>
                    <p>
                    These terms constitute the entire agreement between you and us with respect to the subject matter hereof. This Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications and other understandings (if any) relating to the subject matter of the terms.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="footer_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="getting_card_content">
                  <img
                    src={require("../assests/Logo.webp")}
                    alt="logo"
                    className="footer_logo"
                  />
                  <span className="getcard_sub_subhead padding-right">
                    Unlock the Power of Automated Crypto Trading with Wyscale.
                    Leverage cutting-edge algorithms, AI-driven strategies, and
                    smooth API integration across top exchanges. Enjoy 24/7
                    trading automation while maintaining complete control over
                    your assets. Begin your journey to smarter trading with
                    Wyscale!
                  </span>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Quick Links</span>
                  <div className="footer_links_div">
                    <Link to="/home">
                      <span className="footer_links_span">Home</span>
                    </Link>
                    {/* <Link to="">
                      <span className="footer_links_span">Portfolio</span>
                    </Link> */}
                    <Link to="/strategies">
                      <span className="footer_links_span">Strategies</span>
                    </Link>
                    <Link to="/assets">
                      <span className="footer_links_span">Assets</span>
                    </Link>
                    <Link to="/referral">
                      <span className="footer_links_span">Refer & Earn</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Pages</span>
                  <div className="footer_links_div">
                    <Link to="/termscondition" target="_blank">
                      <span className="footer_links_span">Terms and Conditions</span>
                    </Link>
                    <Link to="/privacypolicy" target="_blank">
                      <span className="footer_links_span">Privacy Policy</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">About Us</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Social Media</span>
                  <div className="footer_links_div">
                    <Link to="">
                      <span className="footer_links_span">LinkedIn</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Instagram</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Facebook</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Twitter</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="footer_last">
          <span className="footer_copyrights">
            ©2025 WYScale. All Rights Reserved.
          </span>
        </div>


      </main>
    </>
  );
}

export default TermsCondition;
