import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect, useCallback } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "react-toastify/dist/ReactToastify.css";
import service from "../services/authservices"
import Tab from "react-bootstrap/Tab";
import { ScaleLoader } from 'react-spinners'
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import authService from "../services/authservices";
import dayjs from "dayjs";
import { getUserDetails } from '../services/authservices';

const HistoryAll = () => {
    const [supportHistory, setSupportHistory] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [loader, setLoader] = useState(false);
    const [toastVisible , setToastVisible] = useState(false);
    const [loginHistory, setLoginHistory] = useState([]);
    const [withdrawaddress, setWithdrawAddress] = useState([]);
    
    const [getpool, setGetpool] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(10);
    const [currentPageWd, setCurrentPageWd] = useState(1);
    const [totalPagesWd, setTotalPagesWd] = useState(0);
    const [limitWd, setLimitWd] = useState(10);
    const [currentPageDp, setCurrentPageDp] = useState(1);
    const [totalPagesDp, setTotalPagesDp] = useState(0);
    const [limitDp, setLimitDp] = useState(10);
    const [currentPageSp, setCurrentPageSp] = useState(1);
    const [totalPagesSp, setTotalPagesSp] = useState(0);
    const [limitSp, setLimitSp] = useState(10);
    const [currency, setCurrency] = useState("USDC");
    const [network, setNetwork] = useState("BEP20");
    const [addressData, setAddressData] = useState(null);
    const [currentPageCp, setCurrentPageCp] = useState(1);
    const [totalPagesCp, setTotalPagesCp] = useState(0);
    const [limitCp, setLimitCp] = useState(10);
    const [referralHistory, setReferralHistory] = useState([]);
    const [currentPageRef, setCurrentPageRef] = useState(1);
    const [totalPagesRef, setTotalPagesRef] = useState(0);
    const [limitRef, setLimitRef] = useState(10);
    const [currentPagewithadd, setCurrentPagewithadd] = useState(1);
    const [totalPageswithadd, setTotalPageswithadd] = useState(0);
    const [limitwithadd, setLimitwithadd] = useState(10);


    const [loading, setLoading] = useState(true);
    const coinTyperef = useRef(currency);
    useEffect(() => { 
        fetchSupportHistory(currentPageSp, limitSp);
        getPool(currentPageCp, limitCp);
        fetchLoginHistory(currentPage, limit);
        fetchWithdrawalHistory(currentPageWd, limitWd);
        fetchTransactions(currentPageDp, limitDp);
        fetchReferralHistory(currentPageRef, limitRef);
        fetchWithdrawalAddress(currentPagewithadd,limitwithadd)
    }, [
        currentPageSp, limitSp,
        currentPageCp, limitCp,
        currentPage, limit,
        currentPageWd, limitWd,
        currentPageDp, limitDp,
        currentPageRef, limitRef,
        currentPagewithadd,limitwithadd
    ]);

    const handleSoftDelete = async (addressId) => {
        console.log(addressId,"addressId");
        
        setWithdrawAddress(prev => prev.filter(address => address._id !== addressId));
          const data = {
            addressId: addressId
          }
          const response = await service.softDeleteAddress(data);
          if (response.status) {
            showSuccessToast(response.message);
          } else {
            showErrorToast(response.message);
          }
      };

    const truncateText = (text, maxLength) => {
        if (!text || typeof text !== "string") {
          return "--";
        }
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + " . . .";
        }
        return text;
      };


    const handlePageChange = (event, value, eventName) => {
        switch (eventName) {
            case "Login":
                setCurrentPage(value);
                break;
            case "Withdraw":
                setCurrentPageWd(value);
                break;
            case "Deposit":
                setCurrentPageDp(value);
                break;
            case "Support":
                setCurrentPageSp(value);
                break;
            case "Collective Sharing":
                setCurrentPageCp(value);
                break;
            case "Referral":
                setCurrentPageRef(value);
                break;
                case "Withdraw Address":
                    setCurrentPagewithadd(value);
                    break;
            default:
                break;
        }
    };


    const handleCopy = (txID) => {
        if (handleCopy.toastVisible) return; 
        
        handleCopy.toastVisible = true; 
      
        if (handleCopy.timeout) clearTimeout(handleCopy.timeout);
      
        handleCopy.timeout = setTimeout(() => {
          navigator.clipboard
            .writeText(txID)
            .then(() => {
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">
                    Transaction ID copied to clipboard!
                  </p>
                </div>
              );
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">Failed to copy Transaction ID</p>
                </div>
              );
            })
            .finally(() => {
              setTimeout(() => {
                handleCopy.toastVisible = false;  
              }, 2000); 
            });
        }, 300);  // 300ms debounce time
      };
      

    const fetchReferralHistory = async (page, limit) => {
        try {
            setLoader(true);

            const data = await service.getReferralHistory(page, limit);

            if (data && data.status && Array.isArray(data.referralHistory)) {
                console.log(data.referralHistory, "Referral History");

                setReferralHistory(data.referralHistory);
                setTotalPagesRef(Math.ceil(data.total / limit));
            } else {
                setReferralHistory([]);
            }
        } catch (error) {
            console.error("Error fetching referral history:", error);

            setReferralHistory([]);
        } finally {
            setLoader(false);
        }
    };


     const fetchUserDetails = async () => {
        const token = localStorage.getItem("token");
        if (token) {

          try {
            setLoader(true);
            const data = await getUserDetails(token);
            if (data && data.status) {
                setLoader(false);
            } else {
                setLoader(false);
            }
          } catch (error) {
            setLoader(false);
          }
        } else {
            setLoader(false);
        }
        setLoading(false);
      };

    const getPool = async (page, limit) => {
        try {
            setLoader(true);
            const response = await service.getPool(page, limit);
            setLoader(false);
            if (response.status) {
                setGetpool(response.data);
                setTotalPagesCp(Math.ceil(response.total / limit));
                setLoader(false);

            } else {
                setLoader(false);
            }
        } catch (error) { setLoader(false) }
    };

    const fetchLoginHistory = async (page, limit) => {

        try {
            setLoader(true)
            const response = await service.getLoginHistory(page, limit);
            if (response.status) {
                setLoginHistory(response.data);
                setTotalPages(Math.ceil(response.total / limit));
                setLoader(false)
            } else {
                console.error("Failed to fetch login history:", response.Message);
                setLoader(false)
            }
        } catch (error) {
            console.error("Error fetching login history:", error);
            setLoader(false)
        } finally {
            setLoader(false);
        }
    };

    const fetchWithdrawalHistory = async (page, limit) => {
        setLoader(true)
        const response = await service.getWithdrawalHistory(page, limit);
        if (response.status) {
            setWithdrawalHistory(response.data);
            setTotalPagesWd(Math.ceil(response.total / limit));
            setLoader(false)
        } else {
            console.error("Failed to fetch withdrawal history:", response.Message);
            setLoader(false)
        }
    };

    const fetchWithdrawalAddress = async (page, limit) => {
        setLoader(true)
        const response = await service.getWithdrawAddress(page, limit);
        if (response.status) {
            setWithdrawAddress(response.data);
            setTotalPageswithadd(Math.ceil(response.total / limit));
            setLoader(false)
        } else {
            console.error("Failed to fetch withdrawal Address:", response.Message);
            setLoader(false)
        }
    };

    const fetchSupportHistory = async (page, limit) => {
        try {
            setLoader(true)
            const response = await service.getsupport(page, limit);
            if (response.status) {
                setSupportHistory(response.data);
                setTotalPagesSp(Math.ceil(response.total / limit));
                setLoader(false)
            } else {
                console.error("Failed to fetch support history");
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.error("Error fetching support history:", error);
        }
    };

    const showErrorToast = (message) => {
              if (!toastVisible) {
                setToastVisible(true); // Set toast as visible
                toast.error(
                  <div className="toast-ml">
                    <h4 className="toast-title">Error!</h4>
                    <p className="toast-message">{message}</p>
                  </div>,
                  {
                    onClose: () => setToastVisible(false), // Reset toastVisible on close
                    autoClose: 3000, // Optional: auto-close after 3 seconds
                  }
                );
              }
            };
          
            const showSuccessToast = (message) => {
              if (!toastVisible) {
                setToastVisible(true);
                toast.success(
                  <div className="toast-ml">
                    <h4 className="toast-title">Success!</h4>
                    <p className="toast-message">{message}</p>
                  </div>,
                  {
                    onClose: () => setToastVisible(false), // Reset state on close
                    autoClose: 3000, // Auto-close after 3 seconds
                  }
                );
              }
            };

    const fetchTransactions = async () => {
        setLoading(true);
        try {
            const response = await service.getDepositHistory();
            if (response.status) {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    // Filter unique transactions based on transactionId
                    const uniqueTransactions = response.data.filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.transactionId === value.transactionId)
                    );
                    setTransactions(uniqueTransactions);
                } else {
                    setTransactions([]);
                }
            } else {
                setTransactions([]);
            }
        } catch (error) {
            console.error("Error fetching transactions:", error);
            setTransactions([]);
            showErrorToast('Failed to load transactions')
        } finally {
            setLoading(false);
        }
    };



    const handleClickmethod = () => {
        console.log('onclick')
    }
    return (
        <>
            <section>
                <Header />
            </section>

            <div className="withdraw-section">
                <div className="container">
                    <div className="row prof_row_sm">
                        <div className="col-lg-2 dispaly_none_1200">
                            <Sidebar />
                        </div>

                        <div className="col-xl-10 col-lg-12 deposit_pad_top">
                            <span className="profile_span">History</span>
                            <div className="col-md-12 col-lg-12 verfication_kyc_col">
                                <div className="pad-l-r">
                                    <section className="asset_section">
                                        <>
                                            <>
                                                <Tabs
                                                    defaultActiveKey="home"
                                                    id="uncontrolled-tab-example"
                                                    className="deposit_accounthead"
                                                    onClick={handleClickmethod}
                                                >
                                                    <Tab eventKey="home" title="Login">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>IP Address</th>
                                                                                            <th>Date & Time</th>
                                                                                            <th>OS</th>
                                                                                            <th>Device</th>
                                                                                            <th>Browser</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : loginHistory.length > 0 ? (
                                                                                            loginHistory.map((login, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="table-flex">
                                                                                                        <span>{login.ipAddress}</span>
                                                                                                    </td>
                                                                                                    <td> <span>
                                                                                                        {isNaN(new Date(login.createdTime))
                                                                                                            ? "null"
                                                                                                            : dayjs(login.createdTime).format(
                                                                                                                "DD MMMM YYYY, hh:mm A"
                                                                                                            )}
                                                                                                    </span></td>
                                                                                                    <td className="table-flex">  <span>{login.os || "N/A"}</span></td>
                                                                                                    <td> <span>{login.device || "N/A"}</span></td>
                                                                                                    <td><span>{login.browser || "N/A"}</span></td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                                                                    <span className="depo_no_record">
                                                                                                        No login history found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                                {totalPages > 1 && (
                                                                                    <div className="pagination history_pagination_all ">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPages}
                                                                                                page={currentPage}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Login')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="profile" title="Deposit">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                                style={{ height: "max-content" }}
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text_left">Currency</th>
                                                                                            <th>Date & Time</th>
                                                                                            <th>Network</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Transaction ID</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {transactions.length > 0 ? (
                                                                                            transactions.slice(0, 5).map((transaction, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <div className="home_recent_firstdata">
                                                                                                            <img
                                                                                                                src={require("../assests/usdt-logo.png")}
                                                                                                                alt="USDT"
                                                                                                                className="usdt_home_recent"
                                                                                                            />
                                                                                                            <span>{transaction.currency}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>
                                                                                                            {isNaN(new Date(transaction.date))
                                                                                                                ? "null"
                                                                                                                : dayjs(transaction.date).format(
                                                                                                                    "DD MMMM YYYY, hh:mm A"
                                                                                                                )}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>{transaction.network}</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>{transaction.amount}</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="home_uid_main">
                                                                                                            <span>{transaction.transactionId}</span>
                                                                                                            <i
                                                                                                                className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                                                                                                onClick={() => {
                                                                                                                    handleCopy(transaction.transactionId);
                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (
                                                                                            <tr>
                                                                                                <td colSpan="5">
                                                                                                    <span className="depo_no_record">
                                                                                                        No transactions found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>

                                                                                {/* Pagination */}
                                                                                {totalPagesDp > 1 && (
                                                                                    <div className="pagination history_pagination_all">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPagesDp}
                                                                                                page={currentPageDp}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Deposit')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab
                                                        eventKey="WithdrawHistory"
                                                        title="Withdraw"
                                                    >
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                                style={{ height: "max-content" }}
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text_left">Currency</th>
                                                                                            <th>Date & Time</th>
                                                                                            <th>Network</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Transaction ID</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : withdrawalHistory.length > 0 ? (
                                                                                            withdrawalHistory.map((withdrawal, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="table-flex">
                                                                                                        <div className="home_recent_firstdata">
                                                                                                            <img
                                                                                                                src={require("../assests/usdt-logo.png")}
                                                                                                                alt="USDT"
                                                                                                                className="usdt_home_recent"
                                                                                                            />
                                                                                                            <span>{withdrawal.currency}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{isNaN(new Date(withdrawal.date))
                                                                                                        ? "null"
                                                                                                        : dayjs(withdrawal.date).format(
                                                                                                            "DD MMMM YYYY, hh:mm A"
                                                                                                        )}</td>
                                                                                                    <td className="table-flex">{withdrawal.network}</td>
                                                                                                    <td>{withdrawal.amount}</td>
                                                                                                    <td><div className="home_uid_main">
                                                                                                        <span>
                                                                                                            {withdrawal.txID && withdrawal.txID !== "Pending"
                                                                                                                ? truncateText(withdrawal?.txID, 10)
                                                                                                                : withdrawal.transactionId && withdrawal.transactionId !== "Pending"
                                                                                                                    ? truncateText(withdrawal?.transactionId, 10)
                                                                                                                    : "-"}
                                                                                                        </span>
                                                                                                        {(withdrawal.txID && withdrawal.txID !== "Pending") ||
                                                                                                            (withdrawal.transactionId && withdrawal.transactionId !== "Pending") ? (
                                                                                                            <i
                                                                                                                className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                                                                                                onClick={() => handleCopy(withdrawal.txID || withdrawal.transactionId)}
                                                                                                            ></i>
                                                                                                        ) : null}
                                                                                                    </div></td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                                                                    <span className="depo_no_record">
                                                                                                        No withdrawal found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                                {totalPagesWd > 1 && (
                                                                                    <div className="pagination history_pagination_all ">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPagesWd}
                                                                                                page={currentPageWd}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Withdraw')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="CollectiveSharing" title="Profit">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Reward Amount</th>
                                                                                            <th>Date & Time</th>
                                                                                            <th>Type</th>
                                                                                            <th>Tier Type</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : getpool.length > 0 ? (
                                                                                            getpool.map((pool, index) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="table-flex">
                                                                                                        <span>{pool.rewardAmount}</span>
                                                                                                    </td>
                                                                                                    <td> <span>
                                                                                                        {isNaN(new Date(pool.createdAt))
                                                                                                            ? "null"
                                                                                                            : dayjs(pool.createdAt).format(
                                                                                                                "DD MMMM YYYY, hh:mm A"
                                                                                                            )}
                                                                                                    </span></td>
                                                                                                    <td> <span>{pool.type || "N/A"}</span></td>
                                                                                                    <td><span>{pool.tierType || "N/A"}</span></td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                                                                    <span className="depo_no_record">
                                                                                                        No profit history found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                                {totalPagesCp > 1 && (
                                                                                    <div className="pagination history_pagination_all ">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPagesCp}
                                                                                                page={currentPageCp}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Collective Sharing')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="Referal" title="Referral">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>SI.no</th>
                                                                                            <th>User Name</th>
                                                                                            <th>Date & Time</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : referralHistory.length > 0 ? (
                                                                                            referralHistory.map((referral, index) => (
                                                                                                <tr key={referral.uuid}>
                                                                                                    <td >
                                                                                                        <span>{index + 1}</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>{referral.displayname}</span>
                                                                                                    </td>
                                                                                                    <td className="">
                                                                                                        <span>
                                                                                                            {new Date(referral.createdAt).toLocaleString()}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                                                                    <span className="depo_no_record">
                                                                                                        No referral history found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                                {totalPagesRef > 1 && (
                                                                                    <div className="pagination history_pagination_all ">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPagesRef}
                                                                                                page={currentPageRef}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Referral')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="support" title="Support">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                                style={{ height: "max-content" }}
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Ticket ID</th>
                                                                                            <th>Date & Time</th>
                                                                                            <th>Subject</th>
                                                                                            <th>Category</th>
                                                                                            <th>Status</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : supportHistory.length > 0 ? (
                                                                                            supportHistory.map((ticket) => (
                                                                                                <tr key={ticket._id}>
                                                                                                    <td className="table-flex">{ticket._id}</td>
                                                                                                    <td>{ticket.created_at}</td>
                                                                                                    <td className="table-flex">{truncateText(ticket.subject, 15)}</td>
                                                                                                    <td>{ticket.category}</td>
                                                                                                    <td>{ticket.status === "0" ? "Open" : "Closed"}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        ) : (

                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                                                                    <span className="depo_no_record">
                                                                                                        No support tickets found
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                                {totalPagesSp > 1 && (
                                                                                    <div className="pagination history_pagination_all">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPagesSp}
                                                                                                page={currentPageSp}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Support')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="withdrawadd" title="Withdraw Address">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="secuirty_box">
                                                                    <div className="secuirty_box_contentsub deposit_contentsub">
                                                                        <div className="table_section home_recent_table">
                                                                            <div
                                                                                className="table_boxcontainer"
                                                                                style={{ height: "max-content" }}
                                                                            >
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                        <th>Label</th>
                                                                                            <th>Address</th>
                                                                                            <th>Currency</th>
                                                                                            <th>Network</th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {loader ? (
                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>
                                                                                                    <div className="loader_main loader_mainChange">
                                                                                                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                                                                                                        <p>Loading...</p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ) : withdrawaddress.filter((address) => !address.isDeleted).length > 0 ? (
                                                                                            withdrawaddress
                                                                                                .filter((address) => !address.isDeleted) // Filtering out the deleted addresses
                                                                                                .map((ticket) => (
                                                                                                    <tr key={ticket._id}>
                                                                                                        <td>{ticket.label}</td>
                                                                                                        <td>{truncateText(ticket?.address, 30)}</td>
                                                                                                        <td>{ticket.token}</td>
                                                                                                        <td>{ticket.network}</td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                className="delete-icon-button"
                                                                                                                onClick={() => handleSoftDelete(ticket._id)}
                                                                                                            >
                                                                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                        ) : (
                                                                                            <tr>
                                                                                                <td colSpan="5" style={{ textAlign: "center" }}>
                                                                                                    <span className="depo_no_record">No Withdraw address found</span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>

                                                                                </table>
                                                                                {totalPageswithadd > 1 && (
                                                                                    <div className="pagination history_pagination_all">
                                                                                        <Stack spacing={2}>
                                                                                            <Pagination
                                                                                                count={totalPageswithadd}
                                                                                                page={currentPagewithadd}
                                                                                                onChange={(e, value) => handlePageChange(e, value, 'Withdraw Address')}
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    "& .MuiPaginationItem-root": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .Mui-selected": {
                                                                                                        backgroundColor: "#ffc630 !important",
                                                                                                        color: "#000",
                                                                                                        "&:hover": {
                                                                                                            backgroundColor: "#ffc630",
                                                                                                            color: "#000",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                    "& .MuiPaginationItem-icon": {
                                                                                                        color: "#fff",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                )}


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>


                                                </Tabs>
                                            </>
                                        </>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HistoryAll;
