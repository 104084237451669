import React, { useState, useRef, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import authService from "../services/authservices";

function WalletTransfer() {
    const [totalBalanceusd, setTotalBalanceusd] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [toastVisible , setToastVisible] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const [fuelWalletUsd, setFuelWalletUsd] = useState(0);
    const [depositHistory, setDepositHistory] = useState([]);
    const [timer, setTimer] = useState(120);
    const [user, setUser] = useState({
        email: null,
      });
    const [emailOtp, setEmailOtp] = useState("");
    const [resendLoader, setResendLoader] = useState(false);
    const [faqs, setFaqs] = useState({});
    const [fuelWallet, setFuelWallet] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    const showErrorToast = (message) => {
      if (!toastVisible) {
        setToastVisible(true); // Set toast as visible
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">{message}</p>
          </div>,
          {
            onClose: () => setToastVisible(false), // Reset toastVisible on close
            autoClose: 3000, // Optional: auto-close after 3 seconds
          }
        );
      }
    };
  
    const showSuccessToast = (message) => {
      if (!toastVisible) {
        setToastVisible(true);
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">{message}</p>
          </div>,
          {
            onClose: () => setToastVisible(false), // Reset state on close
            autoClose: 3000, // Auto-close after 3 seconds
          }
        );
      }
    };

    const customStyles = {
        control: (styles) => ({
          ...styles,
          // backgroundColor: "#f9f9f9",
          // border: "1px solid #ccc",
          // padding: "4px",
          backgroundColor: theme === "dark" ? "transparent" : "#f9f9f9",
          //   border: `1px solid ${theme === "dark" ? "#2b3139" : "#ccc"}`,
          padding: "4px",
          color: theme === "dark" ? "#fff" : "#000",
          //   border: "none",
          boxShadow: "none", // Removes the box-shadow
          outline: "none", // Removes any outline
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          color: theme === "dark" ? "#fff" : "#000",
          backgroundColor: isFocused
            ? theme === "dark"
              ? "#444"
              : "#e0e0e0"
            : theme === "dark"
              ? "#181a20"
              : "#fff",
          cursor: "pointer",
        }),
        singleValue: (styles) => ({
          ...styles,
          color: theme === "dark" ? "#fff" : "#000",
        }),
      };
    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
        } else {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
        }

        localStorage.setItem('theme', theme);
    }, [theme])
 const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            email: data.email,
          };
          setUser(userDetails);
        } else {
          setUser({
            email: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          email: null,
        });
      }
    } else {
      setUser({
        email: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);



    const handleKeyDown = (e) => {
        if (e.key === " " && !e.ctrlKey && !e.metaKey) {
          e.preventDefault();
        }
      };

        const sendOtp = async () => {
          if (!user.email) {
            showErrorToast('User email is not available. Please try again.')
            return;
          }
      
          try {
            const response = await authService.resendOtp({ email: user.email });
            if (response.data.status) {
              showSuccessToast('OTP sent successfully!')
              setOtpSent(true);
              setTimer(120);
            } else {
              showErrorToast(
                response.data.Message || "Failed to send OTP. Please try again."
              );
            }
          } catch (error) {
            showErrorToast("An error occurred. Please try again.");
          }
        };


       useEffect(() => {
          let countdown;
          if (otpSent && timer > 0) {
            countdown = setInterval(() => {
              setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
          } else if (timer === 0) {
            setOtpSent(false);
            clearInterval(countdown);
          }
          return () => clearInterval(countdown);
        }, [timer, otpSent]);

        const showsuccessToast = (message) => {
          toast.dismiss();
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">{message}</p>
            </div>
          );
        };

         const showerrorToast = (message) => {
            toast.dismiss();
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">{message}</p>
              </div>
            );
          };

        const validate = () => {
            const newErrors = {};
          
            // Validation for Fuel Wallet
            if (!fuelWallet || parseFloat(fuelWallet) <= 0) {
              newErrors.fuelWallet = "Fuel Wallet amount is required and must be greater than 0.";
            } else if (parseFloat(fuelWallet) > parseFloat(totalBalanceusd)) {
              newErrors.fuelWallet = `Fuel Wallet amount cannot exceed Main Wallet balance of ${totalBalanceusd} USDT.`;
            }
          
            // Validation for OTP
            if (!emailOtp || emailOtp.trim() === "") {
              newErrors.emailOtp = "OTP is required.";
            } else if (emailOtp.length !== 6 || !/^\d{6}$/.test(emailOtp)) {
              newErrors.emailOtp = "Invalid OTP format. Please enter a 6-digit number.";
            }
          
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0; // Returns true if no errors
          };
          
          const handleTransfer = async () => {
            if (validate()) {
              try {
                // const otpResponse = await authService.verifyOtp({
                //   emailOtp: Number(emailOtp),
                // });

                const otpResponse = await authService.verifyTransferOtp({
                  emailOtp: Number(emailOtp),
                });
          
                if (otpResponse?.status) {
                  console.log("OTP verified successfully.");
                  console.log("Transfer initiated:", {
                    mainWallet: totalBalanceusd,
                    fuelWallet,
                    emailOtp,
                  });
          
                  const transferResponse = await authService.performTransfer({
                    mainWallet: totalBalanceusd,
                    fuelWallet: parseFloat(fuelWallet),
                  });
          
                  if (transferResponse?.status) {
                    console.log("Transfer successful:", transferResponse.message);
                    showSuccessToast("Transfer completed successfully!");
                  } else {
                    console.error("Transfer failed:", transferResponse.message);
                    showerrorToast(transferResponse.message || "Transfer failed.");
                  }
                } else {
                  console.error("OTP verification failed:", otpResponse?.message);
                  showerrorToast(otpResponse?.message || "Invalid OTP. Please try again.");
                }
              } catch (error) {
                console.error("Error during transfer process:", error);
                showerrorToast("An error occurred. Please try again later.");
              }
            }
          };
          


    useEffect(() => {
        // const fetchBalance = async () => {
        //     try {
        //         const response = await authService.getTransactions();
        //         if (response.status === true && response.totalBalanceUSDT !== undefined) {
        //             setTotalBalance(parseFloat(response?.totalBalanceUSDT));
        //             setTotalBalanceusd(parseFloat(response?.totalBalanceUSDTusd));
        //             setFuelWallet(parseFloat(response?.userWalletFuelAmount));
        //             setFuelWalletUsd(parseFloat(response?.totalInUSD));
        //             setDepositHistory(response.deposits);
        //         } else {
        //             console.error("Unexpected response format:", response);
        //         }
        //     } catch (error) {
        //         console.error("Error fetching transactions:", error);
        //     }
        // };

        const fetchBalance = async () => {
          try {
              const response = await authService.getAssets();
              if (response.status === true && response.totalBalanceUSDT !== undefined) {
                  setTotalBalance(parseFloat(response?.totalBalanceUSDT));
                  setTotalBalanceusd(parseFloat(response?.totalBalanceUSDTusd));
                  setFuelWallet(parseFloat(response?.userWalletFuelAmount));
                  setFuelWalletUsd(parseFloat(response?.totalInUSD));
              } else {
                  console.error("Unexpected response format:", response);
              }
          } catch (error) {
              console.error("Error fetching transactions:", error);
          }
      };

        fetchBalance();
    }, []);


    return (
        <div>
            <section>
                <Header />
            </section>

            <div className="deposit-section">
                <div className="container">
                    <div className="row prof_row_sm">
                        <div className="col-lg-2 dispaly_none_1200">
                            <Sidebar />
                        </div>
                        <div className="col-xl-10 col-lg-12 deposit_pad_top">
                            <span className="profile_span">Transfer Wallet</span>
                            <div className="row mt-4">
                                <div className="col-lg-7">
                                    <div className="deposit_left_main additional_transferwallet">
                                        {/* Main Wallet Input - Disabled */}
                                        <div className="depo_inner_divmain">
                                            <label htmlFor="mainWallet" className="home-viewasset-span">
                                                Main Wallet (USDT)
                                            </label>
                                            <div className="pos_rel w-100">
                                                <input
                                                    id="mainWallet"
                                                    type="number"
                                                    value={totalBalance}
                                                    disabled
                                                    placeholder="Enter the Main Wallet"
                                                    className="bind_bin_next_inp"
                                                    aria-label="Main Wallet balance (read-only)"
                                                />
                                            </div>
                                        </div>

                                        {/* Swap Icon */}
                                        <div className="swap_icon">
                                            <img
                                                src={require("../assests/swap_icon.png")}
                                                className="cursor-pointer"
                                                alt="Swap Icon"
                                            />
                                        </div>

                                        {/* Fuel Wallet Input */}
                                        <div className="depo_inner_divmain">
                                            <label htmlFor="fuelWallet" className="home-viewasset-span">
                                                Fuel Wallet (USDT)
                                            </label>
                                            <div className="pos_rel w-100">
                                                <input
                                                    id="fuelWallet"
                                                    type="number"
                                                    max="999999"
                                                    name="fuelWallet"
                                                    value={fuelWallet}
                                                    onChange={(e) => setFuelWallet(e.target.value)}
                                                    placeholder="Enter the Fuel Wallet"
                                                    className="bind_bin_next_inp"
                                                    aria-label="Enter amount for the Fuel Wallet"
                                                />
                                                {errors.fuelWallet && <small className="error">{errors.fuelWallet}</small>}
                                            </div>
                                        </div>

                                        <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">Enter OTP</span>
                        <div className="pos_rel w-100">
                          <input
                            type="number"
                            maxLength="6"
                            name="emailOtp"
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                            placeholder="Enter the verification code"
                            className="bind_bin_next_inp"
                            onKeyDown={handleKeyDown}
                          />
                           {errors.emailOtp && <small className="error">{errors.emailOtp}</small>}
                          {otpSent ? (
                            <span className="bind_bin_next_inp_Copy">
                              Resend ({timer}s)
                            </span>
                          ) : (
                            <span
                              className="bind_bin_next_inp_Copy"
                              onClick={() => sendOtp()}
                              style={{ cursor: "pointer" }}
                            >
                              {resendLoader == false ? (
                                <>{timer === 120 ? "Send OTP" : "Resend"}</>
                              ) : (
                                "Loading..."
                              )}
                            </span>
                          )}
                        </div>
                      </div>

                                        {/* Submit Button */}
                                        <div className="withdraw_submit_div" onClick={handleTransfer}>
                                            <span>Transfer Wallet</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="container m-top-20 p-0">
                                        <div
                                            class="accordion accordion-flush"
                                            id="accordionFlushExample"
                                        >
                                            <div className="faq">
                                                <h5 className="faq-title">FAQs</h5>
                                                {faqs.length > 0 ? (
                                                    faqs.map((faq, index) => (
                                                        <div className="accordion-item font-satoshi" key={faq._id}>
                                                            <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#flush-collapse-${index}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`flush-collapse-${index}`}
                                                                >
                                                                    {index + 1}. {faq.question}
                                                                    <span className="icon">
                                                                        <i className="fas fa-chevron-down"></i>
                                                                    </span>
                                                                </button>
                                                            </h2>
                                                            <div
                                                                id={`flush-collapse-${index}`}
                                                                className="accordion-collapse collapse"
                                                                aria-labelledby={`flush-heading-${index}`}
                                                                data-bs-parent="#accordionFlushExample"
                                                            >
                                                                <div className="accordion-body">
                                                                    {faq.status === "Active" ? faq.answer : "Content not available."}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span className="faq_cate_span">No FAQs available for Deposit.</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WalletTransfer;
