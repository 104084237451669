
import axios from "axios";

// const API_URL = "http://localhost:8084/api";

const API_URL = "https://wyscale.beleaftechnologies.com:8084/api";

// Register user and send OTP
const register = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, data);
    return response.data;
  } catch (error) {
    console.error("Error during registration:", error.response?.data);
    return error.response?.data;
  }
};

const verifyOtp = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/verifyOtp`, data);
    console.log("Backend response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification:", error.response?.data);
    return error.response?.data;
  }
};

const verifywithdrawOtp = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/verifywithdrawOtp`, data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Backend response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification:", error.response?.data);
    return error.response?.data;
  }
};
const verifywithdrawmainOtp = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/verifywithdrawmainOtp`, data,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Backend response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification:", error.response?.data);
    return error.response?.data;
  }
};
// Fetch withdrawal addresses for the user
export const getWithdrawAddresses = async (coinType, label) => {
  try {
    const queryParams = [];

    if (coinType) queryParams.push(`token=${coinType}`);
    if (label) queryParams.push(`label=${label}`);
    const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

    const response = await axios.get(
      `${API_URL}/user/getwithdrawAddress${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching withdrawal addresses:", error);
    return { status: false, data: [] };
  }
};


const withdrawSubmit = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/withdraw_submit`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include auth token
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during withdrawal submission:", error.response?.data);
    return error.response?.data;
  }
};

// User login
const login = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/login`, data);

    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      console.log('Login successful, token stored.');
    }
    console.log('Full login response:', response.data);

    return {
      status: response.data.status,
      requires2FA: response.data.tfastatus === 1,
      token: response.data.token,
      Message: response.data.Message,
    };
  } catch (error) {
    console.error("Error during login:", error.response?.data);
    return error.response?.data;
  }
};


// 2FA Login
const tfaLogin = async (code) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/tfaLogin`, { tfa_code: code }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error during 2FA login:', error.response?.data);
    return error.response?.data;
  }
};
// Forgot password
const forgotPassword = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/forgotpassword`, data);
    return response.data;
  } catch (error) {
    console.error("Error during forgot password:", error.response?.data);
    return error.response?.data;
  }
};

// Reset password
const resetPassword = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/resetPassword`, data);
    return response.data;
  } catch (error) {
    console.error("Error during password reset:", error.response?.data);
    return error.response?.data;
  }
};



export const getQrcode = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/getQRCode`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    return error.response?.data;
  }
};
// Change 2FA status
export const changeTFA = async (token, code) => {
  try {
    const response = await axios.post(`${API_URL}/changeTFA`, { tfa_code: code }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error changing 2FA status:', error.response?.data);
    return error.response?.data;
  }
};

const changePassword = async (data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/loginchangePassword`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error.response?.data);
    return error.response?.data;
  }
};

export const getUserDetails = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in.');
  }

  try {
    const response = await axios.get(`${API_URL}/user`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Unauthorized access - possibly an invalid or expired token.');
        throw new Error('Unauthorized access. Please log in again.');
      } else {
        console.error('Error fetching user details:', error.response.data);
        throw new Error(error.response.data || 'An error occurred while fetching user details.');
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
      throw new Error('No response received from the server.');
    } else {
      console.error('Error setting up request:', error.message);
      throw new Error('An error occurred while setting up the request.');
    }
  }
};

export const verifyTFA = async (token, code) => {
  try {
    const response = await axios.post(`${API_URL}/verifyTFA`, { tfa_code: code }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error verifying TFA:', error.response?.data);
    return error.response?.data;
  }
};

const updateUserDetails = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${API_URL}/updateUser`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error updating user details:", error.response.data);
      return error.response.data;
    }
  }
};


const resendOtp = async (data) => {
  return await axios.post(`${API_URL}/resendOtp`, data);
};
const apibindOtp = async (data) => {
  return await axios.post(`${API_URL}/apibindOtp`, data);
};
const getNotification = async (data) => {
  const token = localStorage.getItem('token');
  return await axios.post(`${API_URL}/getNotification`, {},{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const notifyStateChange = async (data) => {
  const token = localStorage.getItem('token');
  return await axios.post(`${API_URL}/notifyStateChange`, {},{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
const withdrawOtp = async (data) => {
  return await axios.post(`${API_URL}/withdrawOtp`, data);
};
const addresswithdrawOtp = async (data) => {
  return await axios.post(`${API_URL}/addresswithdrawOtp`, data);
};
const getUserAddressNetwork = async (currency, network) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(
      `${API_URL}/user/getUserAddress_network`,
      { currency, network },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching user address and network:', error);
    return error.response?.data;
  }
};

// Add this function to your service file
const getTransactions = async (page = 1, limit = 10) => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(`${API_URL}/user/get_transaction`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error.response?.data);
    return error.response?.data;
  }
};

const getReferralHistory = async (page = 1, limit = 10) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in.');
  }

  try {
    const response = await axios.get(`${API_URL}/referral-history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,   
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching referral history:", error.response?.data);
    return error.response?.data;
  }
};

export const storeApiKey = async ({ apiKey, secretKey, exchangeName }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  try {
    const response = await axios.post(
      `${API_URL}/store`,
      { apiKey, secretKey, exchangeName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('API response:', response);
    if (response.status === 201 && response.data?.message) {
      return {
        message: response.data.message,
        isBounded: response.data.isBounded,
        apiKeyEntry: response.data.apiKeyEntry,
      };
    } else {
      throw new Error('Unexpected response format. Failed to store API key.');
    }
  } catch (error) {
    console.error(
      'Error storing API key:',
      error.response ? error.response.data : error.message
    );

    if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('Failed to store API key. Please try again.');
    }
  }
};


const addWithdrawAddress = async (data) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return { status: false, message: "Authentication token is missing. Please log in again." };
    }

    const response = await axios.post(
      `${API_URL}/user/addwithdrawAddress`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return the response from the server
  } catch (error) {
    if (error.response) {
      console.error("Error adding withdraw address:", error.response.data);
      return error.response.data; // Return the error response from the server
    } else if (error.request) {
      console.error("No response received from the server:", error.request);
      return { status: false, message: "No response from server. Please try again." };
    } else {
      console.error("Error setting up request:", error.message);
      return { status: false, message: "An error occurred. Please try again later." };
    }
  }
};
const performTransfer = async (data) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return { status: false, message: "Authentication token is missing. Please log in again." };
    }

    const response = await axios.post(
      `${API_URL}/perform-transfer`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data; // Return the response from the server
  } catch (error) {
    if (error.response) {
      console.error("Error adding withdraw address:", error.response.data);
      return error.response.data; // Return the error response from the server
    } else if (error.request) {
      console.error("No response received from the server:", error.request);
      return { status: false, message: "No response from server. Please try again." };
    } else {
      console.error("Error setting up request:", error.message);
      return { status: false, message: "An error occurred. Please try again later." };
    }
  }
};



// Function to fetch exchanges from the backend
export const getExchanges = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in.');
  }

  try {
    const response = await axios.get(`${API_URL}/exchanges`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;  // Return the list of exchanges
  } catch (error) {
    console.error("Error fetching exchanges:", error.response?.data);
    return error.response?.data;  // Return error data
  }
};




const getPlan = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/getPlan`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    throw error;
  }
}
const getPool = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/getPool`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,   
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    throw error;
  }
}

const getPlanDetails = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/getplandetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error', error);
    throw error;
  }
}

const planUpgrade = async (data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/planUpgrade`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    return error.response?.data;  // Return error data

  }
}
const softDeleteAddress = async (data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/delete-address`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    return error.response?.data;  // Return error data

  }
}

const handleFollowStrategy = async (data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/handleFollowStrategy`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    return error.response?.data;  // Return error data

  }
}
const updateUserBotStatus = async (data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/updateUserBotStatus`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    return error.response?.data;  // Return error data

  }
}
const saveUserStrategy = async (data) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/saveUserStrategy`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      status: response.status, // Return the HTTP status
      message: response.data.message, // Return the response message
      data: response.data.strategy, // Include additional data if needed
    };
  } catch (error) {
    console.error("Error saving strategy:", error);
    return {
      status: error.response?.status || 500, // Default to 500 if no response
      message: error.response?.data?.message || "An error occurred",
    };
  }
};

const getUserSubscription = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/getUserSubscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    return error.response?.data;
  }
};
const topStratagies = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/topStratagies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching QR code:', error);
    throw error;
  }
}

const marketCurrencyList = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in.');  // Handle if no token is available
  }

  try {
    const response = await axios.get(`${API_URL}/marketCurrencyList`, {  // Make the GET request to the backend
      headers: {
        Authorization: `Bearer ${token}`,  // Attach the token to the Authorization header
      },
    });
    return response.data;  // Return the response data, which should contain the referral history
  } catch (error) {
    console.error("Error fetching referral history:", error.response?.data);  // Log any error that occurs
    return error.response?.data;  // Return the error response if an error occurs
  }
};

export const verifyAuth = async (token) => {
  try {
    const response = await axios.post(`${API_URL}/verifyAuth`, { token: token }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error verifying Auth:', error.response?.data);
    return ({ status: false });
  }
};
export const getReferredUserDeposits = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/getReferredUserDeposits`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Token in the header
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching referred deposits:', error.response?.data);
    return { status: false };
  }
};

export const getWithdrawDetails = async (coinType) => {
  try {
    console.log(coinType, "??????????")
    const response = await axios.get(`${API_URL}/user/getwithdrawDetails?currency_symbol=${coinType}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log(response.data, ">>>>>>>>>>>>>>>>>>>>>>>");
    return response.data;
  } catch (error) {
    console.error("Error fetching withdrawal details:", error);
    return { status: false, data: {} };
  }
};
const getWithdrawalHistory = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/user/getWithdrawalHistory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawal history:', error);
    return { status: false, data: [] };
  }
};

const getDepositHistory = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/getDepositDetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawal history:', error);
    return { status: false, data: [] };
  }
};
const getLoginHistory = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');

    // Build the query parameters
    const response = await axios.get(`${API_URL}/user/getLoginHistory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching login history:', error);
    return { status: false, data: [] };
  }
};

const getWithdrawAddress = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');

    const response = await axios.get(`${API_URL}/withdraw-address`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching login history:', error);
    return { status: false, data: [] };
  }
};


const getFaq = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/getFaq`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawal history:', error);
    return { status: false, data: [] };
  }
};

const support = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/create_ticket`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include auth token
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during withdrawal submission:", error.response?.data);
    return error.response?.data;
  }
};
const getUserActiveStrategy = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/getUserActiveStrategy`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching active strategy:', error);
    return error.response?.data;
  }
};

const getsupport = async (page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/get_ticket`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching withdrawal history:', error);
    return { status: false, data: [] };
  }


};

export const getAssets = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found. Please log in.');
  }

  try {
    const response = await axios.get(`${API_URL}/user/get_assets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;  // Return the list of getAssets
  } catch (error) {
    console.error("Error fetching getAssets:", error.response?.data);
    return error.response?.data;  // Return error data
  }
};

const verifyTransferOtp = async (data) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found. Please log in.');
    }
    const response = await axios.post(`${API_URL}/verifyTransferOtp`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Include auth token
      },
    });
    console.log("Backend response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during OTP verification:", error.response?.data);
    return error.response?.data;
  }
};

export default {
  register,
  verifyOtp,
  login,
  tfaLogin,
  forgotPassword,
  resetPassword,
  changePassword,
  resendOtp,
  apibindOtp,
  getNotification,
  notifyStateChange,
  getUserDetails,
  updateUserDetails,
  getUserAddressNetwork,
  getPool,
  getTransactions,
  getReferralHistory,
  storeApiKey,
  getPlan,
  planUpgrade,
  topStratagies,
  handleFollowStrategy,
  updateUserBotStatus,
  marketCurrencyList,
  verifyAuth,
  addWithdrawAddress,
  withdrawOtp,
  addresswithdrawOtp,
  verifywithdrawOtp,
  verifywithdrawmainOtp,
  getWithdrawAddresses,
  withdrawSubmit,
  getWithdrawDetails,
  getWithdrawalHistory,
  getLoginHistory,
  getPlanDetails,
  support,
  getFaq,
  getsupport,
  performTransfer,
  saveUserStrategy,
  getUserSubscription,
  getWithdrawAddress,
  getUserActiveStrategy,
  getDepositHistory,
  getAssets,
  verifyTransferOtp,
  softDeleteAddress
};
