import React from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";

function Policy() {
  const [showType, setShowType, showTyperef] = useState("Privacy");

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 deposit_pad_top">
              <div className="row">
                <div className="col-lg-9">
                  {showTyperef.current == "Privacy" ? (
                    <div className="policy_main">
                      <div className="policy_top_main">
                        <span className="policy_head">Privacy Policies</span>
                        <div className="policy_top_submain">
                          <div className="policy_state_active">
                            <span className="">Privacy Policies</span>
                          </div>
                          <div
                            className="policy_state_deactive"
                            onClick={() => setShowType("Terms")}
                          >
                            <span className="">Terms & Conditions</span>
                          </div>
                        </div>
                      </div>
                      <div className="policy_second_main">
                        <div className="policy_top_main">
                          {/* <span className="policy_esti_date">
                            Effective Date: 07 October 2025
                          </span> */}
                          <span className="Under_estidate_cont">
                          <b>WYscale</b> ("WYscale," "we," or "us") respects your concerns about privacy and values the relationship we have with you. This Privacy Policy applies to information We collect when You use Our website and other online products and services that link to this Privacy Policy (collectively, the "Services").
                          </span>
                        </div>
                        <div className="policy_second_main">
                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                                INFORMATION YOU PROVIDE TO US
                              </span>
                              <span className="polisy_inn_content_span">
                              We collect information You provide directly to Us. We collect information when You connect your wallet, participate in any interactive feature of the Services, complete an exchange transaction, request customer support or otherwise communicate with us, including:
                              </span>
                              <span className="polisy_inn_content_span">
                                    **Wallet information:**Wallet addresses (public blockchain addresses), transaction, and balance information (blockchain data) that is accessible when interacting with WYscale. We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc. And no other login information (e.g., the User’s name, email address, telephone number, postal address amongst others) is required to use any feature of our Services.
                                    </span>
                              <span className="polisy_inn_content_span">
                              **Blockchain information:**We collect the information about which Blockchain You are interacting with. Please note that when you use the Interface, you are interacting with a particular blockchain, which provides transparency into your transactions. WYscale does not control and is not responsible for any information you make public on the blockchain by taking actions through the Interface.                                    </span>
                              <span className="polisy_inn_content_span">
                                **Transaction Information:**We collect transaction related information for transactions conducted by End Users through the Services (“Transactions”) but it is anonymized. Transaction information includes price, quantity, and any associated or supporting data related to the trade. Transaction information is used to provide the Services, facilitate the Transactions, provide support, and to operate, maintain and improve the Services.                                    </span>
                              <span className="polisy_inn_content_span">
                                Hence, When you use the Interface, the only information we collect from you is your blockchain wallet address, completed transaction hashes, and the token names, symbols, or other blockchain identifiers of the tokens that you swap. We do not collect any personal information from you (e.g., your name or other identifiers that can be linked to you). We do, however, use third-party service providers, like Infura, Cloudflare, and Google Analytics, which may receive or independently obtain your personal information from publicly-available sources. We do not control how these third parties handle your data and you should review their privacy policies to understand how they collect, use, and share your personal information.

                              </span>

                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                                INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE THE SERVICES
                              </span>
                              <div>
                                <span className="polisy_inn_content_span">
                                  **Exchange information:**We collect information about the transactions You complete using the Services, including the preparing of the strategy You provide for the facilitation of the trade of the Trading Assets, and the transaction information involved in such trading.

                                </span>
                                <span className="polisy_inn_content_span">
                                  **Automated collection:**Details of the User’s device, computer and device information, activity on the website, activity on the website and other similar information. The Company collects this information for analytics, improvement of the services and for review purposes.

                                </span>

                                <span className="polisy_inn_content_span">
                                  **Strategy Information:**The strategy created by You is Your property and intellectual property owned by You. You will be responsible to protect the strategy created by You, and We shall not, under any circumstances, be liable if Your strategy is leaked or accessed by anyone else for any reason and/or in any manner whatsoever.
                                </span>
                              </div>
                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                              SHARING AND DISCLOSURE
                              </span>
                              <div>
                              <span className="polisy_inn_content_span">
                              Your payment and transaction details will not be shared with other users or third parties under any circumstances.</span>
                              <span className="polisy_inn_content_span">
                              The information shared by You with Us will not be shared or disclosed with any other user or third party except for any anonymized information used for analytics. The information shared by You may be disclosed to external service providers such as Google Analytics etc., to assist Us in collating and analysing User information and enabling us to make the use of the Services and the Site more user-friendly.

</span>
                              </div>
                            </div>
                            <div className="policy_top_main">
                              <span className="policy_esti_date">
                              CRYPTOGRAPHIC HASHES
                              </span>
                              <div>
                              <span className="polisy_inn_content_span">
                              You agree and acknowledge that when You create Your investment strategy or place the fund/investment plan request on Your Account in accordance with the Terms of Use, through the use of cryptographic hashes and keys, You take full responsibility for the risks involved. By using the Services, You automatically accept liability of any loss, damages or implications incurred through the use of this Site.

</span>
                              </div>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              SOCIAL NETWORK PLUGINS
                              </span>
                              <span className="polisy_inn_content_span">
                              This Website incorporates plugins and/or buttons for social networks, in order to allow easy sharing on your favourite social networks. These plugins are programmed so as not to set any cookies when assessing the page to safeguard the privacy of users. Cookies may be set, if you make voluntary use of the plugin. The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks.

                              </span>
                            </div>

                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              USE OF SERVICES
                              </span>
                              <span className="polisy_inn_content_span">
                              By using the Services, You automatically accept liability of any loss, damages or implications incurred through the use of this Site. WYscale and its owners, shareholders, and officers will not be held liable for any loss of Trading Assets or any financial loss that may be incurred by You.
                              </span>
                            </div>
                           
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              YOUR CONSENT
                              </span>
                              <span className="polisy_inn_content_span">
                              By using our site, you consent to our web site privacy policy.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              CLARIFICATIONS
                              </span>
                              <span className="polisy_inn_content_span">
                              If You have any queries regarding this Privacy Policy, please contact us at support@wyscale.com
                              </span>
                            </div>
                          
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="policy_main">
                      <div className="policy_top_main">
                        <span className="policy_head">Terms & Conditions</span>
                        <div className="policy_top_submain">
                          <div
                            className="policy_state_deactive"
                            onClick={() => setShowType("Privacy")}
                          >
                            <span className="">Privacy Policies</span>
                          </div>
                          <div className="policy_state_active">
                            <span className="">Terms & Conditions</span>
                          </div>
                        </div>
                      </div>
                      <div className="policy_second_main">
                        <div className="policy_top_main">
                          {/* <span className="policy_esti_date">
                            Effective Date: 07 October 2024
                          </span> */}
                          <span className="Under_estidate_cont">
                          Welcome to <b>WYscale</b> Crypto Mutual Fund, a website-hosted user interface (the "Interface" or "App") provided by WYscale ("we", "our", or "us"). The Interface provides access to a decentralised investment opportunity on various blockchain that allows users to trade/invest in certain digital assets. The Interface is one, but not the exclusive, means of accessing the Protocol.
                          </span>
                        </div>
                        <div className="policy_second_main">
                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="polisy_inn_content_span">
                              This Terms of Service Agreement (the "Agreement") explains the terms and conditions by which you may access and use the Interface. You must read this Agreement carefully. By accessing or using the Interface, you signify that you have read, understand, and agree to be bound by this Agreement in its entirety. If you do not agree, you are not authorized to access or use the Interface and should not use the Interface.
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              NOTICE
                              </span>
                              <span className="polisy_inn_content_span">
                              Please read this Agreement carefully as it governs your use of the Interface. This Agreement contains important information, including a binding arbitration provision and a class action waiver, both of which impact your rights as to how disputes are resolved. The Interface is only available to you — and you should only access the Interface — if you agree completely with these terms.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              Modification of this Agreement
                              </span>
                              <span className="polisy_inn_content_span">
                              We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any modifications, we will notify you by updating the date at the top of the Agreement and by maintaining a current version of the Agreement at [ ]. All modifications will be effective when they are posted, and your continued accessing or use of the Interface will serve as confirmation of your acceptance of those modifications. If you do not agree with any modifications to this Agreement, you must immediately stop accessing and using the Interface.{" "}
                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              Eligibility
                              </span>
                              <span className="polisy_inn_content_span">
                              To access or use the Interface, you must be able to form a legally binding contract with us. Accordingly, you represent that you are at least the age of majority in your jurisdiction (e.g., eighteen years old) and have the full right, power, and authority to enter into and comply with the terms and conditions of this Agreement on behalf of yourself and any company or legal entity for which you may access or use the Interface.{" "}
                              </span>
                              <span className="polisy_inn_content_span">

                              You further represent that you are not (a) the subject of economic or trade sanctions administered or enforced by any governmental authority or otherwise designated on any list of prohibited or restricted parties or (b)you represent that your access and use of the Interface will fully comply with all applicable laws and regulations, and that you will not access or use the Interface to conduct, promote, or otherwise facilitate any illegal activity.                              </span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              Additional Rights
                              </span>
                              <span className="polisy_inn_content_span">
                              We reserve the following rights, which do not constitute obligations of ours: (a) with or without notice to you, to modify, substitute, eliminate or add to the Interface; (b) to review, modify, filter, disable, delete and remove any and all content and information from the Interface; and (c) to cooperate with any law enforcement, court or government investigation or order or third party requesting or directing that we disclose information or content or information that you provide.
                              </span>
                            </div>
                          </div>

                          <div className="policy_second_innimp">
                            <span className="policy_inner_head_span">
                            Assumption of Risk
                            </span>
                            <div className="policy_inner_mainhead">
                              <span className="polisy_inn_content_span">
                              By accessing and using the Interface, you represent that you are financially and technically sophisticated enough to understand the inherent risks associated with using cryptographic and blockchain-based systems, and that you have a working knowledge of the usage and intricacies of digital assets such as bitcoin (BTC), ether (ETH), and other digital tokens such as those following the Ethereum Token Standard (ERC-20). In particular, you understand that blockchain-based transactions are irreversible.
                              </span>
                              <span className="polisy_inn_content_span">
                              You further understand that the markets for these digital assets are highly volatile due to factors including (but not limited to) adoption, speculation, technology, security, and regulation. You acknowledge and accept that the cost and speed of transacting with cryptographic and blockchain-based systems such as Ethereum are variable and may increase dramatically at any time. You further acknowledge and accept the risk that your digital assets may lose some or all of their value while they are supplied to the Protocol through the Interface, you may suffer loss due to the fluctuation of prices of tokens in a trading pair or liquidity pool, and, especially in expert modes, experience significant price slippage and cost. You further acknowledge that we are not responsible for any of these variables or risks, do not own or control the Protocol, and cannot be held liable for any resulting losses that you experience while accessing or using the Interface. Accordingly, you understand and agree to assume full responsibility for all of the risks of accessing and using the Interface to interact with the Protocol.
</span>
                            </div>
                            <div className="policy_inner_mainhead">
                              <span className="policy_inner_head_span">
                              Entire Agreement
                              </span>
                              <span className="polisy_inn_content_span">
                              These terms constitute the entire agreement between you and us with respect to the subject matter hereof. This Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications and other understandings (if any) relating to the subject matter of the terms.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policy;
