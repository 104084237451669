import React, { useEffect, useContext } from "react";
import useState from "react-usestateref";
import Logo from "../assests/Logo.webp";
import authService from "../services/authservices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ScaleLoader } from 'react-spinners'
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";

import { Link, useNavigate, useLocation } from "react-router-dom";
import UserIcon from "../assests/icons/profile.png";
import { UserContext } from "../contexts/UserContext";
import { getUserDetails } from "../services/authservices";

dayjs.extend(relativeTime);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBarItems: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerIcon: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    width: 300,
  },
}));

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [NotiEl, setNotiEl] = useState(null);
  const isHomePage = window.location.pathname === "/";
  const istermsCondition = window.location.pathname === "/termscondition";
  const isprivacypolicy = window.location.pathname === "/privacypolicy";
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const isActiatedFree = localStorage.getItem("freemiumActivated") || "false";
  const { theme, toggleTheme } = useContext(UserContext);
  const location = useLocation();
  const classes = useStyles();
  const { logout } = useContext(UserContext);
  const [hasUnread, setHasUnread] = useState(false);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.classList.add(theme); // Apply the current theme to the body element (or another container)
  }, [theme]);

  useEffect(() => {
    fetchUserDetails();
    let userToken = localStorage.getItem('token');
    if(userToken){
    getnotify();
    }
  }, []);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setNotiEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

    const getnotify = async () => {
    try {
      const response = await authService.getNotification();
      // console.log("response-->",response.data.data.data);
      if (response.status) {
        setLoginHistory(response.data.data.data);
        const hasUnreadNotifications = response.data.data.data.some((n) => n.status === 0);
        // console.log("hasUnreadNotifications--->>>>",hasUnreadNotifications);
        setHasUnread(hasUnreadNotifications); // Update state
      }
    } catch (error) {}
  };

  const handleClick1 = async(event) => {
    setNotiEl(event.currentTarget);
    const response = await authService.notifyStateChange();
    if (response.status) {
      setHasUnread(false); // Remove the bell indicator
      getnotify();
    }
  };

  // const handleThemeToggle = () => {
  //   // window.location.reload();
  //   setTheme((prevTheme) => {
  //     const newTheme = prevTheme === "dark" ? "light" : "dark";
  //     localStorage.setItem("theme", newTheme); // Save to localStorage for persistence
  //     return newTheme;
  //   });
  // };

  useEffect(() => {
    // Whenever the theme is toggled, we update the body class.
    // This ensures the theme change is applied globally on the page.
    document.body.className = theme; // Update body class according to the theme
  }, [theme]);

  // useEffect(() => {
  //   document.body.setAttribute("data-theme", theme); // Set the theme attribute on <body>
  // }, [theme]);
  // useEffect(() => {
  //   if (theme === 'dark') {
  //     document.body.classList.add('dark');
  //     document.body.classList.remove('light');
  //   } else {
  //     document.body.classList.add('light');
  //     document.body.classList.remove('dark');
  //   }
  //   localStorage.setItem('theme', theme);
  // }, [theme])

  useEffect(() => {
    let userToken = sessionStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };

  const obfuscateEmail = (email) => {
    if (!email) return "";
    const [localPart, domainPart] = email.split("@");
    const firstFive = localPart.slice(0, 3);
    return `${firstFive}***@${domainPart}`;
  };

  const [open1, setOpen] = useState(false);

  const navigate = useNavigate();

  const notifyNav = () => {
    navigate("/loginHistory");
  };
  const copyToClipboard = (() => {
    let toastVisible = false; 
    let timeout = null; 
  
    return (text) => {
      if (toastVisible) return;
  
      if (timeout) clearTimeout(timeout);
  
      timeout = setTimeout(() => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            toastVisible = true; 
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">Copied to authenticated code!</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          })
          .catch((err) => {
            console.error("Failed to copy text:", err);
            toastVisible = true;
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">Failed to copy text</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          });
      }, 300); 
    };
  })();
  

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const [loginHistory, setLoginHistory,loginHistoryref] = useState([]);
  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/register");
  };


  
  // useEffect(() => {
  //   const fetchLoginHistory = async () => {
  //     const response = await authService.getLoginHistory();
  //     if (response.status) {
  //       setLoginHistory(response.data.slice(0, 5)); // Limit to 5 records
  //     } else {
  //       console.error("Failed to fetch login history:", response.Message);
  //     }
  //     setLoading(false);
  //   };

  //   fetchLoginHistory();
  // }, []);

  return (
    <div>
      <header className="scrolled">
        <div>
          <div className={`${classes.root} `}>
            <AppBar position="static">
              <Toolbar className="container-fluid pad-0 w-100">
                <Typography variant="h6" className={classes.title}>
                  <div className="d-flex">
                    <Link to="/" className="logo-brand">
                      <img src={Logo} className="img-fluid" alt="logo" />
                    </Link>
                    <div className={`menu_new_typr ${classes.appBarItems}`}>
                      {/* <Link to="/" color="inherit" className="contact_button"> */}
                      <Link
                       to={user.isAuthenticated ? "/home": "/login"}
                        color="inherit"
                        className={`${isActive("home")}`}
                        
                      >
                        Home
                      </Link>
                      <Link
                        to={user.isAuthenticated ? "/strategies": "/login"}
                        color="inherit"
                        className={`${isActive("strategies")}`}
                      >
                        Strategies
                      </Link>
                      <Link
                        to={user.isAuthenticated ? "/assets": "/login"}
                        color="inherit"
                        className={`${isActive("assets")}`}
                      >
                        Assets
                      </Link>
                      <Link
                        to={user.isAuthenticated ? "/referral": "/login"}
                        color="inherit"
                        className={`${isActive("referral")}`}
                      >
                        Refer & Earn
                      </Link>
                    </div>
                  </div>
                </Typography>
<div className="mobile_change_header">
<div className="mobile-theme-icon">
  
                  {/* {theme == "dark" ? (
                    <Link
                      onClick={handleThemeToggle}
                      aclassName="contact_button"
                    >
                      <span className="theme-icons">
                        {" "}
                        <i clss="bi bi-brightness-high"></i>
                      </span>
                    </Link>
                  ) : (
                    <Link
                      onClick={handleThemeToggle}
                      className="contact_button"
                    >
                      <span className="theme-icons">
                        <i class="bi bi-moon"></i>
                      </span>
                    </Link>
                  )} */}

                  {theme === 'dark' ? (
                    <Link onClick={toggleTheme} className="contact_button">
                      <span className="theme-icons">
                        <i className="bi bi-brightness-high"></i>
                      </span>
                    </Link>
                  ) : (
                    <Link onClick={toggleTheme} className="contact_button">
                      <span className="theme-icons">
                        <i className="bi bi-moon"></i>
                      </span>
                    </Link>
                  )}
                </div>
                  {user.isAuthenticated ? (
                    <div className="mobile-only">
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="head-drops cursor-pointer"
                        onClick={handleClick1}
                      >
                        <img
                          src={require("../assests/icons/Notification.png")}
                          width="30px"
                          className="dark_display_none nav-primary-icons"
                        />
                        {hasUnread && <div className="bell-indicator"></div>}
                      </h6>
                      <Menu
                        id="simple-menu"
                        anchorEl={NotiEl}
                        keepMounted
                        open={Boolean(NotiEl)}
                        onClose={handleClose1}
                        className="MuiList-padding-notify"
                      >
                        <div className="frame-container notify-dropdown">
                          <div className="user-details-noti">
                            <div className="notify-contents">
                              <div className="notify-head-wrapper d-flex align-items-center justify-content-between w-100">
                                <h5>Notifications</h5>
                                <div
                                  className="d-flex align-items-center gap-2 cursor-pointer"
                                  onClick={notifyNav}
                                >
                                  <span className="home_starty_view">View All</span>
                                  <i className="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="noti_drop_margin">
                            {loading ? (
                              <div className="loader_main loader_mainChange">
                                <ScaleLoader color="#36d7b7" height={10} width={3} />
                                <p>Loading...</p>
                              </div>
                            ) : (
                              loginHistoryref.current.map((login, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center justify-content-between my-2 py-2"
                                >
                                  <span className="top_startgy_inbottomadvance">
                                    Login Successfully
                                  </span>
                                  <span className="noti_inner_right">
                                    {dayjs(login.createdAt).fromNow()}
                                  </span>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </Menu>
                    </div>
                  ) : null}


                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className={` m-0 p-0 ${classes.mobileMenuButton} ${classes.drawerIcon}`}
                >
                  <MenuIcon className="meus" onClick={handleMobileMenuOpen} />
                </IconButton>
          
</div>
              
 
                <div className={`menu_new_typr_right ${classes.appBarItems}`}>

                  {isHomePage || istermsCondition || isprivacypolicy ? (

                    <>
                      {user.isAuthenticated ? (
                    
                          <button className="head-btn" onClick={handleLogout}>
                            <span className="head-btn-now">Log Out</span>
                            <span className="head-btn-new">Log Out</span>
                          </button>
                      

                      ) : (
                        <>
                          <Link to="/login">
                            <button className="head-btn">
                              <span className="head-btn-now">Log In</span>
                              <span className="head-btn-new">Log In</span>
                            </button>
                          </Link>

                          <Link to="/register">
                            <button className="head-btn">
                              <span className="head-btn-now">Sign Up</span>
                              <span className="head-btn-new">Sign Up</span>
                            </button>
                          </Link>
                        </>
                      )}
                    </>) : (
                    <>
                      <Link to="/deposit">
                        <button className="head-btn">
                          <span className="head-btn-now">Deposit</span>
                          <span className="head-btn-new">Deposit</span>
                        </button>
                      </Link>

                      <span className="das mx-1">|</span>
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className=" head-drops cursor-pointer"
                        onClick={handleClick1}
                      >
                        {/* {loginCheck ? ( */}
                        <img
                          src={require("../assests/icons/Notification.png")}
                          width="30px"
                          className="dark_display_none nav-primary-icons"
                        />
                        {hasUnread && <div className="bell-indicator"></div>}
                      </h6>
                      <Menu
                        id="simple-menu"
                        anchorEl={NotiEl}
                        keepMounted
                        open={Boolean(NotiEl)}
                        onClose={handleClose1}
                        className="MuiList-padding-notify"
                      >
                        {/* {loginCheck ? ( */}
                        <div className="frame-container notify-dropdown">
                          {/* user details */}
                          <div className="user-details-noti">
                            <div className="notify-contents">
                              <div className="notify-head-wrapper d-flex align-items-center justify-content-between w-100">
                                <h5>Notifications</h5>
                                <div
                                  className="d-flex align-items-center gap-2 cursor-pointer"
                                  onClick={notifyNav}
                                >
                                  <span className="home_starty_view">
                                    View All
                                  </span>
                                  <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="noti_drop_margin">
                            {loading ? (
                                <div className="loader_main loader_mainChange">
                                  <ScaleLoader color="#36d7b7" height={10} width={3} />
                                  <p>Loading...</p>
                                </div>
                            ) : (
                              loginHistoryref.current.map((login, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center justify-content-between my-2 py-2"
                                >
                                  <span className="top_startgy_inbottomadvance">
                                    Login Successfully
                                  </span>
                                  <span className="noti_inner_right">
                                    {dayjs(login.createdAt).fromNow()}
                                  </span>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        {/* ) : (
                        ""
                      )} */}
                      </Menu>
                      <h6
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className=" head-drops cursor-pointer"
                        onClick={handleClick}
                      >
                        {/* {loginCheck ? ( */}
                        <img
                          src={require("../assests/icons/profile.png")}
                          width="30px"
                          className="dark_display_none nav-primary-icons"
                        />

                      </h6>

                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className="MuiList-padding"
                      >
                        {/* {loginCheck ? ( */}
                        <div className="frame-container">
                          {/* user details */}
                          <div className="user-details">
                            <img
                              src={require("../assests/icons/home-user.png")}
                              alt="usericon"
                              className="user-img"
                            />
                            <div className="details">
                              <span className="details-mail">{user.email}</span>
                              <span className="details-udi">
                                UID: {user.uid}
                                <i
                                  className="fa-regular fa-copy cursor-pointer"
                                  onClick={() => copyToClipboard(user.uid)}
                                ></i>
                              </span>
                            </div>
                          </div>
                          {/* links */}
                          <ul className="links">
                            <li>
                              <Link to="/profile" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/user-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Profile</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/deposit" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/deposit-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Deposit</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/withdraw" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/withdraw-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Withdraw</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            {isActiatedFree === "false" && <li>
                              <Link to="/apibind" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/api-bind.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>API Bind</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li> } 
                            <li>
                              <Link to="/team" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/team-ico.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Your Team</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/security" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/Security-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span>Security</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/faq" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/question-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span> FAQs</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/policy" className="link-content">
                                <div className="head_link_flex">
                                  <img
                                    src={require("../assests/icons/policy-icon.png")}
                                    // width="30px"
                                    className="nav-primary-icons-headinner"
                                  />
                                  <span> Policy</span>
                                </div>
                                <span className="text_head_arrow hover-show">
                                  <i class="fa-solid fa-chevron-right"></i>
                                </span>
                              </Link>
                            </li>
                          </ul>
                          {/* logout button */}
                          {user.isAuthenticated && (
                            <div
                              className="btn-wrapper security-link cursor-pointer"
                              onClick={handleLogout}
                            >
                              <span className="user-btn">Logout</span>
                              <img
                                src={require("../assests/logout.png")}
                                alt="logoutimg"
                                className="logout-img"
                              />
                            </div>
                          )} 


                        </div>

                      </Menu>

                    </>

                  )}

                  {theme === 'dark' ? (
                    <Link onClick={toggleTheme} className="contact_button">
                      <span className="theme-icons">
                        <i className="bi bi-brightness-high"></i> {/* Icon for light mode */}
                      </span>
                    </Link>
                  ) : (
                    <Link onClick={toggleTheme} className="contact_button">
                      <span className="theme-icons">
                        <i className="bi bi-moon"></i> {/* Icon for dark mode */}
                      </span>
                    </Link>
                  )}
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              anchor="left"
              open={mobileMenuOpen}
              onClose={handleMobileMenuClose}
              className={`mobile-drawer ${classes.drawer}`}
            >
              <div className={classes.mobileMenu}>
                <div className="logo_new_sectio mobile_menu_icon">
                  <Link to="/" className="logo-brand">
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </Link>
                </div>

                <div className="menu_statis">
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/home": "/login"}>Home</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/strategies": "/login"}>Strategies</Link>
                  </ListItem>
                  <ListItem button className="drawa ">
                    <Link to={user.isAuthenticated ? "/assets": "/login"}>Assets</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/referral": "/login"}> Refer & Earn</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/profile": "/login"}> Profile</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/deposit": "/login"} > Deposit</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/withdraw": "/login"}> Withdraw</Link>
                  </ListItem>
                   <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/apibind": "/login"}> API Bind</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/security": "/login"}> Security</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/faq": "/login"}> FAQs</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/policy": "/login"}> Policy</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/upgrade": "/login"}>Upgrade</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/team": "/login"}>Your Team</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/transferwallet": "/login"}>Transfer</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/history": "/login"}> History</Link>
                  </ListItem>
                  <ListItem button className="drawa">
                    <Link to={user.isAuthenticated ? "/support": "/login"}>Support</Link>
                  </ListItem>
                  {!user.isAuthenticated ? (
                    <>
                      <ListItem button className="drawa">
                      <button className="head-btn" onClick={handleLogin}>
                              <span className="head-btn-now">Log In</span>
                              <span className="head-btn-new">Log In</span>
                            </button>
                      </ListItem>
                      <ListItem button className="drawa">
                      <button className="head-btn" onClick={handleRegister}>
                              <span className="head-btn-now">Register</span>
                              <span className="head-btn-new">Register</span>
                            </button>
                      </ListItem>
                    </>
                  ) : (
                    <ListItem button className="drawa">
                     <button className="head-btn" onClick={handleLogout}>
                            <span className="head-btn-now">Log Out</span>
                            <span className="head-btn-new">Log Out</span>
                          </button>
                    </ListItem>
                  )}

                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
