import React, { useEffect, useContext, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Switch from "react-switch";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import authService from "../services/authservices";
import Select from "react-select";

function Profile() {
  const [checked, setChecked] = useState(false);
  const { logout } = useContext(UserContext);
  const [toastVisible , setToastVisible] = useState(false);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const navigate = useNavigate();
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const options = useMemo(() => countryList().getData(), []);

  const [username, setUsername] = useState(user.displayname || "");
  const [country, setCountry] = useState(user.country || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editableField, setEditableField] = useState("");
  const [inputValue, setInputValue , inputValueref] = useState("");
  const [email, setEmail] = useState(user.email || "");
  const [error, setError] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState([]);

  const [strategyOptions, setStrategyOptions] = useState([]);
  const openModal = (field) => {
    setEditableField(field);
    if (field === "username") {
      setInputValue(user.displayname);
    } else if (field === "country") {
      setSelectedCountry(options.find(option => option.value === user.country));
    } else if (field === "email") {
      setInputValue(user.email);
    } else if (field === "strategy") {
      setSelectedStrategy(strategyOptions.find(option => option.value === user.strategy));
    }
    setIsModalOpen(true);
  };

  const [selectedCountry, setSelectedCountry] = useState(
    options.find(option => option.value === user.country) || null
  );

  const [userSubscriptionName, setUserSubscriptionName] = useState('');
  const [filteredStrategyOptions, setFilteredStrategyOptions] = useState([]);


  const [activeStrategy, setActiveStrategy] = useState('');

  useEffect(() => {
    const fetchSubscriptionAndStrategy = async () => {
      const subscriptionResponse = await authService.getUserSubscription();
      if (subscriptionResponse && subscriptionResponse.subscriptionName) {
        setUserSubscriptionName(subscriptionResponse.subscriptionName);
        setFilteredStrategyOptions(filterStrategyOptions(subscriptionResponse.subscriptionName));
      }

      const strategyResponse = await authService.getUserActiveStrategy();
      if (strategyResponse && strategyResponse.strategy) {
        setActiveStrategy(strategyResponse.strategy);
      }
    };

    fetchSubscriptionAndStrategy();
  }, []);

  const filterStrategyOptions = (subscriptionName) => {
    let allowedStrategies = [];
    if (subscriptionName === "Freemium") {
      allowedStrategies = ["Intra Strategy"];
    } else if (subscriptionName === "Premium") {
      allowedStrategies = ["Intra Strategy", "DCA", "Arbitrage Strategy"];
    } else if (subscriptionName === "Pro") {
      allowedStrategies = ["Intra Strategy", "DCA", "Arbitrage Strategy", "Triangle Strategy"];
    }
    return strategyOptions.filter(option => allowedStrategies.includes(option.value));
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    setError("");
    if (editableField === "username") { 
      const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, ""); 

      if (sanitizedValue.length === 0) {
        setError("Username cannot be empty"); // Error if input is empty
      } else {
        setError(""); // Clear error if input is valid
      }
  
      if (sanitizedValue.length <= 50 || value.length === 0) {
        setInputValue(sanitizedValue); // Update state only for valid input or if cleared
      }
    } else if (editableField === "email") {
      const regex = /^[^\s]*$/;
      if (!regex.test(value) || value.length > 250) {
        setError("Invalid email. No spaces allowed and must be under 250 characters.");
      } else {
        setInputValue(value);
      }
    }
  };

  const showErrorToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true); // Set toast as visible
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset toastVisible on close
                  autoClose: 3000, // Optional: auto-close after 3 seconds
                }
              );
            }
          };
        
          const showSuccessToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true);
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset state on close
                  autoClose: 3000, // Auto-close after 3 seconds
                }
              );
            }
          };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.value);
  };


  const handleSaveChanges = async () => {


    if (!inputValue && editableField !== "country" && editableField !== "strategy") return;

    if (editableField === "username") {
      if (inputValue.endsWith(" ")) {
        setError("Input cannot end with a space.");
        return;
      }
    }

    if (editableField === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputValue)) {
        setError("Invalid email format.");
        return;
      }
    }

    const updatedData = {};
    if (editableField === "username" && inputValue !== user.displayname) {
      updatedData.displayname = inputValue;
    }
    if (editableField === "email" && inputValue !== user.email) {
      updatedData.email = inputValue;
    }
    if (editableField === "country" && selectedCountry !== user.country) {
      const selectedCountryLabel = options.find(option => option.value === selectedCountry)?.label;
      updatedData.country = selectedCountryLabel;
    }


    if (editableField === "strategy") {

      const selectedStrategyArray = Array.isArray(selectedStrategy) ? selectedStrategy : [];
      const selectedStrategyNames = selectedStrategyArray.map(option => option.value).filter(Boolean);

      if (selectedStrategyArray.length === 0) {
        showErrorToast('Please select at least one strategy before saving.')
        return;
      }

      try {
        const response = await authService.saveUserStrategy({
          stratagyName: selectedStrategyNames, // Send valid strategy names
        });

        if (response && (response.status === 200 || response.status === 201)) {
          setIsModalOpen(false); // Close the modal
          setUser((prev) => ({
            ...prev,
            strategy: selectedStrategyArray.map(s => s.value), // Update user strategies
          }));
        showSuccessToast(response.message)
          const strategyResponse = await authService.getUserActiveStrategy();
          if (strategyResponse && strategyResponse.strategy) {
            setActiveStrategy(strategyResponse.strategy);
          }
        } else {
          showErrorToast(response.message || "Unexpected error")
        }

      } catch (error) {
        console.error("Error saving strategy:", error);
        showErrorToast(error.response?.data?.message || "An unexpected error occurred.")
      }
      return;
    }

    if (Object.keys(updatedData).length === 0) {
      // toast.info(
      //   <div className="toast-ml">
      //     <h4 className="toast-title">Info!</h4>
      //     <p className="toast-message">No changes detected.</p>
      //   </div>
      // );
      setIsModalOpen(false);
      return;
    }

    try {
      const response = await authService.updateUserDetails(updatedData);
      console.log("Backend Response:", response);

      if (response && response.status) {
        setIsModalOpen(false);
        setUser((prev) => ({
          ...prev,
          ...updatedData,
        }));
        showSuccessToast('Profile updated successfully')
      } else {
        showErrorToast('Failed to update user details. Please try again.')
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      showErrorToast('An error occurred while updating user details.')
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const copyToClipboard = (() => {
    let toastVisible = false; // Track visibility of toast
    let timeout = null; // Debounce logic
  
    return (text) => {
      if (toastVisible) return; // Prevent showing multiple toasts
  
      if (timeout) clearTimeout(timeout); // Clear previous timeout
  
      timeout = setTimeout(() => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            toastVisible = true;
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">Copied to clipboard</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; // Reset toast visibility after closing
                },
              }
            );
          })
          .catch((err) => {
            console.error("Failed to copy text:", err);
            toastVisible = true;
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">Failed to copy text</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; // Reset toast visibility after closing
                },
              }
            );
          });
      }, 300); // Optional debounce time
    };
  })();
  const copyToClipboardurlcopy = (() => {
    let toastVisible = false; 
    let timeout = null; 
  
    return (text) => {
      if (toastVisible) return;
  
      if (timeout) clearTimeout(timeout);
  
      timeout = setTimeout(() => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            toastVisible = true; 
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">Link copied to clipboard</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          })
          .catch((err) => {
            console.error("Failed to copy text:", err);
            toastVisible = true;
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">Failed to copy text</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          });
      }, 300); 
    };
  })();
  
  // const copyToClipboardurlcopy = (() => {
  //   let toastVisible = false; 
  //   return () => {
  //     if (!user.referralUrl) {
  //       console.warn("No referral URL to copy.");
  //       return;
  //     }
  
  //     if (toastVisible) return;
  
  //     navigator.clipboard
  //       .writeText(user.referralUrl)
  //       .then(() => {
  //         toastVisible = true; 
  //         toast("Link copied to clipboard!", {
  //           onClose: () => {
  //             toastVisible = false; 
  //           },
  //         });
  //       })
  //       .catch((err) => {
  //         console.error("Failed to copy: ", err);
  //         toastVisible = true; 
  //         toast("Failed to copy link!", {
  //           onClose: () => {
  //             toastVisible = false; 
  //           },
  //         });
  //       });
  //   };
  // })();
  
  const copyToClipboardlink = (() => {
    let toastVisible = false; 
    return () => {
      const referralCode = user.referralCode;
  
      if (!referralCode) {
        console.warn("Referral code is missing.");
        return;
      }
      const referralUrl = `/register?invite=${referralCode}`;
  
      if (toastVisible) return; 
  
      navigator.clipboard
        .writeText(referralUrl)
        .then(() => {
          toastVisible = true; 
          toast("Link copied to clipboard!", {
            onClose: () => {
              toastVisible = false; 
            },
          });
          navigate(referralUrl); 
        })
        .catch((err) => {
          console.error("Failed to copy or navigate: ", err);
          toastVisible = true; 
          toast("Failed to copy the link!", {
            onClose: () => {
              toastVisible = false; 
            },
          });
        });
    };
  })();
  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "50px",
      minHeight: "44px",
      backgroundColor: state.isFocused ? "#f9f9f9" : "#f9f9f9", // Background color on focus
      borderColor: state.isFocused ? "#dfdede" : "#dfdede", // Change border color on focus
      color: "#909090", // Text color
      boxShadow: state.isFocused ? "none" : "none", // Box-shadow on focus
      fontFamily: '"Helvetica", sans-serif', // Custom font family
      fontSize: "14px", // Custom font size
      padding: "0 8px",
      borderRadius: "10px",
      "&:hover": {
        borderColor: "#dfdede", // Hover border color
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "50px", // Ensure consistent height for the value container (selected value area)
      padding: "0 8px", // Padding inside the value container
    }),
    input: (provided) => ({
      ...provided,
      height: "50px", // Consistent height for input inside the select
      margin: "0", // Ensure the margin does not affect the height
      padding: "0", // Padding inside input
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#cdb308"
        : state.isFocused
          ? "#f0f0f0"
          : "#ffffff", // Background color for selected and hovered options
      color: state.isSelected ? "#000000" : "#909090", // Text color for selected option
      fontFamily: '"Helvetica", sans-serif',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#ffffff", // Background of the dropdown menu
      borderColor: "#dfdede",
      borderRadius: "10px", // Custom border-radius for the dropdown menu
      overflow: "hidden",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#909090", // Color of the selected value
      fontFamily: '"Helvetica", sans-serif',
    }),
  };





  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchStrategies = async () => {
      try {

        const strategies = await authService.topStratagies(token);
        const strategyOptions = strategies.data.map(strategy => ({
          value: strategy.stratagyName,
          label: strategy.stratagyName,
        }));
        setStrategyOptions(strategyOptions);

      } catch (error) {
        console.error('Error fetching strategies:', error);
      }
    };

    fetchStrategies();
  }, []);

  const handleStrategyChange = (selectedOptions) => {
    console.log("Selected Strategies:", selectedOptions); // Debugging
    if (Array.isArray(selectedOptions)) {
      setSelectedStrategy(selectedOptions); // Update state
    } else {
      setSelectedStrategy([]); // Reset if no options selected
    }
  };

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="profile">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 col-lg-12 profile_pad_top">
              <div className="profile_main">
                <div className="profile_span">Profile</div>
                <div className="row">
                  <div className="col-lg-2">
                    <div className="profile_pos_rel">
                      <img
                        src={require("../assests/icons/home-user.png")}
                        alt="usericon"
                        className="user-img-profile"
                      />
                      {/* <div className="profile_abs_edit">
                        <img
                          src={require("../assests/icons/profile-edit.png")}
                          alt="Profile edit"
                          className="profile_edit_img"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="profile_right_totalmain">
                      {/* <div className='profile_right_first'>
                                        <div className='profile_suhead_main'>
                                            <img src={require("../assests/icons/profile-info.png")} alt='Profile Info' className='profile_subheads_logo' />
                                            <span className='profile_suhead_span'>
                                            Personal info
                                            </span>
                                        </div>
                                        <div className='profile_rightbox_main'>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'>Username</span>
                                                <span className='prof_boxcenter_span'>Jhonsnow661@gmail.com</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> User ID</span>
                                                <span className='prof_boxcenter_span'> 28de0ad9-4187-406f-84bd-3863ab6a1ce7</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'>Country/Region</span>
                                                <span className='prof_boxcenter_span'>India</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                      {/* <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-info.png")}
                            alt="Profile Info"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Personal info
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Username
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Jhonsnow661@gmail.com
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                User ID
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {" "}
                                  28de0ad9-4187-406f-84bd
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Country/Region
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  India
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-info.png")}
                            alt="Profile Info"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Personal info
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          {/* Username Section */}
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                Username
                              </span>
                            </div>
                            <div className="col-4 ">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.displayname}
                                </span>{" "}
                                {/* Directly use user.displayname */}
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                onClick={() => openModal("username")}
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>

                          <hr />
                          {/* Country Section */}
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">Country/Region</span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {options.find(option => option.value === user.country)?.label || user.country}
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button className="prof_chang_box" onClick={() => openModal("country")}>
                                <span className="prof_chang_box_span">Change</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/account-details.png")}
                            alt="Acount details"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Account details
                          </span>
                        </div>
                        <div className="profile_rightbox_main">

                          <hr />
                          {/* Email Section */}
                          <div className="row">
                            <div className="col-md-4 col-6">
                              <span className="home-viewasset-span">
                                Email Address
                              </span>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.email}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4">

                            </div>
                          
                          </div>
                        </div>
                      </div>
                      {/* <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/account-details.png")}
                            alt="Acount details"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">
                            Account details
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Email Address
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Jhonsnow661@gmail.com
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalsecond"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                login password{" "}
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {" "}
                                  R*********{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <button
                                className="prof_chang_box"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModalsecond"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className='profile_right_first'>
                                        <div className='profile_suhead_main'>
                                            <img src={require("../assests/icons/profile-share.png")} alt='Share profile' className='profile_subheads_logo' />
                                            <span className='profile_suhead_span'>
                                            Share
                                            </span>
                                        </div>
                                        <div className='profile_rightbox_main'>
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> My invitation code</span>
                                                <span className='prof_boxcenter_span'>https://invest.untrade.io/auth/si...</span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='profile_box_divrow'>
                                                <span className='home-viewasset-span'> Invitation link </span>
                                                <span className='prof_boxcenter_span'> https://invest.untrade.io/auth/si... </span>
                                                <div className='prof_chang_box'>
                                                    <span className='prof_chang_box_span'>Change</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-share.png")}
                            alt="Share profile"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">Share</span>
                        </div>
                        <div className="profile_rightbox_main">
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                My invitation code
                              </span>
                            </div>

                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  {user.referralCode
                                    ? user.referralCode
                                    : "Loading..."}
                                </span>
                              </div>
                            </div>

                            <div className="col-4 profile_box_divrow">
                              <div className="prof_chang_box" onClick={() => copyToClipboard(user.referralCode)}>
                                <span
                                  className="prof_chang_box_span"
                                >
                                  Copy
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Invitation link{" "}
                              </span>
                            </div>

                            <div className="col-4">
                              {/* <div className="prof_tablecenter_scroll" onClick={copyToClipboardlink}> */}
                              <div className="prof_tablecenter_scroll">
                                <span
                                  className="prof_boxcenter_span"
                                  // onClick={copyToClipboardlink}
                                >
                                  {user.referralUrl || "Generating link..."}
                                </span>
                              </div>
                            </div>

                            <div className="col-4 profile_box_divrow">
                              <div className="prof_chang_box" onClick={() => copyToClipboard(user.referralUrl)}>
                                <span
                                  className="prof_chang_box_span"
                                  
                                >
                                  Copy
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile_right_first">
                      <div className="profile_suhead_main">
                             <img
                              src={require("../assests/icons/security-withdraw.png")}
                              alt="Withdrawal addresses"
                              className="secu_head_img icons-light"
                            />
                            <img
                              src={require("../assests/icons/security-withdraw-dark.webp")}
                              alt="Withdrawal addresses"
                              className="secu_head_img icons-dark"
                            />
                          <span className="profile_suhead_span">
                          Withdrawal addresses
                          </span>
                        </div>
                        <div className="profile_rightbox_main">
                      <div className="row withdraw_head_add ">
                      <div className="col-4">
                              <span className="home-viewasset-span">
                              Add your withdraw address
                              </span>
                            </div>
                            <div className="col-4 display_none"></div>
                        <div className="col-4 profile_box_divrow">
                          <div className="h-100 d-flex align-items-center">
                            <Link to="/security-withdraw">
                              <button
                                className="prof_chang_box"
                                type="button"
                              >
                                <span className="prof_chang_box_span">
                                  Change
                                </span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-4">
                          <span className="home-viewasset-span">Strategy</span>
                        </div>
                        <div className="col-4">
                          <div className="prof_tablecenter_scroll">
                            <span className="prof_boxcenter_span">
                              {activeStrategy || "No active strategy"}
                            </span>
                          </div>
                        </div>
                        <div className="col-4 profile_box_divrow">
                          <button className="prof_chang_box" onClick={() => openModal("strategy")}>
                            <span className="prof_chang_box_span">Change</span>
                          </button>
                        </div>
                      </div> */}




                      <div className="profile_right_first">
                        <div className="profile_suhead_main">
                          <img
                            src={require("../assests/icons/profile-setting.png")}
                            alt="Profile setting"
                            className="profile_subheads_logo"
                          />
                          <span className="profile_suhead_span">Settings</span>
                        </div>
                        <div className="profile_rightbox_main">
                          {/* <div className="row">
                            <div className="col-4">
                              <span className="home-viewasset-span">
                                {" "}
                                Notification
                              </span>
                            </div>
                            <div className="col-4">
                              <div className="prof_tablecenter_scroll">
                                <span className="prof_boxcenter_span">
                                  Get notified about the trade
                                </span>
                              </div>
                            </div>
                            <div className="col-4 profile_box_divrow">
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                                onColor="#31BD65" // Color inside the switch when on
                                offColor="#b0bec5" // Color inside the switch when off
                                handleDiameter={18} // Diameter of the switch handle (button)
                                height={22} // Height of the switch
                                width={37} // Width of the switch
                                uncheckedIcon={false} // No icon when off
                                checkedIcon={false} // No icon when on
                                handleStyle={{
                                  boxShadow: "none", // This removes the glow or shadow around the handle
                                  backgroundColor: "white", // Ensure the handle is white
                                }}
                              />
                            </div>
                          </div> */}
                          <hr />
                          <div className="row">
                            <div className="col-md-4 col-6">
                              <span className="home-viewasset-span">
                                {" "}
                                Exit WYscale{" "}
                              </span>
                            </div>
                            <div className="col-md-4 display_none"></div>
                            <div className="col-md-4 col-6 profile_box_divrow">
                              <div
                                className="prof_bottom_logout"
                                onClick={handleLogout}
                              >
                                <span className="prof_bottom_logout_span">
                                  Log Out
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">
                          <div class="modal-header lvl-one-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                              <span className="home_user_display">
                                <img
                                  src={require("../assests/icons/profile-info.png")}
                                  alt="Profile Info"
                                  className="profile_subheads_logo mb-1 mx-2"
                                />{" "}
                                Personal info
                              </span>
                            </h1>
                            <button
                              type="button"
                              class="btn-close btn-close-custom"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body personal_verify_body lvl-one-body">
                            <div className="mt-2 d-flex flex-column gap-3">
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Username
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Username"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  User ID
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your User ID"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Country/Region
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Country/Region"
                                />
                              </div>
                              <div className="">
                                <div className="home_topstary_follow">
                                  <span>Save</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}


                    {isModalOpen && (
                      <div
                        className="modal fade show"
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog-centered modal-md"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header lvl-one-header">
                              <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                              >
                                Edit {editableField.charAt(0).toUpperCase() + editableField.slice(1)}
                              </h1>
                              <span className="btn-close-new" onClick={() => setIsModalOpen(false)}>
                                X
                              </span>
                            </div>
                            <div className="modal-body personal_verify_body lvl-one-body">
                              <div className="mt-2 d-flex flex-column gap-3">
                                <div className="profile_edit_inpmain">
                                  <span className="home-viewasset-span">
                                    {editableField.charAt(0).toUpperCase() + editableField.slice(1)}
                                  </span>
                                  {editableField === "country" ? (
                                    <Select
                                      options={options}
                                      value={options.find(option => option.value === selectedCountry)} // Ensure value is an object
                                      onChange={handleCountryChange}
                                      placeholder={user.country}
                                      styles={customStyles}
                                      className="register-country-dropdown"
                                    />
                                  ) : editableField === "strategy" ? (
                                    <Select
                                      options={strategyOptions}
                                      value={selectedStrategy} // Array of selected strategies
                                      onChange={handleStrategyChange}
                                      placeholder="Select your strategies"
                                      styles={customStyles}
                                      className="register-country-dropdown"
                                      isMulti // Enable multi-select
                                      closeMenuOnSelect={false} // Keep dropdown open for multiple selections
                                    />

                                  ) : (
                                    <input
                                      type="text"
                                      placeholder={`Enter your ${editableField}`}
                                      value={inputValueref.current}
                                      onChange={handleInputChange}
                                    />
                                  )}
                                  {error && (
                                    <div
                                      className="error-message"
                                      style={{ color: "red", marginTop: "5px" }}
                                    >
                                      {error}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <button
                                    className="btn profile_savebtn"
                                    onClick={handleSaveChanges}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}



                    <div
                      class="modal fade"
                      id="exampleModalsecond"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered modal-md">
                        <div class="modal-content">
                          <div class="modal-header lvl-one-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                              <span className="home_user_display">
                                <img
                                  src={require("../assests/icons/account-details.png")}
                                  alt="Acount details"
                                  className="profile_subheads_logo mb-1 mx-2"
                                />{" "}
                                Account details
                              </span>
                            </h1>
                            <button
                              type="button"
                              class="btn-close btn-close-custom"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body personal_verify_body lvl-one-body">
                            <div className="mt-2 d-flex flex-column gap-3">
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  Email Address
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your Email Address"
                                />
                              </div>
                              <div className="profile_edit_inpmain">
                                <span className="home-viewasset-span">
                                  login password
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter your login password"
                                />
                              </div>
                              <div className="mt-3">
                                <div className="home_topstary_follow">
                                  <span>Save</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Profile;
