import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useNavigate } from "react-router-dom";
import { verifyAuth } from "../services/authservices";

function Privacypolicy() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const advanceSectionRef = useRef(null);
  const [userAuth, setAuth, userAuthref] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (advanceSectionRef.current) {
        const sectionTop = advanceSectionRef.current.offsetTop;
        const sectionHeight = advanceSectionRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  useEffect(() => {
    checkuserAuth();
  }, [0]);

  const checkuserAuth = async () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      try {
        const data = await verifyAuth(token);
        if (data && data.status) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error("Error auth user details:", error);
        setAuth(false);
      }
    } else {
      setAuth(false);
    }
  };

  const nav_page = () => {
    if(!userAuthref.current){
      navigate("/register");
    }else{
      navigate("/home");
    }
  };

  return (
    <>
      <main className="wyscale_landing_main">
        <section>
          <Header />
        </section>
        <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content" id="privacy-condition">
            <h2>Privacy Policy</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                    WYscale ("WYscale," "we," or "us") respects your concerns about privacy and values the relationship we have with you. This Privacy Policy applies to information We collect when You use Our website and other online products and services that link to this Privacy Policy (collectively, the "Services").
                    </p>
                   
                    <h3>INFORMATION YOU PROVIDE TO US</h3>
                    <h4>
                    We collect information You provide directly to Us. We collect information when You connect your wallet, participate in any interactive feature of the Services, complete an exchange transaction, request customer support or otherwise communicate with us, including:
                    </h4>

                   <p>
                   **Wallet information:**Wallet addresses (public blockchain addresses), transaction, and balance information (blockchain data) that is accessible when interacting with WYscale. We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc. And no other login information (e.g., the User’s name, email address, telephone number, postal address amongst others) is required to use any feature of our Services.
                   </p>

                   <p>
**Blockchain information:**We collect the information about which Blockchain You are interacting with. Please note that when you use the Interface, you are interacting with a particular blockchain, which provides transparency into your transactions. WYscale does not control and is not responsible for any information you make public on the blockchain by taking actions through the Interface.
                   </p>

                   <p>
                   **Transaction Information:**We collect transaction related information for transactions conducted by End Users through the Services (“Transactions”) but it is anonymized. Transaction information includes price, quantity, and any associated or supporting data related to the trade. Transaction information is used to provide the Services, facilitate the Transactions, provide support, and to operate, maintain and improve the Services.
                   </p>
<p>
Hence, When you use the Interface, the only information we collect from you is your blockchain wallet address, completed transaction hashes, and the token names, symbols, or other blockchain identifiers of the tokens that you swap. We do not collect any personal information from you (e.g., your name or other identifiers that can be linked to you). We do, however, use third-party service providers, like Infura, Cloudflare, and Google Analytics, which may receive or independently obtain your personal information from publicly-available sources. We do not control how these third parties handle your data and you should review their privacy policies to understand how they collect, use, and share your personal information.
</p>
                    <h5>
                    INFORMATION WE COLLECT AUTOMATICALLY WHEN YOU USE THE SERVICES{" "}
                    </h5>
                    <p>**Exchange information:**We collect information about the transactions You complete using the Services, including the preparing of the strategy You provide for the facilitation of the trade of the Trading Assets, and the transaction information involved in such trading.</p>
<p>
**Automated collection:**Details of the User’s device, computer and device information, activity on the website, activity on the website and other similar information. The Company collects this information for analytics, improvement of the services and for review purposes.
</p>
<p>
**Strategy Information:**The strategy created by You is Your property and intellectual property owned by You. You will be responsible to protect the strategy created by You, and We shall not, under any circumstances, be liable if Your strategy is leaked or accessed by anyone else for any reason and/or in any manner whatsoever.
</p>
                    <h5>
                    SHARING AND DISCLOSURE{" "}
                    </h5>
                    <p>
                    Your payment and transaction details will not be shared with other users or third parties under any circumstances.
                    </p>
                   <p>
                   The information shared by You with Us will not be shared or disclosed with any other user or third party except for any anonymized information used for analytics. The information shared by You may be disclosed to external service providers such as Google Analytics etc., to assist Us in collating and analysing User information and enabling us to make the use of the Services and the Site more user-friendly.
                   </p>

                  
                    
                    <h4 className="mt-4">
                      {" "}
                      CRYPTOGRAPHIC HASHES{" "}
                    </h4>

                   <p>
                   You agree and acknowledge that when You create Your investment strategy or place the fund/investment plan request on Your Account in accordance with the Terms of Use, through the use of cryptographic hashes and keys, You take full responsibility for the risks involved. By using the Services, You automatically accept liability of any loss, damages or implications incurred through the use of this Site.
                    </p>

                    <h4>SOCIAL NETWORK PLUGINS </h4>
                    <p>
                    This Website incorporates plugins and/or buttons for social networks, in order to allow easy sharing on your favourite social networks. These plugins are programmed so as not to set any cookies when assessing the page to safeguard the privacy of users. Cookies may be set, if you make voluntary use of the plugin. The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks.
                    </p>

                 

                    <h4>USE OF SERVICES </h4>
                    <p>
                    By using the Services, You automatically accept liability of any loss, damages or implications incurred through the use of this Site. WYscale and its owners, shareholders, and officers will not be held liable for any loss of Trading Assets or any financial loss that may be incurred by You.
                        </p>
                        <h4>YOUR CONSENT </h4>
                    <p>
                    By using our site, you consent to our web site privacy policy.
                    </p>

                    <h4>CLARIFICATIONS</h4>
<p>If You have any queries regarding this Privacy Policy, please contact us at support@wyscale.com</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section className="footer_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="getting_card_content">
                  <img
                    src={require("../assests/Logo.webp")}
                    alt="logo"
                    className="footer_logo"
                  />
                  <span className="getcard_sub_subhead padding-right">
                    Unlock the Power of Automated Crypto Trading with Wyscale.
                    Leverage cutting-edge algorithms, AI-driven strategies, and
                    smooth API integration across top exchanges. Enjoy 24/7
                    trading automation while maintaining complete control over
                    your assets. Begin your journey to smarter trading with
                    Wyscale!
                  </span>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Quick Links</span>
                  <div className="footer_links_div">
                    <Link to="/home">
                      <span className="footer_links_span">Home</span>
                    </Link>
                    {/* <Link to="">
                      <span className="footer_links_span">Portfolio</span>
                    </Link> */}
                    <Link to="/strategies">
                      <span className="footer_links_span">Strategies</span>
                    </Link>
                    <Link to="/assets">
                      <span className="footer_links_span">Assets</span>
                    </Link>
                    <Link to="/referral">
                      <span className="footer_links_span">Refer & Earn</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Pages</span>
                  <div className="footer_links_div">
                    <Link to="/termscondition">
                      <span className="footer_links_span">Terms and Conditions</span>
                    </Link>
                    <Link to="" onClick={() => {
    document.getElementById('privacy-condition')?.scrollIntoView({ behavior: 'smooth' });
  }}>
                      <span className="footer_links_span"> Privacy Policy</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">About Us</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Social Media</span>
                  <div className="footer_links_div">
                    <Link to="">
                      <span className="footer_links_span">LinkedIn</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Instagram</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Facebook</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Twitter</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="footer_last">
          <span className="footer_copyrights">
            ©2025 WYScale. All Rights Reserved.
          </span>
        </div>
      </main>
    </>
  );
}

export default Privacypolicy;
