import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetails } from "../services/authservices";
import authService from "../services/authservices";
import ReactApexChart from 'react-apexcharts';
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from 'react-spinners'
import dayjs from "dayjs";
function Home() {
  const [currency, setCurrency] = useState("USDC");
  const [network, setNetwork] = useState("BEP20");
  const [transactions, setTransactions] = useState([]);
  const [fuelWallet, setFuelWallet] = useState(0);
  const [addressData, setAddressData] = useState(null);
  const [planDetails, setPlanDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    botStatus: "DeActive",
    displayname: null,
    country: null,
    isAuthenticated: false,
    exchanges: [],
  });
  
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [state, setState] = useState({
    series: [
      {
        name: 'Annual ROI',
        data: [0], // Initial data with default 0
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 350,
        toolbar: {
          tools: {
            download: false, // Disable the download button
          }},
        zoom: {
          enabled: false,
        },
        
      },
      
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth', // Line shape
        width: 2, // Line thickness
        colors: ['#2ebd85'], // Line color: red
      },
      fill: {
        type: 'solid', // Background fill type
        colors: ['#1b2a2a'], // Background color: green
      },
      title: {
        text: '',
        align: 'left',

      },
      subtitle: {
        text: '',
        align: 'left',
      },
      xaxis: {
        categories: ['Start'], // Initial X-axis category
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0, // Start from 0
        max: 100, // Default maximum range
        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false, // Hide grid lines if desired
      },
      legend: {
        show: false,
      },
    },
  });

  const truncateText = (text, maxLength) => {
    if (!text || typeof text !== "string") {
      return "--";
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " . . .";
    }
    return text;
  };
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
            botStatus: data.botStatus,
            exchanges: data.exchanges,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            exchanges: [],
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          exchanges: [],
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
    getStratagy();
    marketCurrencyList();
    fetchBalance();
  }, [0]);
  const [marketData, setmarketData, marketDataref] = useState([]);
  const [marketLoader, setmarketLoader] = useState(false);
  useEffect(() => {
    let socket;

    const createSocketConnection = () => {
      socket = new WebSocket('ws://localhost:8080');

      socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.status) {
          setmarketData((prevData) => {
            return prevData.map(item =>
              item.symbol === data.data.symbol
                ? { ...item, price: data.data.price, change: data.data.change }
                : item
            );
          });
          //console.log('Market data updated:', data.data);
        } else {
          console.error('Error receiving market data:', data.message);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed. Reconnecting...');
        setTimeout(createSocketConnection, 5000); // Reconnect after 5 seconds
      };
    };

    createSocketConnection();

    return () => {
      if (socket) socket.close();
    };
  }, []);




  // Empty dependency array ensures this effect only runs once when the component mounts

  // useEffect(() => {
  //   // Connect to WebSocket server
  //   const ws = new WebSocket("ws://localhost:8080");

  //   ws.onmessage = (event) => {
  //     const { data } = JSON.parse(event.data);

  //     if (data) {
  //       setmarketData(data);
  //     }
  //   };

  //   ws.onerror = (error) => {
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, []);

  const marketCurrencyList = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setmarketLoader(true);
        const data = await authService.marketCurrencyList(token);
        if (data.status) {
          setmarketLoader(false);
          setmarketData(data.data);
        } else {
          setmarketLoader(false);
          setmarketData([]);
        }
      } catch (error) { }
    }
  };
  const handleCopy = (txID) => {
    if (handleCopy.toastVisible) return; 
    
    handleCopy.toastVisible = true; 
  
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);  
    
    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          toast.error(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        })
        .finally(() => {
          setTimeout(() => {
            handleCopy.toastVisible = false; 
          }, 2000);  
        });
    }, 300);  
  };
  
  const copyToClipboard = (() => {
    let toastVisible = false; 
    let timeout = null; 
  
    return (uid) => {
      if (toastVisible) return;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        navigator.clipboard
          .writeText(uid)
          .then(() => {
            toastVisible = true; 
            showsuccessToast("UID copied to clipboard!", {
              onClose: () => {
                toastVisible = false; 
              },
            });
          })
          .catch(() => {
            toastVisible = true; 
            showerrorToast("Failed to copy UID", {
              onClose: () => {
                toastVisible = false; 
              },
            });
          });
      }, 300);
    };
  })();
  
  const handleFollowStrategy = async (data) => {
    try {
      setLoader(true)
      var obj = {
        action: data.buttonLabel,
        strategyId: data._id,
      };
      const response = await authService.handleFollowStrategy(obj);
      setStratagyLoader(false);
      const message = response.Message?.trim()
      if (message === 'Strategy is Followed') {
        showsuccessToast(response.Message);
        getStratagy();
        setLoader(false)
      } else if(message === 'Strategy is Unfollowed') {
      console.log(message,"response.Message")
        showsuccessToast(response.Message);
        getStratagy();
        setLoader(false)
      }
      else{
        showerrorToast(response.Message);
        setLoader(false)
      }
    } catch (err) { setLoader(false) }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const [StratagyLoader, setStratagyLoader] = useState(false);
  const [StratagyList, setStratagyList, StratagyListref] = useState([]);

  const getStratagy = async (data) => {
    try {
      setLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      if (response.status == true) {
        if (response.data.length > 0) {
          const updatedSeries = response.data.map((options) => {
            const annualROI = options?.annualROI || 0;
            console.log(annualROI, "annualROI")
            return {
              name: options.stratagyName || "Annual ROI",
              data: [0, annualROI],
            };
          });

          console.log("Updated series:", updatedSeries);

          const maxYValue = Math.max(
            100,
            ...response.data.map((options) => options?.annualROI || 0)
          );


          setState((prev) => ({
            ...prev,
            series: updatedSeries,
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: ["Start", "End"],
              },
              yaxis: {
                ...prev.options.yaxis,
                min: 0,
                max: Math.ceil(maxYValue / 10) * 10,
              },
            },
          }));
        } else {

          console.log("No data found, using defaults.");
          setState((prev) => ({
            ...prev,
            series: [
              {
                name: "Annual ROI",
                data: [0, 10],
              },
            ],
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: ["Start", "Target"],
              },
              yaxis: {
                ...prev.options.yaxis,
                min: 0,
                max: 100,
              },
            },
          }));
        }
        setStratagyList(response.data);
        setLoader(false);
      } else {
        setStratagyList(response.data);
        setLoader(false);
      }
    } catch (error) { setLoader(false); }
  };

  const navigateStratagy = () => {
    navigate("/strategies");
  };

   const getPlanDetails = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const response = await authService.getPlanDetails(token);
        setLoading(false);
        if (response.data && Array.isArray(response.data)) {
          setPlanDetails(response.data);
        } else {
          setPlanDetails([]); 
          localStorage.setItem("freemiumActivated", false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching plan details:", error);
      }
    };

    const updateUserBotStatus = async (data) => {
      try {
        if (!planDetails || planDetails.length === 0) {
          showerrorToast("Please upgrade the plan.");
          return;
        }
    
        if (!user || !user.exchanges || user.exchanges.length === 0) {
          showerrorToast("You must have at least one exchange connected.");
          return;
        }
    
        // const minBalanceRequired = planDetails[0].minbalancereq;

        const minBalanceRequired = planDetails[0].min_fuel;
        
        const numericFuelWallet = parseFloat(fuelWallet) || 0;
        const numericMinBalanceRequired = parseFloat(minBalanceRequired) || 0;
    
        if (numericFuelWallet < numericMinBalanceRequired) {
          showerrorToast(
            `Insufficient fuel wallet balance. A minimum of ${minBalanceRequired} is required.`
          );
          return;
        }
    
        // Set loader before API call
        setStratagyLoader(true);
    
        const obj = {
          action: data,
        };
    
        const response = await authService.updateUserBotStatus(obj);
    
        // Reset loader
        setStratagyLoader(false);
    
        if (response.status) {
          showsuccessToast(response.Message);
        } else {
          showerrorToast(response.Message);
        }
    
        // Refresh necessary data
        getStratagy();
        fetchUserDetails();
    
      } catch (err) {
        setStratagyLoader(false); // Ensure the loader is reset in case of errors
        console.error("Error updating bot status:", err);
        showerrorToast("An error occurred while updating the bot status.");
      }
    };
    
  const fetchAddressData = async () => {
    try {
      const data = await authService.getUserAddressNetwork(currency, network);
      if (data.status) {
        setAddressData(data.data);
      } else {
        console.log(data.Message);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await authService.getDepositHistory();
      if (response.status) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          // Filter unique transactions based on transactionId
          const uniqueTransactions = response.data.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.transactionId === value.transactionId)
          );
          setTransactions(uniqueTransactions);
        } else {
          setTransactions([]);
        }
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      showerrorToast('Failed to load transactions')
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddressData();
    fetchTransactions();
  }, [currency, network]);
  useEffect(() => {
    getPlanDetails();
  }, []);



  const [totalBalance, setTotalBalance, totalBalanceref] = useState(0);
  const [profitData, setProfitdata] = useState(null);
  const fetchBalance = async () => {
    try {
      // const response = await authService.getTransactions();
      const response = await authService.getAssets();
      console.log("fetchBalance",response)
      if (response.status) {
        setTotalBalance(parseFloat(response.totalBalanceUSDT) || 0);
        setFuelWallet(parseFloat(response?.userWalletFuelAmount));
        setProfitdata(response.profits)
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="home">
        <div className="container">
          <div className="home_main">
            <div className="home_first_main">
              <div className="home_first_left">
                <div className="home_user_main">
                  <img
                    src={require("../assests/icons/home-user.png")}
                    alt="User"
                    className="home_user_icon"
                  />
                  <div className="home_user_name">
                    <span className="home_user_display">{user.email}</span>
                    <div className="home_uid_main">
                      <span className="home_user_uid">{user.uid}</span>
                      <i
                        class="ri-file-copy-line cursor-pointer uid_copy"
                        onClick={() => copyToClipboard(user.uid)}
                      ></i>
                    </div>
                  </div>
                </div>
                <span className="home_slash">|</span>
                <div className="home_bln_top">
                  <div className="home_user_bln">
                    <span className="home_userbln_head">
                      Total Value (USDT)
                    </span>
                    <span className="footer_head">
                      {totalBalanceref.current}
                    </span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head">Today’s PNL</span>
                    <span className="footer_head">{((profitData?.todayProfit) ? profitData?.todayProfit : '0.00')}%</span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head">Yesterday’s PNL</span>
                    <span className="footer_head">{((profitData?.yesterdayProfit ? profitData?.yesterdayProfit : '0.00'))}%</span>
                  </div>

                  {/* <div className="home_user_bln">
                    <span className="home_userbln_head">
                      Binance Balance (USDT)
                    </span>
                    <span className="footer_head">0.00</span>
                  </div> */}

                  {/* <div className="home_user_bln">
                    <span className="home_userbln_head"> 7 Days Pnl</span>
                    <span className="footer_head">0.00%</span>
                  </div> */}
                  <div className="home_user_bln">
                    <span className="home_userbln_head"> 30 Days PNL</span>
                    <span className="footer_head">{((profitData?.monthlyProfit) ? profitData?.monthlyProfit : '0.00')}%</span>
                  </div>
                  <div className="home_user_bln">
                    <span className="home_userbln_head"> Overall PNL</span>
                    <span className="footer_head">{((profitData?.overallProfit) ? profitData?.overallProfit : '0.00')}%</span>
                  </div>
                </div>
              </div>
              <Link to="/assets">
                <div className="dash_first_right">
                  <img
                    src={require("../assests/icons/home-assest-view.png")}
                    alt="View Asset"
                    className="home_viewasset_img"
                  />
                  <span className="home-viewasset-span">View Assets</span>
                </div>
              </Link>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="h-100">
                  <img
                    src={require("../assests/home-banner.webp")}
                    alt="Trading Bot"
                    className="home_second_img"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home_second_rightmain">
                  <div className="manage_btn_row">
                    <span className="home_botstatus_span">Bot status</span>

                    <div className="home_bottop_right">
                    {user.botStatus != "Active" ? (
                      <i
                        class="bi bi-exclamation-circle home_user_info"
                        style={{ color: "#ee516d" }}
                      ></i>
                    ) : ("")}
                      {/* <i class="fa-regular fa-circle-check home_user_info_correct"></i> */}
                      {/* <span className="home_bot_inactive">
                        {" "}
                        {user.botStatus == "Active"
                          ? "Active"
                          : "In Active"}{" "}
                      </span> */}
                      {user.botStatus == "Active" ? (
                      <span className="home_bot_active">Active</span>
                      ) : (
                        <span className="home_bot_inactive">
                        {" "}
                        In Active
                      </span>
                      )}
                    </div>
                  </div>

                  <div className="home_activate_main">
                    <span className="home_botstatus_def">
                      Your bot is {user.botStatus === "Active" ? "active" : "inactive"}.
                      <span className="bot_condition">
                        {user.botStatus === "Active" ? "Deactivate" : "Activate"} your Bot!
                      </span>
                    </span>

                    {user.botStatus === "Active" ? (
                      <div
                        className="invest_main"
                        onClick={() => updateUserBotStatus("DeActive")}
                      >
                        <span className="invest_now">
                          Deactivate
                        </span>
                        <span className="invest_new">
                          Deactivate
                        </span>
                      </div>
                    ) : (
                      <div
                        className="invest_main"
                        onClick={() => updateUserBotStatus("Active")}
                      >
                        <span className="invest_now">
                          Activate
                        </span>
                        <span className="invest_new">
                          Activate
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="home_third_left">
                  <div className="home_third_topmain">
                    <div className="manage_btn_row">
                      <span className="home_botstatus_span">
                        Top strategies
                      </span>
                      <div
                        className="d-flex align-items-center gap-2 cursor-pointer viewall_stragies"
                        onClick={navigateStratagy}
                      >
                        <span className="home_starty_view">View All</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                      </div>
                    </div>
                    {loader ? (
                      <div className="loader_main loader_mainChange">
                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                        <p>Loading...</p>
                      </div>
                    ) :
                      <div className="row">
                        {StratagyListref.current.length > 0
                          ? StratagyListref.current
                            .slice(0, 3)
                            .map((options, index) => {
                              return (
                                <div className="col-lg-4">
                                  <div className="home_topstarty_inmain">
                                    <div className="home_topstary_inntop">
                                      <div className="top_startgy_intopintop">
                                        <span className="dca_main">
                                          {options.stratagyName}
                                        </span>
                                        <div className="reg_check_main">
                                          <div className="">
                                            <img
                                              src={require("../assests/icons/topstartegy-users.png")}
                                              alt="Users"
                                              className="topstartegy-users"
                                            />
                                            <span className="top-99">
                                              {" "}
                                              {options.followers}
                                            </span>
                                          </div>
                                          <div className="topstartegy_color_div">
                                            <span>
                                              ROI: {options.leverage}X
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="top_startgy_intopinbottom">
                                        <div className="top_startgy_inbottomlefthome">
                                          <span className="top_startgy_inbottomadvance">
                                            Annualized ROI
                                          </span>
                                          <span className="top_startgy_inbottompercen">
                                            {options.annualROI}%
                                          </span>
                                        </div>
                                        <div id="chart" className="chart_mainhome" >
                                          <ReactApexChart
                                            options={state.options}
                                            series={
                                              state.series[index]
                                                ? [state.series[index]]
                                                : []
                                            }
                                            type="area"
                                            height={130}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="home_topstary_follow"
                                      onClick={() =>
                                        handleFollowStrategy(options)
                                      }
                                    >
                                      <span>{options.buttonLabel}</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    }
                  </div>
                  <div className="home_third_topmain pb-0">
                    <div className="manage_btn_row">
                      <span className="home-viewasset-span">
                        {" "}
                        Recent Deposit
                      </span>
                      <Link to="/allhistory">
                        <div className="d-flex align-items-center gap-2 cursor-pointer viewall_stragies">
                          <span className="home_starty_view">View All</span>
                          <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="recent_depo_tablemain pb-0">
                      <div className="table-responsive table-borderless home_recent_table">
                        {loading ? (
                          <div className="loader_main loader_mainChange">
                            <ScaleLoader color="#36d7b7" height={10} width={3} />
                            <p>Loading...</p>
                          </div>
                        ) : (
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="text_left">Currency</th>
                                <th>Date & Time</th>
                                <th>Network</th>
                                <th>Amount</th>
                                <th>Transaction ID</th>
                              </tr>
                            </thead>


                            <tbody>
                              {transactions.length > 0 ? (
                                transactions.slice(0, 5).map((transaction, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="home_recent_firstdata">
                                        <img
                                          src={require("../assests/usdt-logo.png")}
                                          alt="USDT"
                                          className="usdt_home_recent"
                                        />
                                        <span>{transaction.currency}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <span>
                                        {isNaN(new Date(transaction.date))
                                          ? "null"
                                          : dayjs(transaction.date).format(
                                            "DD MMMM YYYY, hh:mm A"
                                          )}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{transaction.network}</span>
                                    </td>
                                    <td>
                                      <span>{transaction.amount}</span>
                                    </td>
                                    <td>
                                      <div className="home_uid_main">
                                        <span>{transaction.transactionId}</span>
                                        <i
                                          className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                          onClick={() => {
                                            handleCopy(transaction.transactionId);
                                          }}
                                        ></i>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5">
                                    <span className="depo_no_record">
                                      No transactions found
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="home_third_topmain mb-2">
                  <span className="home_right_marketspan">Market prices</span>
                  <div className="table-responsive table-borderless home_recent_table">
                    <table className="table">
                      <thead>
                        <tr> {/* Wrap the headers inside a <tr> */}
                          <th className="text_left">Name</th>
                          <th>Price</th>
                          <th>Change</th>
                        </tr>
                      </thead>

                      {marketLoader ? (

                        <tbody>
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              <div className="loader_main loader_mainChange">
                                <ScaleLoader color="#36d7b7" height={10} width={3} />
                                <p>Loading...</p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : marketDataref.current.length > 0 ? (
                        <tbody>
                          {marketDataref.current.slice(0, 7).map((options, index) => (
                            <tr key={index}>
                              <td>
                                <div className="home_recent_firstdata">
                                  <img
                                    // src={options.imageUrl}
                                    src={require(`../assests/coins/${options.symbol.toLowerCase()}.png`)}
                                    alt={options.symbol}
                                    className="usdt_home_recent"
                                  />
                                  <span> {options.symbol}</span>
                                </div>
                              </td>
                              <td>
                                <div className="home_market_center">
                                  <span> {options.price}</span>
                                  <span className="home_market_center_dim">${options.Usdprice}</span>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={
                                    options.change24Hour > 0
                                      ? "home_market_lastdata"
                                      : "home_market_lastdatared"
                                  }
                                >
                                  {options.change24Hour}%
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (

                        <tbody>
                          <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                              No data available
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
