
import React, { useState, useRef, useEffect, useCallback } from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { ScaleLoader } from 'react-spinners'
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import service from "../services/authservices";

function DepositHistory() {
    const [currency, setCurrency] = useState("USDC");
    const [network, setNetwork] = useState("BEP20");
    const [addressData, setAddressData] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [currentPageDp, setCurrentPageDp] = useState(1);
    const [totalPagesDp, setTotalPagesDp] = useState(0);
    const [limitDp, setLimitDp] = useState(10);

    const fetchAddressData = async () => {
        try {
            const data = await service.getUserAddressNetwork(currency, network);
            if (data.status) {
                setAddressData(data.data);
            } else {
                console.log(data.Message);
            }
        } catch (error) {
            console.error("Error fetching address data:", error);
        }
    };

    useEffect(() => {
        fetchWithdrawalHistory(currentPageDp, limitDp);
    }, [currentPageDp, limitDp]);

    const fetchWithdrawalHistory = async (page, limit) => {
        const response = await service.getDepositHistory(page, limit);
        if (response.status) {
            setTransactions(response.data);
            setTotalPagesDp(Math.ceil(response.total / limit));
        } else {
            console.error('Failed to fetch withdrawal history:', response.Message);
        }
    };

    // const fetchTransactions = async (page, limit) => {
    //     try {
    //         setLoader(true);
    //         const response = await service.getDepositHistory(page, limit);

    //         if (response.status) {
    //             if (Array.isArray(response.deposits) && response.deposits.length > 0) {

    //                 const uniqueTransactions = response.deposits.filter(
    //                     (value, index, self) =>
    //                         index === self.findIndex((t) => t.txID === value.txID)
    //                 );

    //                 setTransactions(uniqueTransactions);
    //                 setTotalPagesDp(Math.ceil(response.total / limit));
    //             } else {

    //                 setTransactions([]);
    //             }
    //         } else {

    //             setTransactions([]);
    //             toast.error(
    //                 <div className="toast-ml">
    //                     <h4 className="toast-title">Error!</h4>
    //                     <p className="toast-message">{response.message || "Failed to load transactions"}</p>
    //                 </div>
    //             );
    //         }
    //     } catch (error) {
    //         console.error("Error fetching transactions:", error);
    //         setTransactions([]);
    //         toast.error(
    //             <div className="toast-ml">
    //                 <h4 className="toast-title">Error!</h4>
    //                 <p className="toast-message">Failed to load transactions</p>
    //             </div>
    //         );
    //     } finally {
    //         setLoader(false);
    //     }
    // };
    const handlePageChangeDp = (event, value) => {
        setCurrentPageDp(value);
    };

    useEffect(() => {
        fetchAddressData();
        // fetchTransactions();
    }, [currency, network]);


    const handleCopy = (txID) => {
        if (handleCopy.toastVisible) return;
      
        handleCopy.toastVisible = true; 
      
        if (handleCopy.timeout) clearTimeout(handleCopy.timeout);
      
        handleCopy.timeout = setTimeout(() => {
          navigator.clipboard
            .writeText(txID)
            .then(() => {
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">
                    Transaction ID copied to clipboard!
                  </p>
                </div>
              );
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">Failed to copy Transaction ID</p>
                </div>
              );
            })
            .finally(() => {
              setTimeout(() => {
                handleCopy.toastVisible = false;
              }, 2000);  
            });
        }, 300); 
      };
      

      let toastVisible = false; // Boolean flag to manage toast visibility

      const handleCopyAdd = (address) => {
        if (toastVisible) {
          return; // Don't show a new toast if one is already visible
        }
      
        // Prevent triggering multiple times rapidly using debounce
        if (handleCopyAdd.timeout) clearTimeout(handleCopyAdd.timeout);
      
        handleCopyAdd.timeout = setTimeout(() => {
          toastVisible = true; // Set toast visibility to true (toast is being shown)
      
          navigator.clipboard
            .writeText(address)
            .then(() => {
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">Address copied to clipboard!</p>
                </div>
              );
            })
            .catch((err) => {
              console.error("Failed to copy text:", err);
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">Failed to copy address</p>
                </div>
              );
            })
            .finally(() => {
              // Reset the toast visibility after 5 seconds (duration of the toast)
              setTimeout(() => {
                toastVisible = false; // Toast is hidden after 5 seconds
              }, 5000);
            });
        }, 300); // Debounce time of 300ms to prevent fast repeated clicks
      };
      



    return (

        <div className="recent_depo_main">
            <div className="manage_btn_row">
                <span className="home_right_marketspan">Recent Deposit</span>
                <Link to="/deposit">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">Back to Deposit</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                </Link>
            </div>
            <div className="recent_depo_tablemain pb-0">
                <div className="table-responsive table-borderless home_recent_table">
                    {loader ? (
                        <div className="loader_main loader_mainChange">
                            <ScaleLoader color="#36d7b7" height={10} width={3} />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text_left">Currency</th>
                                        <th>Date & Time</th>
                                        <th>Network</th>
                                        <th>Amount</th>
                                        <th>Transaction ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.length > 0 ? (
                                        transactions.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="home_recent_firstdata">
                                                        <img
                                                            src={require("../assests/usdt-logo.png")}
                                                            alt="USDT"
                                                            className="usdt_home_recent"
                                                        />
                                                        <span>{transaction.currency}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>
                                                        {isNaN(new Date(transaction.date))
                                                            ? "null"
                                                            : dayjs(transaction.date).format(
                                                                "DD MMMM YYYY, hh:mm A"
                                                            )}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span>{transaction.network}</span>
                                                </td>
                                                <td>
                                                    <span>{transaction.amount}</span>
                                                </td>
                                                <td>
                                                    <div className="home_uid_main">
                                                        <span>{transaction.transactionId}</span>
                                                        <i
                                                            className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                                            onClick={() =>
                                                                handleCopy(transaction.transactionId)
                                                            }
                                                        ></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">
                                                <span className="depo_no_record">
                                                    No transactions found
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {totalPagesDp > 1 && (
                                <div className="pagination history_pagination_all">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPagesDp}
                                            page={currentPageDp}
                                            onChange={handlePageChangeDp}
                                            size="small"
                                            sx={{
                                                "& .MuiPaginationItem-root": {
                                                    color: "#fff",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "#ffc630 !important",
                                                    color: "#000",
                                                    "&:hover": {
                                                        backgroundColor: "#ffc630",
                                                        color: "#000",
                                                    },
                                                },
                                                "& .MuiPaginationItem-ellipsis": {
                                                    color: "#fff",
                                                },
                                                "& .MuiPaginationItem-icon": {
                                                    color: "#fff",
                                                },
                                            }}
                                        />
                                    </Stack>
                                </div>
                            )}
                        </>


                    )}
                </div>
            </div>
        </div>
    )
}


export default DepositHistory