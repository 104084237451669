
import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import authservices from "../services/authservices";


function FAQ() {
  const [faqType, setFaqType] = useState("Trade");
  const [faqs, setFaqs] = useState({});
  const faqCategories = [
    { key: "Trade", label: "Trade" },
    { key: "Deposit", label: "Deposit" },
    { key: "Withdraw", label: "Withdraw" },
    { key: "API Bind", label: "API Bind" },
    { key: "Team Management", label: "Team Management" },
    { key: "Trading Bots", label: "Trading Bots" },
    { key: "Strategies", label: "Strategies" },
    { key: "Risk and Fraud", label: "Risk and Fraud" },
  ];


  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await authservices.getFaq();
        if (response.status) {
          const groupedFaqs = response.data
            .filter((faq) => faq.type === "FAQ")
            .reduce((acc, faq) => {
              const { category } = faq;
              if (!acc[category]) acc[category] = [];
              acc[category].push(faq);
              return acc;
            }, {});
          setFaqs(groupedFaqs);
          const firstCategory = Object.keys(groupedFaqs)[0];
          if (firstCategory) {
            setFaqType(firstCategory);
          }
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);


  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">FAQs</span>
              <div className="faq_resp_changed_mainright mt-3">
                <div className="faq_col2_main h-100">
                  <span className="faq_cate_span">Categories</span>
                  <div className="mt-2">
                    {Object.keys(faqs).map((category) => (
                      <div
                        key={category}
                        className={faqType === category ? "faq_cate_main_active" : "faq_cate_main"}
                        onClick={() => setFaqType(category)}
                      >
                        <i className="fa-solid fa-chevron-right faq_cate_arrow"></i>
                        <span>{category}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="faq_col10_main h-100">
                  {faqs[faqType] && faqs[faqType].length > 0 ? (
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                      {faqs[faqType].map((faq, index) => (
                        <div key={faq._id} className="accordion-item-faq font-satoshi">
                          <h2 className="accordion-header" id={`flush-heading-${index}`}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapse-${index}`}
                              aria-expanded="false"
                              aria-controls={`flush-collapse-${index}`}
                            >
                              {faq.question}
                              <span className="icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </h2>
                          <div
                            id={`flush-collapse-${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`flush-heading-${index}`}
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div className="accordion-body-faq">
                              {faq.answer || "Answer not available"}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="faq_cate_span">No FAQs available for this category.</span>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
