import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import ReactApexChart from 'react-apexcharts';
import "react-toastify/dist/ReactToastify.css";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import {ScaleLoader} from 'react-spinners'
function Strategies() {
  useEffect(() => {
    getStratagy();
    getFollowedStratagy();
  }, []);

  const [StratagyLoader, setStratagyLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [StratagyList, setStratagyList, StratagyListref] = useState([]);
  
  const [state, setState] = useState({
    series: [
      {
          name: 'Annual ROI',
          data: [0], 
      },
  ],
    options: {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false,
            },
            toolbar: {
              tools: {
                download: false, // Disable the download button
              }},
        

        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2, 
          colors: ['#2ebd85'],
      },
      fill: {
          type: 'solid', 
          colors: ['#1b2a2a'],
      },
        title: {
            text: '',
            align: 'left',
            
        },
        subtitle: {
            text: '',
            align: 'left',
        },
        xaxis: {
          categories: ['Start'], 
          labels: {
              show: false,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
      },
      yaxis: {
        min: 0, 
        max: 100, 
        labels: {
            show: true,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
        grid: {
            show: false,
        },
        legend: {
          show: false,
      },
    },
});
  const [stateunfollow, setStateunfollow] = useState({
    series: [
      {
          name: 'Annual ROI',
          data: [0], 
      },
  ],
    options: {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
          curve: 'smooth', 
          width: 2, 
          colors: ['#2ebd85'], 
      },
      fill: {
          type: 'solid',
          colors: ['#1b2a2a'], 
      },
        title: {
            text: '',
            align: 'left',
            
        },
        subtitle: {
            text: '',
            align: 'left',
        },
        xaxis: {
          categories: ['Start'], 
          labels: {
              show: false,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
      },
      yaxis: {
        min: 0, 
        max: 100, 
        labels: {
            show: true,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
        grid: {
            show: false, 
        },
        legend: {
          show: false,
      },
    },
});


  const getStratagy = async (data) => {
    try {
      setLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      console.log(response, "response");
      if (response.status == true) {
        console.log(response.data,"response.data")
        if (response.data.length > 0) {
           const updatedSeries = response.data.map((options) => {
             const annualROI = options?.annualROI || 0; 
             console.log(annualROI,"annualROI")
             return {
               name: options.stratagyName || "Annual ROI",
               data: [0, annualROI], 
                buttonLabel: options.buttonLabel || "Follow",
             };
           });

           const unFollowseries =  response.data.filter((option,index) => option.buttonLabel === "Unfollow") 
           .map((options,index) => {
            const annualROI = options?.annualROI || 0; 
            return {
              name: options.stratagyName || "Annual ROI",
              data: [0, annualROI], 
               buttonLabel: options.buttonLabel || "Follow",
            };
           })
       
           console.log("Upted series:", unFollowseries); 
       
           const maxYValue = Math.max(
             100, 
             ...response.data.map((options) => options?.annualROI || 0)
           );
       
           const maxUnfollowYValue = Math.max(
            100,
            ...response.data
              .filter((options) => options.buttonLabel === "Unfollow")
              .map((options) => options?.annualROI || 0)
          );
       
         
           setState((prev) => ({
             ...prev,
             series: updatedSeries,
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "End"],
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0, 
                 max: Math.ceil(maxYValue / 10) * 10, 
               },
             },
           }));
           setStateunfollow((prev) => ({
             ...prev,
             series: unFollowseries,
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "End"],
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0, 
                 max: Math.ceil(maxUnfollowYValue / 10) * 10, 
               },
             },
           }));
           
         } else {
           
           console.log("No data found, using defaults.");
           setState((prev) => ({
             ...prev,
             series: [
               {
                 name: "Annual ROI",
                 data: [0, 10],
               },
             ],
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "Target"], 
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0,
                 max: 100,
               },
             },
           }));
           setStateunfollow((prev) => ({
             ...prev,
             series: [
               {
                 name: "Annual ROI",
                 data: [0, 10],
               },
             ],
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "Target"], 
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0,
                 max: 100,
               },
             },
           }));
         }
        setStratagyList(response.data);
        setLoader(false);
      } else {
        setStratagyList(response.data);
        setLoader(false);
      }
      console.log(StratagyListref.current.length, "--==");
    } catch (error) {
      console.log(error, "-----------------");
      setLoader(false);
    }
  };

  const getFollowedStratagy = async (data) => {
    try {
      setLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.topStratagies(token);
      setStratagyLoader(false);
      console.log(response, "response");
      if (response.status == true) {
        if (response.data.length > 0) {
           const updatedSeries = response.data.map((options) => {
             const annualROI = options?.annualROI || 0; 
             console.log(annualROI,"annualROI")
             return {
               name: options.stratagyName || "Annual ROI",
               data: [0, annualROI], 
                buttonLabel: options.buttonLabel || "Follow",
             };
           });

           const unFollowseries =  response.data.filter((option,index) => option.buttonLabel === "Unfollow") 
           .map((options,index) => {
            const annualROI = options?.annualROI || 0; 
            return {
              name: options.stratagyName || "Annual ROI",
              data: [0, annualROI], 
               buttonLabel: options.buttonLabel || "Follow",
            };
           })
       
           console.log("Updated series:", unFollowseries); 
       
           const maxYValue = Math.max(
             100, 
             ...response.data.map((options) => options?.annualROI || 0)
           );
       
           const maxUnfollowYValue = Math.max(
            100,
            ...response.data
              .filter((options) => options.buttonLabel === "Unfollow")
              .map((options) => options?.annualROI || 0)
          );
       
         
           setState((prev) => ({
             ...prev,
             series: updatedSeries,
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "End"],
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0, 
                 max: Math.ceil(maxYValue / 10) * 10, 
               },
             },
           }));
           setStateunfollow((prev) => ({
             ...prev,
             series: unFollowseries,
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "End"],
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0, 
                 max: Math.ceil(maxUnfollowYValue / 10) * 10, 
               },
             },
           }));
         } else {
           
           console.log("No data found, using defaults.");
           setState((prev) => ({
             ...prev,
             series: [
               {
                 name: "Annual ROI",
                 data: [0, 10],
               },
             ],
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "Target"], 
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0,
                 max: 100,
               },
             },
           }));
           setStateunfollow((prev) => ({
             ...prev,
             series: [
               {
                 name: "Annual ROI",
                 data: [0, 10],
               },
             ],
             options: {
               ...prev.options,
               xaxis: {
                 ...prev.options.xaxis,
                 categories: ["Start", "Target"], 
               },
               yaxis: {
                 ...prev.options.yaxis,
                 min: 0,
                 max: 100,
               },
             },
           }));
         }
        setStratagyList(response.data);
        setLoader(false);
      } else {
        setStratagyList(response.data);
        setLoader(false);
      }
      console.log(StratagyListref.current.length, "--==");
    } catch (error) {
      setLoader(false);
      console.log(error, "-----------------");
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const handleFollowStrategy = async (data) => {
    try {
      setLoader(true)
      var obj = {
        action: data.buttonLabel,
        strategyId: data._id,
      };
      const response = await authService.handleFollowStrategy(obj);
      setStratagyLoader(false);
      const message = response.Message?.trim()
      if (message === 'Strategy is Followed') {
        showsuccessToast(response.Message);
        getStratagy();
        setLoader(false)
      } else if(message === 'Strategy is Unfollowed') {
      console.log(message,"response.Message")
        showsuccessToast(response.Message);
        getStratagy();
        setLoader(false)
      }
      else{
        showerrorToast(response.Message);
        setLoader(false)
      }
     setTimeout(() => {
      setLoader(false)
     }, 1000);
    } catch (err) { setTimeout(() => {
      setLoader(false)
     }, 1000);}
  };

  return (
    <div>
      
      <section>
        <Header />
      </section>
      <div className="asset_row_resp">
        <div className="container">
          <div className="home_main">
            <div className="row starteg_main">
              <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
                <span className="startegy_top_center">
                  Master the Market with Proven Strategies for Success
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0 pad-0-560">
                <div className="startegy_first_main">
                  <span className="home_botstatus_span">Top Strategies</span>

                  {loader ? (
         <div className="loader_main loader_mainChange">
                             <ScaleLoader color="#36d7b7" height={10} width={3} />
                             <p>Loading...</p>
                           </div>
      ) : <div className="row">
      {StratagyListref.current.length > 0
        ? StratagyListref.current.map((options,index) => {
          return (
            <div className="col-lg-3 col-md-6 pad-0-560">
              <div className="strategy_topstarty_inmain">
                <div className="startegy_topstary_inntop">
                  <div className="top_startgy_intopintop">
                    <span className="dca_main">
                      {" "}
                      {options.stratagyName}
                    </span>
                    <div className="reg_check_main">
                      <div className="">
                        <img
                          src={require("../assests/icons/topstartegy-users.png")}
                          alt="Users"
                          className="topstartegy-users"
                        />
                        <span className="top-99">
                          {" "}
                          {options.followers}
                        </span>
                      </div>
                      <div className="topstartegy_color_div">
                        <span>
                          ROI: {options.leverage}X
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="startgy_box_intopinbottom">
                    <div className="top_startgy_inbottomleft">
                      <span className="top_startgy_inbottomadvance">
                        Annualized ROI
                      </span>
                      <span className="top_startgy_inbottompercen">
                        {options.annualROI}%
                      </span>
                    </div>
                    <div id="chart" className="chart_main">
                      <ReactApexChart
                        options={state.options}
                        series={
                          state.series[index]
                            ? [state.series[index]]
                            : []
                        }
                        type="area"
                        height={130}
                      />
                    </div>

                  </div>
                </div>
                <div
                  className="home_topstary_follow"
                  onClick={() =>
                    handleFollowStrategy(options)
                  }
                >
                  <span>{options.buttonLabel}</span>
                </div>
              </div>
            </div>
          );
        })
        : ""}
    </div>}
                  {/* {StratagyLoader == true ? (
                    <span className="home_loading">Loading....</span>
                  ) : (
                    
                  )} */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="startegy_first_main">
                  <span className="home_botstatus_span">
                    Followed strategies
                  </span>

                  <div className="row">
                    {StratagyListref.current.length > 0 ? (
                      StratagyListref.current.filter(
                        (option) => option.buttonLabel === "Unfollow"
                      ).length > 0 ? (
                        StratagyListref.current
                          .filter((option) => option.buttonLabel === "Unfollow")
                          .map((options, index) => {
                            return (
                              <div className="col-lg-3 col-md-6 pad-0-560">
                                <div className="strategy_topstarty_inmain">
                                  <div className="startegy_topstary_inntop">
                                    <div className="top_startgy_intopintop">
                                      <span className="dca_main">
                                        {" "}
                                        {options.stratagyName}
                                      </span>
                                      <div className="reg_check_main">
                                        <div className="">
                                          <img
                                            src={require("../assests/icons/topstartegy-users.png")}
                                            alt="Users"
                                            className="topstartegy-users"
                                          />
                                          <span className="top-99">
                                            {" "}
                                            {options.followers}
                                          </span>
                                        </div>
                                        <div className="topstartegy_color_div">
                                          <span>
                                            ROI: {options.leverage}X
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="startgy_box_intopinbottom">
                                      <div className="top_startgy_inbottomleft">
                                        <span className="top_startgy_inbottomadvance">
                                          Annualized ROI
                                        </span>
                                        <span className="top_startgy_inbottompercen">
                                          {options.annualROI}%
                                        </span>
                                      </div>
                                     

                                      <div id="chart" className="chart_main" style={{ position: 'relative', top: "21px", right: "0px" }}>
                                        <ReactApexChart
                                          options={stateunfollow.options}
                                          series={
                                            stateunfollow.series[index]
                                              ? [stateunfollow.series[index]]
                                              : []
                                          }
                                          type="area"
                                          height={130}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <div
                          className="no-strategies"
                        >
                          <h3>No followed strategies</h3>
                        </div>
                      )
                    ) : (
                      <div
                        className="no-strategies"
                      >
                        <h3>No followed strategies</h3>
                      </div>
                    )}
                  </div>

                    {/* <div className="col-lg-3 col-md-6 m-top-20-770 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Intra Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Profit: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 m-top-20 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Triangle Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Profit: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 m-top-20 pad-0-560">
                    <div className="strategy_topstarty_inmain">
                        <div className="startegy_topstary_inntop">
                            <div className="top_startgy_intopintop">
                                <span className="dca_main"> Arbitrage Strategy</span>
                                <div className="reg_check_main">
                                    <div className="">
                                    <img
                                    src={require("../assests/icons/topstartegy-users.png")}
                                    alt="Users"
                                    className="topstartegy-users"
                                  />
                                  <span className="top-99"> 99</span>
                                    </div>
                                    <div className="topstartegy_color_div">
                                  <span>Profit: 3X</span>
                                </div>
                                </div>
                                </div>
                                <div className="startgy_box_intopinbottom">
                                    <div className="top_startgy_inbottomleft">
                                    <span className="top_startgy_inbottomadvance">
                                  Annualized ROI
                                </span>
                                <span className="top_startgy_inbottompercen">
                                  536.49%
                                </span>
                                    </div>
                                    <img
                                src={require("../assests/topstrategy-chart.png")}
                                alt="Startegy chart"
                                className="startegy_chart_strategybox"
                              />
                                
                            </div>
                        </div>
                        <div className="home_topstary_follow">
                          <span>Un Follow</span>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Strategies;
