import React, { useEffect,useContext} from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { UserContext } from "../contexts/UserContext";
import { getUserDetails } from "../services/authservices";
import authService from "../services/authservices";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyTFA } from "../services/authservices";
import dayjs from "dayjs";
import { ScaleLoader } from 'react-spinners'

function Withdraw() {
  const navigate = useNavigate();
  const [amount, setAmount,amountref] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(300);
  const [emailOtp, setEmailOtp,emailOtpref] = useState("");
  const [tfaCode, setTfaCode,tfaCoderef] = useState("");
  const [coinType, setCoinType, coinTyperef] = useState("USDC");
  const [addressOptions, setAddressOptions] = useState([]);
  const [selectedAddress, setSelectedAddress,selectedAddressref] = useState(null);
  const [label, setLabel] = useState("");
  const [network, setNetwork,networkref] = useState('');
  const [addressData, setAddressData] = useState(null);
  const [resendLoader, setResendLoader] = useState(false);
  const [buttonLoader,setbuttonLoader] = useState(false);
  const [input, setInput] = useState("");
  const [faqs, setFaqs] = useState({});
  const [errors, setErrors] = useState({});
  const { theme } = useContext(UserContext);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await authService.getFaq();
        if (response.status) {
          // Filter FAQs with type 'Withdraw' only
          const withdrawFaqs = response.data.filter((faq) => faq.type === "Withdraw");
          setFaqs(withdrawFaqs); // Store filtered FAQs directly
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);


  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const [toastVisible , setToastVisible] = useState(false);

  const showErrorToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true); // Set toast as visible
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset toastVisible on close
                  autoClose: 3000, // Optional: auto-close after 3 seconds
                }
              );
            }
          };
        
          const showSuccessToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true);
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset state on close
                  autoClose: 3000, // Auto-close after 3 seconds
                }
              );
            }
          };
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoading(false);
  };

  const setcoin = (value) =>{
    setCoinType(value);
    setNetwork("");
    setSelectedAddress(null);
    setAmount("");
    setEmailOtp("");
    setTfaCode("");
    setErrors({});
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);
  const handleCopy = (txID) => {
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);
  
    handleCopy.timeout = setTimeout(() => {
      if (handleCopy.toastVisible) return;
  
      handleCopy.toastVisible = true;
  
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          showsuccessToast(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">Transaction ID copied to clipboard!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          showerrorToast(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        })
        .finally(() => {
          setTimeout(() => {
            handleCopy.toastVisible = false;
          }, 2000); 
        });
    }, 300); 
  };
  
  const [withdrawDetails, setWithdrawDetails] = useState({
    minWithdraw: 0,
    maxWithdraw: 0,
    availableBalance: 0,
    fees: 0,
  });
  useEffect(() => {
    const fetchWithdrawDetails = async () => {
      const result = await authService.getWithdrawDetails(coinType);
      if (result) {
        setWithdrawDetails({
          minWithdraw: result.data.minWithdrawLimit,
          maxWithdraw: result.data.maxWithdrawLimit,
          availableBalance: result.data.balance,
          fees: result.data.withdrawFee,
        });
        // setNetwork(null);
      } else {
        console.error("Failed to fetch withdrawal details:", result.message);
      }
    };

    fetchWithdrawDetails();
  }, [coinType]);

  const truncateText = (text, maxLength) => {
    if (!text || typeof text !== "string") {
      return "--";
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " . . .";
    }
    return text;
  };
  const customStyles = {
    control: (styles) => ({
      ...styles,
      // backgroundColor: "#f9f9f9",
      // border: "1px solid #ccc",
      // padding: "4px",
      backgroundColor: theme === "dark" ? "transparent" : "#f9f9f9",
      //   border: `1px solid ${theme === "dark" ? "#2b3139" : "#ccc"}`,
      padding: "4px",
      color: theme === "dark" ? "#fff" : "#000",
      //   border: "none",
      boxShadow: "none", // Removes the box-shadow
      outline: "none", // Removes any outline
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
      backgroundColor: isFocused
        ? theme === "dark"
          ? "#444"
          : "#e0e0e0"
        : theme === "dark"
          ? "#181a20"
          : "#fff",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
    }),
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      const result = await authService.getWithdrawAddresses(coinType);
      const filteredAddresses = result.data.filter(
        (address) => address.token === coinType && address.network === network
      );

      const formattedAddresses = filteredAddresses.map((address) => ({
        value: address.address,
        label: address.label || address.address,
        token: address.token,
        network: address.network,
      }));

      setAddressOptions(formattedAddresses);
    };

    fetchAddresses();
  }, [coinType, network]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);

  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      const response = await authService.getWithdrawalHistory();
      if (response.status) {
        setWithdrawalHistory(response.data);
      } else {
        console.error("Failed to fetch withdrawal history:", response.Message);
      }
    };

    fetchWithdrawalHistory();
  }, []);

  const handlechange = () => {
    navigate("/security-tfa");
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedAddress(selectedOption);
    validate();
  };

  const customFilter = (option, inputValue) => {
    const sanitizedInput = inputValue.trim().toLowerCase();
    const label = option.label.toLowerCase();
    const address = option.value.toLowerCase();

    console.log("Searching for:", sanitizedInput);
    console.log("Checking label:", label, "Address:", address);
    const labelMatch = label.includes(sanitizedInput);
    const addressMatch = address.includes(sanitizedInput);

    console.log("Label match:", labelMatch, "Address match:", addressMatch);
    return labelMatch || addressMatch;
  };
  const handleKeyDown = (e) => {
    if (e.key === " " && !e.ctrlKey && !e.metaKey) {
      e.preventDefault();
    }
  };

  const handleKeyDownDropdown = (e, type) => {
    if (type === "withdraw") {
      
      if (
        e.key === "Backspace" ||
        e.key === "Delete" ||
        e.key === "Tab" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        return; 
      }
  
      if (e.target.value.length >= 70) {
        e.preventDefault();
      }
  
      if (!/^[a-zA-Z0-9]$/.test(e.key)) {
        e.preventDefault(); 
      }
    }
  };
  const handleKeyDown2 = (e) => {
    const allowedKeys = [
      "Backspace", // Allow deleting with Backspace
      "Delete", // Allow deleting with Delete
      "ArrowLeft", // Allow moving left
      "ArrowRight", // Allow moving right
      "Tab", // Allow tabbing
    ];

    // Allow numbers, one decimal point, and the allowed keys
    if (
      (!/[0-9.]/.test(e.key) && !allowedKeys.includes(e.key)) ||
      (e.key === "." && e.target.value.includes(".")) ||
      e.key === " " // Disallow spaces
    ) {
      e.preventDefault();
    }
  };

  const handleWithdrawSubmit = async () => {
    let errros = validate();
    if (Object.keys(errros).length > 0){
      return setErrors(errros);
    }
    // if (!amount || !selectedAddress || !emailOtp || !tfaCode) {
    //   toast.error("Please fill all required fields.");
    //   return;
    // }
    setbuttonLoader(true);
    const otpResponse = await authService.verifywithdrawmainOtp({
      withdrawOtp: Number(emailOtpref.current),
    });
    console.log(emailOtp, "????????????????????????",otpResponse.Message);
    if (!otpResponse.status) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        {otpResponse.Message}
        </p>
      </div>
      );
      return;
    }

    // toast.success(otpResponse.Message);
    const token = localStorage.getItem("token");
    const tfaResponse = await verifyTFA(token, Number(tfaCoderef.current));
    console.log(tfaResponse.Message);
    if (tfaResponse.status == 200) {
      setbuttonLoader(false);
      toast.success(
        <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{tfaResponse.Message}</p>
      </div>
      );
      console.log(tfaCode, "????????????????????????");
    }
    if (!tfaResponse.status) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        {tfaResponse.Message}
        </p>
      </div>
      );
      return;
    }

    const numericAmount = parseFloat(amountref.current);
    if (numericAmount < withdrawDetails.minWithdraw) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        Amount must be at least ${withdrawDetails.minWithdraw.toFixed(6)}.
        </p>
      </div>
      );
      return;
    }
    if (numericAmount > withdrawDetails.maxWithdraw) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        Amount must not exceed ${withdrawDetails.maxWithdraw.toFixed(6)}.
        </p>
      </div>
      );
      return;
    }
    if (numericAmount > withdrawDetails.availableBalance) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        Insufficient balance.
        </p>
      </div>
      );
      return;
    }
    // const amountsubmit = amountref.current;
    // const otpsubmit = emailOtpref.current;
    // const tfasubmit = tfaCoderef.current;
    // const networksubmit = networkref.current;

    const requestData = {
      amount: amountref.current,
      address: selectedAddressref.current.value,
      currency_symbol: coinType,
      emailOtp: emailOtpref.current,
      tfaCode:tfaCoderef.current,
      network: networkref.current,
    };
    console.log("requestData-->>",requestData);
    try {
      const response = await authService.withdrawSubmit(requestData);
      if (response.status) {
        setbuttonLoader(false);
        showsuccessToast(
          <div className="toast-ml">
          <h4 className="toast-title">Success!</h4>
          <p className="toast-message">{response.Message}</p>
        </div>
        );
        setAmount("");
        setTfaCode("");
        setAddressData("");
        setNetwork("BEP20");
        setEmailOtp("");
      } else {
        setbuttonLoader(false);
        showerrorToast(
          <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">{response.Message}</p>
        </div>
        );
      }
    } catch (error) {
      setbuttonLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">An error occurred while processing the request.</p>
      </div>
      );
    }
  };

  const sendOtp = async () => {
    setResendLoader(true);
    if (!user.email) {
      setResendLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">User email is not available. Please try again.</p>
      </div>
      );
      return;
    }

    console.log("errors-->>>>>>>",errors);
    if (errors.network || errors.address || errors.amount ) {
      setResendLoader(false);
      showerrorToast(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
            Please fill all fields before sending
            OTP.
          </p>
        </div>
      );
      return;
    }

    try {
      const response = await authService.withdrawOtp({ email: user.email });
      if (response.data.status) {
        setResendLoader(false);
        showsuccessToast(
          <div className="toast-ml">
          <h4 className="toast-title">Success!</h4>
          <p className="toast-message">OTP sent successfully!</p>
        </div>
        );
        setOtpSent(true);
        setTimer(300);
      } else {
        setResendLoader(false);
        showerrorToast(
          <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">
          { response.data.Message ?  response.data.Message : "Failed to send OTP. Please try again." }
          </p>
        </div>
        );
      }
    } catch (error) {
      setResendLoader(false);
      showerrorToast(
        <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">
        An error occurred. Please try again.
        </p>
      </div>
      );
    }
  };

  const handleNetworkChange = (selectedOption) => {
    setNetwork(selectedOption ? selectedOption.value : null);
    setSelectedAddress(null);
    setAmount("");
    setEmailOtp("");
    setTfaCode("");
    validate();
  };

  const validate = (values) => {
    console.log("emailOtpref-->",emailOtpref.current);
    let newerrors = {};
    if(!networkref.current){
      newerrors.network = "Network is a required field";
    }
    if(!selectedAddressref.current){
      newerrors.address = "Address is a required field";
    }
    if(!amountref.current){
      newerrors.amount = "Amount is a required field";
    }
    if(!emailOtpref.current){
      newerrors.otp = "OTP is a required field";
    }
    if(!tfaCoderef.current){
      newerrors.tfa = "TFA is a required field";
    }

    setErrors(newerrors);
    return newerrors;
  }

  const NetworkOptions = [
    { value: "TRC20", label: "TRC20" },
    { value: "BEP20", label: "BEP20" },
  ];
  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);


  useEffect(() => {
    setSelectedAddress(null);
  }, [coinType]);

        const showsuccessToast = (message) => {
          toast.dismiss();
          toast.success(message);
        };
      
        const showerrorToast = (message) => {
          toast.dismiss();
          toast.error(message);
        };

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="withdraw-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">Withdraw</span>
              <div className="row mt-4">
                <div className="col-lg-7">
                  <div className="deposit_left_main">
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">Select crypto</span>
                      <div className="depo_nav_design">
                        <div
                          className={
                            coinType === "USDC"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => setcoin("USDC")}
                        >
                          <img
                            src={require("../assests/usdc-logo.png")}
                            alt="USDC"
                            className="logout-img"
                          />
                          <span>USDC</span>
                        </div>
                        <div
                          className={
                            coinType === "USDT"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => setcoin("USDT")}
                        >
                          <img
                            src={require("../assests/usdt-logo.png")}
                            alt="USDT"
                            className="logout-img"
                          />
                          <span>USDT</span>
                        </div>
                      </div>
                    </div>
                    <div className="depo_inner_divmain">
                      <span className="register_spans">Network</span>
                      <Select
                        options={NetworkOptions}
                        styles={customStyles}
                        placeholder="Select Network"
                        onChange={handleNetworkChange}
                        className="withdraw-dropdown-menu"
                        value={network ? NetworkOptions.find((option) => option.value === network) : null}
                      />
                      {errors &&
                          errors.network && (
                            <p className="error">
                              {errors.network}
                            </p>
                          )}
                    </div>
                    <div className="depo_inner_divmain">
                      <div className="manage_btn_row">
                        <span className="home-viewasset-span">
                          Wallet Address
                        </span>
                        <Link to="/security-withdraw">
                          <div className="d-flex align-items-center gap-2 cursor-pointer">
                            <span className="home_starty_view">
                              {" "}
                              Add Address
                            </span>
                            <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                          </div>
                        </Link>
                      </div>
                      <Select
                        options={addressOptions}
                        styles={customStyles}
                        placeholder="Select or type the address"
                        className="withdraw-dropdown-menu"
                        onChange={handleSelectChange}
                        value={selectedAddress}
                        isSearchable={true}
                        inputValue={label}
                        defaultInputValue={selectedAddress}
                        getOptionLabel={(e) => `${e.value}`}
                        filterOption={customFilter}
                        onInputChange={(value, action) => {
                          if (action.action === "input-change") setLabel(value); 
                        }}
                        onKeyDown={(e) => handleKeyDownDropdown(e, "withdraw")}
                      />
                      {errors &&
                          errors.address && (
                            <p className="error">
                              {errors.address}
                            </p>
                          )}
                    </div>
                    <div className="depo_in_notesdiv">
                      <div className="depoin_notes_icon">
                        <i class="bi bi-exclamation-triangle depo_note_ico"></i>
                      </div>
                      <div className="depo_notes_defspan">
                        Make sure the address you provided is correct.
                        Transactions cannot be reversed once completed.
                      </div>
                    </div>
                    <div className="withdraw_fes_main">
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">
                          Withdraw Amount
                        </span>
                        <div className="pos_rel">
                          <input
                            className="withdraw_amnt"
                            placeholder="Enter the amount"
                            type="text"
                            value={amount}
                            pattern="[0-9]*"
                            maxLength={8}
                            onChange={(e) => {
                              const value = e.target.value;
                              setAmount(value); // Update the state
                              validate(value); 
                            }} // Bind amount input
                            onKeyDown={handleKeyDown2}
                          />
                          <span className="withdraw_amnt_max">Max</span>
                        </div>
                        {errors &&
                          errors.amount && (
                            <p className="error">
                              {errors.amount}
                            </p>
                          )}
                      </div>
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">Enter OTP</span>
                        <div className="pos_rel w-100">
                          <input
                            type="number"
                            maxLength="6"
                            min={0}
                            name="emailOtp"
                            value={emailOtpref.current}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 6) {
                                setEmailOtp(value);
                                validate(value);
                              }
                              
                            }}
                            placeholder="Enter the verification code"
                            className="bind_bin_next_inp"
                            // onKeyDown={handleKeyDown}
                          />
                          {otpSent ? (
                            <span className="bind_bin_next_inp_Copy">
                              Resend ({timer}s)
                            </span>
                          ) : (
                            <span
                              className="bind_bin_next_inp_Copy"
                              onClick={() => sendOtp()}
                              style={{ cursor: "pointer" }}
                            >
                              {resendLoader == false ? (
                                <>{timer === 300 ? "Send OTP" : "Resend"}</>
                              ) : (
                                "Loading..."
                              )}
                            </span>
                          )}
                        </div>
                        {errors &&
                          errors.otp && (
                            <p className="error">
                              {errors.otp}
                            </p>
                          )}
                      </div>
                      <div className="depo_inner_divmain">
                        <span className="home-viewasset-span">Enter 2FA</span>
                        <div className="pos_rel w-100">
                          <input
                            type="number"
                            maxLength="6"
                            min={0}
                            name="tfaCode"
                            value={tfaCoderef.current}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 6) {
                              setTfaCode(value); // Update the state
                              validate(value); 
                              }
                            }}
                            placeholder="Enter the 2FA code"
                            className="bind_bin_next_inp"
                            // onKeyDown={handleKeyDown}
                          />
                          {errors &&
                          errors.tfa && (
                            <p className="error">
                              {errors.tfa}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="with_draw_feeses">
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">
                            Fees
                          </span>
                          <span className="home-viewasset-span">
                            {withdrawDetails.fees}%
                          </span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">
                            Available balance
                          </span>
                          <span className="home-viewasset-span">
                            {withdrawDetails.availableBalance}
                          </span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">
                            Minimum withdraw
                          </span>
                          <span className="home-viewasset-span">
                            {withdrawDetails.minWithdraw}
                          </span>
                        </div>
                        <div className="with_fees_spacebet">
                          <span className="top_startgy_inbottomadvance">
                            Maximum withdraw
                          </span>
                          <span className="home-viewasset-span">
                            {withdrawDetails.maxWithdraw}
                          </span>
                        </div>
                      </div>
                    </div>
                    {user.tfaStatus == 0 ? (
                      <div
                        className="withdraw_submit_div"
                        onClick={handlechange}
                      >
                        <span>Enable 2FA</span>
                      </div>
                    ) : (
                      <>
                      {buttonLoader == false ? (
                        <div
                        className="withdraw_submit_div"
                        onClick={handleWithdrawSubmit}
                      >
                        <span>Submit</span>
                      </div>
                      ) : (
                        <div
                        className="withdraw_submit_div"
                      >
                        <span>Loading...</span>
                      </div>
                      )}
</>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="container m-top-20 p-0">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="faq">
                        <h5 className="faq-title">FAQs</h5>
                        {faqs.length > 0 ? (
                          faqs.map((faq, index) => (
                            <div className="accordion-item font-satoshi" key={faq._id}>
                              <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-collapse-${index}`}
                                >
                                  {index + 1}. {faq.question}
                                  <span className="icon">
                                    <i className="fas fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`flush-heading-${index}`}
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {faq.status === "Active" ? faq.answer : "Content not available."}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className="faq_cate_span">No FAQs available for Withdraw.</span>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="recent_depo_main">
                <div className="manage_btn_row">
                  <span className="home_right_marketspan">Recent Withdraw</span>
                  <Link to="/withdrawhistory">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <span className="home_starty_view">View All</span>
                      <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                  </Link>
                </div>
                <div className="recent_depo_tablemain pb-0">
                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                       <div className="loader_main loader_mainChange">
                                           <ScaleLoader color="#36d7b7" height={10} width={3} />
                                           <p>Loading...</p>
                                         </div>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text_left">Currency</th>
                            <th>Date & Time</th>
                            <th>Network</th>
                            <th>Amount</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {withdrawalHistory.length > 0 ? (
                            withdrawalHistory
                              .slice(0, 5)
                              .map((withdrawal, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="home_recent_firstdata">
                                      <img
                                        src={require("../assests/usdt-logo.png")}
                                        alt="USDT"
                                        className="usdt_home_recent"
                                      />
                                      <span>{withdrawal.currency}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <span>
                                      {isNaN(new Date(withdrawal.date))
                                        ? "null"
                                        : dayjs(withdrawal.date).format(
                                          "DD MMMM YYYY, hh:mm A"
                                        )}
                                    </span>
                                  </td>
                                  <td>
                                    <span>{withdrawal.network}</span>
                                  </td>
                                  <td>
                                    <span>{withdrawal.amount}</span>
                                  </td>
                                  <td>
                                    <div className="home_uid_main">
                                      <span>
                                        {withdrawal.txID && withdrawal.txID !== "Pending"
                                          ? truncateText(withdrawal?.txID, 10)
                                          : withdrawal.transactionId && withdrawal.transactionId !== "Pending"
                                            ? truncateText(withdrawal?.transactionId, 10)
                                            : "-"}
                                      </span>
                                      {(withdrawal.txID && withdrawal.txID !== "Pending") ||
                                        (withdrawal.transactionId && withdrawal.transactionId !== "Pending") ? (
                                        <i
                                          className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                          onClick={() => handleCopy(withdrawal.txID || withdrawal.transactionId)}
                                        ></i>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="5">
                                <span className="depo_no_record">
                                  No transactions found
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
