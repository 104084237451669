import React, { useState, useContext,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../contexts/UserContext";
import { Box, Modal } from "@material-ui/core";
function Login() {
  const { login } = useContext(UserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [signloader, setSignloader] = useState(false);
  const [toastVisible , setToastVisible] = useState(false);
  const navigate = useNavigate();
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  useEffect(() => {
    var token = localStorage.getItem('token');
    if (token) {
      navigate("/home");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) newErrors.email = "Email is required";
      else if (!emailRegex.test(value))
        newErrors.email = "Invalid email format";
      else delete newErrors.email;
    } else if (name === "password") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    
      if (!value) {
        newErrors.password = "Password is required"; 
      } else if (!passwordRegex.test(value)) {
        newErrors.password = "Invalid password format"; 
      } else {
        delete newErrors.password; 
      }
    }

    setErrors(newErrors);
  };

  const handleKeyDown = (e, type) => {
    if (type === "email") {
      const regex = /^[a-zA-Z0-9@._-]+$/;
      if (
        !regex.test(e.key) ||
        (e.target.value.length >= 250 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    } else if (type === "password") {
      if (
        e.key === " " ||
        (e.target.value.length >= 15 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    }
  };

   useEffect(() => {
      if (theme === 'dark') {
        document.body.classList.add('dark');
        document.body.classList.remove('light');
      } else {
        document.body.classList.add('light');
        document.body.classList.remove('dark');
      }
  
      localStorage.setItem('theme', theme);
    }, [theme])

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
      return newErrors;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      return newErrors;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;

    if (!formData.password) {
      newErrors.password = "Password is required";
      return newErrors;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must be 8-15 characters long, include uppercase, lowercase, number, and special character";
      return newErrors;
    }

    return newErrors;
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const showErrorToast = (message) => {
    if (!toastVisible) {
      setToastVisible(true); // Set toast as visible
      toast.error(
        <div className="toast-ml">
          <h4 className="toast-title">Error!</h4>
          <p className="toast-message">{message}</p>
        </div>,
        {
          onClose: () => setToastVisible(false), // Reset toastVisible on close
          autoClose: 3000, // Optional: auto-close after 3 seconds
        }
      );
    }
  };

  const showSuccessToast = (message) => {
    if (!toastVisible) {
      setToastVisible(true);
      toast.success(
        <div className="toast-ml">
          <h4 className="toast-title">Success!</h4>
          <p className="toast-message">{message}</p>
        </div>,
        {
          onClose: () => setToastVisible(false), // Reset state on close
          autoClose: 3000, // Auto-close after 3 seconds
        }
      );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const firstErrorField = Object.keys(validationErrors)[0];
      const firstErrorElement = document.getElementsByName(firstErrorField)[0];
      if (firstErrorElement) {
        firstErrorElement.focus();
      }
      return;
    }

    try {
      setSignloader(true);
      localStorage.setItem("useremail", formData.email);
      const loginResponse = await authService.login(formData);
      console.log("Login response:", loginResponse);

      if (loginResponse.status) {
        setSignloader(false);
        const token = loginResponse.token;
        await login(token);
        const userDetails = await authService.getUserDetails();
        console.log("Fetched user details:", userDetails);

        if (userDetails.status) {
          const maskedPassword =
          formData.password.charAt(0) +
          "*".repeat(formData.password.length - 1);
          localStorage.setItem("maskedPassword", maskedPassword);
          if (userDetails.tfastatus === 1) {
            // toast.info("2FA required. Redirecting to 2FA login page.");
            navigate("/tfa");
          } else {
            showSuccessToast('Login successful!')
            navigate("/home");
          }
        } else {
          showErrorToast('Failed to fetch user details after login.')
        }
      } else {
        setSignloader(false);
        showErrorToast(loginResponse.Message)
      }
    } catch (error) {
      setSignloader(false);
      console.error("Error during login:", error);
      showErrorToast('An error occurred during login. Please try again.')
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // const handleToast = () => {
  //   toast.info(
  //     <div className="toast-ml">
  //       <h4 className="toast-title">Success!</h4>
  //       <p className="toast-message">Toast message</p>
  //     </div>
  //   );
  // };

  const [verifyLoader,setverifyLoader]= useState(false);

  const verify_email = async () => {
    try {
      // var obj = {
      //   email: localStorage.getItem("useremail"),
      // };
      setverifyLoader(true);
      const data = await authService.resendOtp({email: localStorage.getItem("useremail")});
      setverifyLoader(false);
      if (data.status) {
        // (data.Message);
        showSuccessToast('OTP sent to your email!')
        navigate("/verification");
      } else {
        // (data.Message);
        showErrorToast('Please try again later')
      }
    } catch (error) {
      
    }
  }

  return (
    <div>
      <div className="row register_main container-fluid">
        <div className="col-lg-6">
          <div className="d-flex flex-column mb-5">
            <div className="register_head_logo">
              <Link to="/">
                <img
                  src={require("../assests/Logo.webp")}
                  alt="Logo"
                  className="register_logo cursor-pointer"
                />
              </Link>
            </div>
            <div className="register_left_main mt-3">
              <div className="hero_center">
                <div className="register_form">
                  <span className="register_head_sign">Login</span>
                  <div className="register_inner_main">
                    <div className="getting_card_content w-100">
                      <div className="register_inner_submain w-100">
                        <div className="reg_inner_small">
                          <span className="register_spans">Email Address</span>
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter the email address"
                            className="reg_input"
                            value={formData.email}
                            onChange={handleInputChange}
                            onKeyDown={(e) => handleKeyDown(e, "email")}
                          />
                          {errors.email && (
                            <span className="error">{errors.email}</span>
                          )}
                          {/* <p className="errorcss mb-0">Invalid Email</p> */}
                        </div>
                        <div className="reg_inner_small">
                          <span className="register_spans">Password</span>
                          <div className="reg_pos_rel">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              name="password"
                              placeholder="Enter the password"
                              className="reg_input"
                              value={formData.password}
                              onChange={handleInputChange}
                              onKeyDown={(e) => handleKeyDown(e, "password")}
                            />
                            <i
                              className={`fa-regular ${passwordVisible ? "fa-eye" : "fa-eye-slash"
                                } reg_eye`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                          {errors.password && (
                            <span className="error">{errors.password}</span>
                          )}
                        </div>
                      </div>

                      <span className="reg_check_span fnt-13">
                        <Link to="/forgot"> Forget Password? </Link>
                      </span>
                    </div>
                    <div className="getting_card_content">
                      {signloader === true ? (
                        <div className="reg_btn">
                          <span className="hero_invest"> Loading... </span>
                        </div>
                      ) : (
                        <div className="reg_btn" onClick={handleSubmit}>
                          <span className="hero_invest"> Log In </span>
                        </div>
                        // <div className="reg_btn" onClick={handleToast}>
                        //   <span className="hero_invest"> Log In </span>
                        // </div>
                      )}
                    </div>
                  </div>
                </div>
                <span className="reg_bottom_span">
                  Don't have an account?
                  <Link to="/register">
                    {" "}
                    <span className="reg_check_span">Sign Up.</span>{" "}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 register_right_main">
          <div className="login_right">
            <div className="footer_links_div">
              <span className="register_head">Log In to Trade Smarter!</span>
              <span className="register_subhead">
                {" "}
                Welcome back! Log in to access your trading tools and <br />{" "}
                unlock smarter trading today!{" "}
              </span>
            </div>
          </div>
        </div>

           <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="email-popup-modal-title"
                aria-describedby="email-popup-modal-description"
              >
                <Box>
                  <div className="email-popup-card">
                    <div className="email-pop-icon">
                      <i class="fa-regular fa-circle-xmark" onClick={handleClose}></i>
                    </div>
                    <div className="email-pop-img mb-3">
                      <img
                        src={require("../assests/icons/email-pop.webp")}
                        alt="email-icon"
                      />
                    </div>
                    <h3>E-mail Verification Require!</h3>
                    <p>
                      Please verify your email address to log in. Click the link
                      to verify now
                    </p>
                    <div className="Submit">
                    {verifyLoader ==false ?
                      <button onClick={verify_email}>Verify Now</button>
                      :
                  <button>Loading ...</button>
                    }
                    </div>
                  </div>
                </Box>
              </Modal>
      </div>
    </div>
  );
}

export default Login;
