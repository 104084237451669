import { Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { useEffect, useState,useContext} from "react";
import service from "../services/authservices"
import { toast } from "react-toastify";
import { UserContext } from "../contexts/UserContext";
import { ScaleLoader } from 'react-spinners'
import "react-toastify/dist/ReactToastify.css";
const Support = () => {
  const [faqs, setFaqs] = useState({});
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [toastVisible , setToastVisible] = useState(false);
  const [loading, setLoading] = useState(false);
    const { theme } = useContext(UserContext);
  const characterLimit = 100; // Set your character limit
  const [errors, setErrors] = useState({
    subject: '',
    selectedCategory: '',
    message: ''
  });
  const [supportHistory, setSupportHistory] = useState([]);
  useEffect(() => {
    fetchSupportHistory();
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await service.getFaq();
      if (response.status) {
        const withdrawFaqs = response.data.filter((faq) => faq.type === "Support");
        setFaqs(withdrawFaqs);
      } else {
        console.error("Failed to fetch FAQs");
      }
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  const fetchSupportHistory = async () => {
    try {
      setLoading(true)
      const response = await service.getsupport();
      if (response.status) {
        setSupportHistory(response.data);
        setLoading(false)
      } else {
        setLoading(false)
        console.error("Failed to fetch support history");
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching support history:", error);
    }
  };
  const categoryOptions = [
    { value: "Deposit", label: "Deposit" },
    { value: "Withdraw", label: "Withdraw" },
    { value: "Upgrade", label: "Upgrade" },
  ];

 

  const truncateText = (text, maxLength) => {
    if (!text || typeof text !== "string") {
      return "--";
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " . . .";
    }
    return text;
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: theme === "dark" ? "transparent" : "#f9f9f9",
      padding: "4px",
      color: theme === "dark" ? "#fff" : "#000",
      boxShadow: "none",
      outline: "none",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
      backgroundColor: isFocused
        ? theme === "dark"
          ? "#444"
          : "#e0e0e0"
        : theme === "dark"
          ? "#181a20"
          : "#fff",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
    }),
  };


const showErrorToast = (message) => {
          if (!toastVisible) {
            setToastVisible(true); // Set toast as visible
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">{message}</p>
              </div>,
              {
                onClose: () => setToastVisible(false), // Reset toastVisible on close
                autoClose: 3000, // Optional: auto-close after 3 seconds
              }
            );
          }
        };
      
        const showSuccessToast = (message) => {
          if (!toastVisible) {
            setToastVisible(true);
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">{message}</p>
              </div>,
              {
                onClose: () => setToastVisible(false), // Reset state on close
                autoClose: 3000, // Auto-close after 3 seconds
              }
            );
          }
        };

  const handleSubmit = async () => {
    // Validate form inputs
    let validationPassed = true;
    let errorObj = {
      subject: '',
      selectedCategory: '',
      message: ''
    };
  
    if (!subject) {
      errorObj.subject = 'Subject is required.';
      validationPassed = false;
    }
    
    if (!selectedCategory) {
      errorObj.selectedCategory = 'Please select a category.';
      validationPassed = false;
    }
  
    if (!message) {
      errorObj.message = 'Message is required.';
      validationPassed = false;
    }
  
    // If validation failed, show the error messages and stop the function
    if (!validationPassed) {
      setErrors(errorObj);
      return;
    }
  
    // Clear previous errors
    setErrors({
      subject: '',
      selectedCategory: '',
      message: ''
    });
  
    setLoading(true);
  
    const data = {
      Subject: subject,
      text: message,
      Category: selectedCategory.value,
      image: "", // Handle file uploads if necessary
      video: "", // Handle file uploads if necessary
    };
  
    try {
      const response = await service.support(data);
      if (response.status) {
        showSuccessToast("Ticket raised successfully! Our team will get back to you soon.");
        // Reset form
        setSubject("");
        setMessage("");
        setSelectedCategory(null);
        fetchSupportHistory();
      } else {
        showErrorToast("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting support ticket:", error);
      showErrorToast("Failed to submit the ticket. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = (e) => {
    let value = e.target.value;
    // Trim leading spaces and enforce the character limit
    if (value.length <= characterLimit) {
      setSubject(value.trimStart());
      setErrors((prevErrors) => ({ ...prevErrors, subject: "" }));
    } else {
      // Optional: Handle limit breach error
      setErrors((prevErrors) => ({
        ...prevErrors,
        subject: `Subject cannot exceed ${characterLimit} characters.`,
      }));
    }
  };


  return (
    <>
      <section>
        <Header />
      </section>

      <div className="withdraw-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>

            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">Support</span>
              <div className="row mt-4">
                <div className="col-lg-7">
                  <div className="deposit_left_main">
                    <div className="depo_inner_divmain">
                      <label htmlFor="subject" className="support-label">
                        Subject
                      </label>
                      <input
                        type="text"
                        placeholder="Enter the subject"
                        className="bind_bin_next_inp"
                        id="subject"
                        value={subject}
                        onChange={handleSubjectChange}
                      />

                      {/* Error message for subject */}
                      {errors.subject && <span className="error">{errors.subject}</span>}
                    </div>

                    <div className="depo_inner_divmain">
                      <label htmlFor="select-category" className="support-label">
                        Select Category
                      </label>
                      <Select
                        styles={customStyles}
                        placeholder="Enter the Category"
                        className="withdraw-dropdown-menu w-100"
                        options={categoryOptions}
                        value={selectedCategory}
                        onChange={(selectedOption) => {
                          setSelectedCategory(selectedOption);
                          // Clear the error message once the user selects a category
                          setErrors((prevErrors) => ({ ...prevErrors, selectedCategory: "" }));
                        }}
                      />
                      {/* Error message for category */}
                      {errors.selectedCategory && (
                        <span className="error">{errors.selectedCategory}</span>
                      )}
                    </div>

                    <div className="depo_inner_divmain">
                      <label htmlFor="message" className="support-label">
                        Message
                      </label>
                      <textarea
                        placeholder="Enter the message"
                        className="bind_bin_next_inp support_textarea"
                        id="message"
                        value={message}
                        onChange={(e) => {
                          const value = e.target.value;
                          const sanitizedValue = value.trimStart();
                          setMessage(sanitizedValue);
                          setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
                        }}
                      ></textarea>
                      {/* Error message for message */}
                      {errors.message && <span className="error">{errors.message}</span>}
                    </div>

                    <div className="w-100">
                      <button
                        className="support-btn"
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="container m-top-20 p-0">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="faq">
                        <h5 className="faq-title">FAQs</h5>
                        {faqs.length > 0 ? (
                          faqs.map((faq, index) => (
                            <div className="accordion-item font-satoshi" key={faq._id}>
                              <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-collapse-${index}`}
                                >
                                  {index + 1}. {faq.question}
                                  <span className="icon">
                                    <i className="fas fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`flush-heading-${index}`}
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {faq.status === "Active" ? faq.answer : "Content not available."}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className="faq_cate_span">No FAQs available for Support.</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recent_depo_main">
                <div className="manage_btn_row">
                  <span className="home_right_marketspan">Support History</span>
                  <Link to="/supporthistory">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <span className="home_starty_view">View All</span>
                      <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                  </Link>
                </div>

                <div className="recent_depo_tablemain pb-0">
                  <div className="table-responsive table-borderless home_recent_table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Ticket ID</th>
                          <th>Date & Time</th>
                          <th>Subject</th>
                          <th>Category</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          // Show loader when data is being fetched
                          <tr>
                            <td colSpan="5" className="text-center">
                              <div className="loader_main loader_mainChange">
                                <ScaleLoader color="#36d7b7" height={10} width={3} />
                                <p>Loading...</p>
                              </div>
                            </td>
                          </tr>
                        ) : supportHistory.length > 0 ? (
                          supportHistory.slice(0, 5).map((ticket) => (
                            <tr key={ticket._id}>
                              <td><span>{ticket._id}</span></td>
                              <td><span>{ticket.created_at}</span></td>
                              <td className="table-flex">{truncateText(ticket.subject, 15)}</td>
                              <td><span>{ticket.category}</span></td>
                              <td><span>{ticket.status === "0" ? "Open" : "Closed"}</span></td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">No support tickets found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
