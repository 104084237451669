import "./App.css";
import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import EmailVerify from "./pages/EmailVerify";
import TfaLogin from "./pages/TfaLogin";
import TermsCondition from "./pages/TermsCondition";
import Home from "./pages/Home";
import Assets from "./pages/Assets";
import Strategies from "./pages/Strategies";
import Refferal from "./pages/Refferal";
import Profile from "./pages/Profile";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import ApiBind from "./pages/ApiBind";
import AutoLogout from "./AutoLogout";
import Team from "./pages/Team";
import Security from "./pages/Security";
import SecurityWithdraw from "./pages/SecurityWithdraw";
import SecurityLoginpass from "./pages/SecurityLoginpass";
import SecurityTfa from "./pages/SecurityTfa";
import Policy from "./pages/Policy";
import FAQ from "./pages/FAQ";
import PrivateRoute from "./pages/PrivateRoute";
import { UserProvider } from "./contexts/UserContext";
import { TfaProvider } from "./contexts/2facontext";
import Upgrade from "./pages/Upgrade";
import transitions from "@material-ui/core/styles/transitions";
import PageNotFound from "./pages/PageNotFound";
import UnderMaintanence from "./pages/UnderMaintanence";
import WithdrawHistory from "./pages/withdrawhistory";
import Deposithistory from "./pages/deposithistory";
import LoginHistory from "./pages/loginHistory";
import AllHistory from "./pages/allhistory";
import Privacypolicy from "./pages/Privacypolicy";
import Support from "./pages/Support";
import HistoryAll from "./pages/HistoryAll";
import SupportHistory from "./pages/supporthistory";
import WalletTransfer from "./pages/WalletTransfer";
import Referralhistory from "./pages/refferalhistory";
function App() {
  useEffect(() => {
    if (window.AOS) {
      window.AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
      });
    }
  }, []);

  return (
    <UserProvider>
      <TfaProvider>
        <BrowserRouter>
        <AutoLogout/>
          <ToastContainer
            autoClose={2500}
            transition={Slide}
            hideProgressBar={true}
          />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/termscondition" element={<TermsCondition />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/verification" element={<EmailVerify />} />
            <Route path="/tfa" element={<TfaLogin />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/maintanence" element={<UnderMaintanence />} />
            {/* Protected Routes */}
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <PrivateRoute>
                  <Assets />
                </PrivateRoute>
              }
            />
            <Route
              path="/strategies"
              element={
                <PrivateRoute>
                  <Strategies />
                </PrivateRoute>
              }
            />
            <Route
              path="/loginHistory"
              element={
                <PrivateRoute>
                  <LoginHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/referral"
              element={
                <PrivateRoute>
                  <Refferal />
                </PrivateRoute>
              }
            />
            <Route
              path="/supporthistory"
              element={
                <PrivateRoute>
                  <SupportHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/allhistory"
              element={
                <PrivateRoute>
                  <AllHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/transferwallet"
              element={
                <PrivateRoute>
                  <WalletTransfer />
                </PrivateRoute>
              }
            />
            <Route
              path="/withdrawhistory"
              element={
                <PrivateRoute>
                  <WithdrawHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/deposithistory"
              element={
                <PrivateRoute>
                  <Deposithistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/deposit"
              element={
                <PrivateRoute>
                  <Deposit />
                </PrivateRoute>
              }
            />
            <Route
              path="/withdraw"
              element={
                <PrivateRoute>
                  <Withdraw />
                </PrivateRoute>
              }
            />
            <Route
              path="/apibind"
              element={
                <PrivateRoute>
                  <ApiBind />
                </PrivateRoute>
              }
            />
            <Route
              path="/upgrade"
              element={
                <PrivateRoute>
                  <Upgrade />
                </PrivateRoute>
              }
            />
            <Route
              path="/team"
              element={
                <PrivateRoute>
                  <Team />
                </PrivateRoute>
              }
            />
            <Route
              path="/security"
              element={
                <PrivateRoute>
                  <Security />
                </PrivateRoute>
              }
            />
            <Route
              path="/security-withdraw"
              element={
                <PrivateRoute>
                  <SecurityWithdraw />
                </PrivateRoute>
              }
            />
            <Route
              path="/security-loginpassword"
              element={
                <PrivateRoute>
                  <SecurityLoginpass />
                </PrivateRoute>
              }
            />
            <Route
              path="/security-tfa"
              element={
                <PrivateRoute>
                  <SecurityTfa />
                </PrivateRoute>
              }
            />
            <Route
              path="/support"
              element={
                <PrivateRoute>
                  <Support />
                </PrivateRoute>
              }
            />
            <Route
              path="/referralhistory"
              element={
                <PrivateRoute>
                  <Referralhistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/history"
              element={
                <PrivateRoute>
                  <HistoryAll />
                </PrivateRoute>
              }
            />


            {/* Public Routes */}
            <Route path="/policy" element={<Policy />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </BrowserRouter>
      </TfaProvider>
    </UserProvider>
  );
}

export default App;
