import React, { useEffect } from "react";
import useState from "react-usestateref";

import authService from "../services/authservices";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ScaleLoader} from 'react-spinners'
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

function WithdrawalHistory() {

    const [amount, setAmount] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [timer, setTimer] = useState(120);
    const [emailOtp, setEmailOtp] = useState("");
    const [toastVisible , setToastVisible] = useState(false);
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [tfaCode, setTfaCode] = useState("");
    const [currentPageWd, setCurrentPageWd] = useState(1);
        const [totalPagesWd, setTotalPagesWd] = useState(0);
        const [limitWd, setLimitWd] = useState(10); 
    const [coinType, setCoinType, coinTyperef] = useState("USDC");


    const [addressOptions, setAddressOptions] = useState([]);

    const [network, setNetwork] = useState("BEP20");


    const showSuccessToast = (message) => {
        if (!toastVisible) {
          setToastVisible(true);
          toast.success(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">{message}</p>
            </div>,
            {
              onClose: () => setToastVisible(false), // Reset state on close
              autoClose: 3000, // Auto-close after 3 seconds
            }
          );
        }
      };


    const [user, setUser] = useState({
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        displayname: null,
        country: null,
        isAuthenticated: false,
    });
    const [loading, setLoading] = useState(true);
    const fetchUserDetails = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const data = await authService.getUserDetails(token);
                if (data && data.status) {
                    const userDetails = {
                        isAuthenticated: true,
                        tfaStatus: data.tfastatus,
                        email: data.email,
                        displayname: data.displayname,
                        country: data.country,
                        uid: data.uid,
                        referralCode: data.referralCode,
                        referralUrl: data.referralUrl,
                    };
                    setUser(userDetails);
                } else {
                    setUser({
                        isAuthenticated: false,
                        tfaStatus: 0,
                        email: null,
                        uid: null,
                        referralCode: null,
                        referralUrl: null,
                    });
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                setUser({
                    isAuthenticated: false,
                    tfaStatus: 0,
                    email: null,
                    uid: null,
                    referralCode: null,
                    referralUrl: null,
                });
            }
        } else {
            setUser({
                isAuthenticated: false,
                tfaStatus: 0,
                email: null,
                uid: null,
                referralCode: null,
                referralUrl: null,
            });
        }
        setLoading(false);
    };

    const handlePageChangeWd = (event, value) => {
        setCurrentPageWd(value);
    };

    const truncateText = (text, maxLength) => {
        if (!text || typeof text !== "string") {
          return "--";
        }
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + " . . .";
        }
        return text;
      };
    useEffect(() => {
        fetchUserDetails();
    }, []);
    const handleCopy = (txID) => {
        if (handleCopy.timeout) {
          clearTimeout(handleCopy.timeout);
        }
        let toastVisible = false;
      
        handleCopy.timeout = setTimeout(() => {
          if (handleCopy.toastVisible) return;
      
          handleCopy.toastVisible = true;
      
          navigator.clipboard
            .writeText(txID)
            .then(() => {
                showSuccessToast(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">Transaction ID copied to clipboard!</p>
                </div>
              );
            })
            .catch((err) => {
              console.error("Failed to copy text: ", err);
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">Failed to copy Transaction ID</p>
                </div>
              );
            }) 
            .finally(() => {
              setTimeout(() => {
                handleCopy.toastVisible = false;
              }, 2000); 
            });
        }, 300); 
      };
      
    const [withdrawDetails, setWithdrawDetails] = useState({
        minWithdraw: 0,
        maxWithdraw: 0,
        availableBalance: 0,
        fees: 0,
    });
    useEffect(() => {
        const fetchWithdrawDetails = async () => {
            const result = await authService.getWithdrawDetails(coinType);
            if (result) {
                setWithdrawDetails({
                    minWithdraw: result.data.minWithdrawLimit,
                    maxWithdraw: result.data.maxWithdrawLimit,
                    availableBalance: result.data.balance,
                    fees: result.data.withdrawFee,
                });
            } else {
                console.error("Failed to fetch withdrawal details:", result.message);
            }
        };

        fetchWithdrawDetails();
    }, [coinType]);


    useEffect(() => {
        const fetchAddresses = async () => {
            const result = await authService.getWithdrawAddresses(coinType);
            const filteredAddresses = result.data.filter(
                (address) => address.token === coinType && address.network === network
            );

            const formattedAddresses = filteredAddresses.map((address) => ({
                value: address.address,
                label: address.label || address.address,
                token: address.token,
                network: address.network,
            }));

            setAddressOptions(formattedAddresses);
        };


        fetchAddresses();
    }, [coinType, network]);

    useEffect(() => {
        fetchWithdrawalHistory(currentPageWd, limitWd);
    }, [currentPageWd, limitWd]);

    const fetchWithdrawalHistory = async (page, limit) => {
        const response = await authService.getWithdrawalHistory(page, limit);
        if (response.status) {
            setWithdrawalHistory(response.data);
            setTotalPagesWd(Math.ceil(response.total / limit));
        } else {
            console.error('Failed to fetch withdrawal history:', response.Message);
        }
    };




    return (

        <div className="recent_depo_main">
            <div className="manage_btn_row">
                <span className="home_right_marketspan">Recent Withdraw</span>
                <Link to="/withdraw">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">Back to Withdraw</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                </Link>
            </div>
            <div className="recent_depo_tablemain pb-0">
                <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                      <div className="loader_main loader_mainChange">
                                          <ScaleLoader color="#36d7b7" height={10} width={3} />
                                          <p>Loading...</p>
                                        </div>
                    ) : (
                        <>
                         <table className="table">
                            <thead>
                                <tr>
                                    <th className="text_left">Currency</th>
                                    <th>Date & Time</th>
                                    <th>Network</th>
                                    <th>Amount</th>
                                    <th>Transaction ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {withdrawalHistory.length > 0 ? (
                                    withdrawalHistory.map((withdrawal, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="home_recent_firstdata">
                                                    <img
                                                        src={require("../assests/usdt-logo.png")}
                                                        alt="USDT"
                                                        className="usdt_home_recent"
                                                    />
                                                    <span>{withdrawal.currency}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <span>
                                                    {isNaN(new Date(withdrawal.date))
                                                        ? "null"
                                                        : dayjs(withdrawal.date).format(
                                                            "DD MMMM YYYY, hh:mm A"
                                                        )}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{withdrawal.network}</span>
                                            </td>
                                            <td>
                                                <span>{withdrawal.amount}</span>
                                            </td>
                                            <td>
                                                <div className="home_uid_main">
                                                <div className="home_uid_main">
                                      <span>
                                        {withdrawal.txID && withdrawal.txID !== "Pending"
                                          ? truncateText(withdrawal?.txID, 10)
                                          : withdrawal.transactionId && withdrawal.transactionId !== "Pending"
                                            ? truncateText(withdrawal?.transactionId, 10)
                                            : "-"}
                                      </span>
                                      {(withdrawal.txID && withdrawal.txID !== "Pending") ||
                                        (withdrawal.transactionId && withdrawal.transactionId !== "Pending") ? (
                                        <i
                                          className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                          onClick={() => handleCopy(withdrawal.txID || withdrawal.transactionId)}
                                        ></i>
                                      ) : null}
                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">
                                            <span className="depo_no_record">
                                                No transactions found
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {totalPagesWd > 1 && (
                                                                                  <div className="pagination history_pagination_all ">
                                                                                      <Stack spacing={2}>
                                                                                          <Pagination
                                                                                              count={totalPagesWd}
                                                                                              page={currentPageWd}
                                                                                              onChange={handlePageChangeWd}
                                                                                              size="small"
                                                                                              sx={{
                                                                                                "& .MuiPaginationItem-root": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .Mui-selected": {
                                                                                                    backgroundColor: "#ffc630 !important",
                                                                                                    color: "#000",
                                                                                                    "&:hover": {
                                                                                                        backgroundColor: "#ffc630",
                                                                                                        color: "#000",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiPaginationItem-ellipsis": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .MuiPaginationItem-icon": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                            }}
                                                                                          />
                                                                                      </Stack>
                                                                                  </div>
                                                                              )}
                        
                        </>
                       
                        
                    )}
                </div>
            </div>
        </div>
    )
}



export default WithdrawalHistory;