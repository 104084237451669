import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ScaleLoader} from 'react-spinners'
import dayjs from "dayjs";
import Header from "./Header";

import ApiService from "../services/authservices";
function Refferal() {
  const copyToClipboard = (() => {
    let toastVisible = false; 
    return (text) => {
      if (!text) {
        console.warn("No text provided for copying.");
        return;
      }
  
      if (toastVisible) return; 
  
      if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);
  
      copyToClipboard.timeout = setTimeout(() => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            toastVisible = true; 
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">Copied to clipboard!</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          })
          .catch((err) => {
            console.error("Failed to copy text:", err);
            toastVisible = true; 
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">Failed to copy text</p>
              </div>,
              {
                onClose: () => {
                  toastVisible = false; 
                },
              }
            );
          });
      }, 300); 
    };
  })();
  

  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    referralCount: null,
    directReferralCount: null,
    indirectReferralCount: null,
    profits:0,
    isAuthenticated: false,
  });
  const [loading, setLoading] = useState(true);
  const fetchUserDetails = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const data = await ApiService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
            referralCount: data.referralCount,
            directReferralCount: data.directReferralCount,
            indirectReferralCount: data.indirectReferralCount,
            profits: data.profits,
          };
          setUser(userDetails);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
            referralCount: null,
            directReferralCount: null,
            indirectReferralCount: null,
            profits: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
          referralCount: 0,
          directReferralCount: 0,
          indirectReferralCount: 0,
          profits: 0,
        });
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        referralCount: 0,
        directReferralCount: 0,
        indirectReferralCount: 0,
        profits: 0,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const [referralHistory, setReferralHistory] = useState([]);

  useEffect(() => {
    fetchReferralHistory();
  }, []);

  

  const fetchReferralHistory = async () => {
    try {
      setLoading(true);
      const data = await ApiService.getReferralHistory();
      if (data && data.status && Array.isArray(data.referralHistory)) {
        setReferralHistory(data.referralHistory);
        setLoading(false);
      } else {
        setReferralHistory([]);
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching referral history:", error);
      setReferralHistory([]);
      setLoading(false)
    }
  };

  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="asset_row_resp">
        <div className="container">
          <div className="home_main">
            <div className="row refer_main">
              <div className="col-lg-6 col-md-6 col-sm-12 refer_left_col">
                <span className="refer_top_left">
                  {" "}
                  Invite & Earn the Rewards!
                </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 m-top-20-770 refer_right_col">
                <div className="refer_right_main">
                  <div className="refer_top_innertop">
                    <span className="refer_link_span">
                      {user.referralUrl || "Generating link..."}
                    </span>
                    <div className="refer_cop_div">
                      <i
                        class="ri-file-copy-line cursor-pointer copy_refer"
                        onClick={() => copyToClipboard(user.referralUrl)}
                      ></i>
                    </div>
                  </div>
                  <div className="refer_top_innertop">
                    <span className="refer_link_span">
                      {user.referralCode ? user.referralCode : "Loading..."}
                    </span>
                    <div className="refer_cop_div">
                      <i
                        class="ri-file-copy-line cursor-pointer copy_refer"
                        onClick={() => copyToClipboard(user.referralCode)}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0 pad-0-560">
                <div className="refer_second_main">
                  <span className="refer_myrefer_head">
                    My Referral and Earnings
                  </span>
                  <div className="row w-100 margin-0">
                    <div className="col-lg-4 pad-0-560">
                      <div className="refer_count_box">
                        <div className="refer_in_top">
                          <img
                            src={require("../assests/icons/refer-earning.png")}
                            alt="Refer"
                            className="refer_icon_inbox"
                          />
                          <span className="top_startgy_inbottomadvance">
                            {" "}
                            Your cumulative earnings
                          </span>
                        </div>
                        {user.profits != null && user.profits != undefined ? (
                        <span className="refer_count_number">{user.profits.toFixed(6)}</span>
                        ) : (
                          <span className="refer_count_number">0.00</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 pad-0-560 m-top-20">
                      <div className="refer_count_box">
                        <div className="refer_in_top">
                          <img
                            src={require("../assests/icons/refer-teamember.png")}
                            alt="Refer"
                            className="refer_icon_inbox"
                          />
                          <span className="top_startgy_inbottomadvance">
                            {" "}
                            Referred friend
                          </span>
                        </div>
                        {user.directReferralCount != null && user.directReferralCount != undefined ? (
                        <span className="refer_count_number">{user.directReferralCount}</span>
                        ) : (
                          <span className="refer_count_number">0</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 pad-0-560 m-top-20">
                      <div className="refer_count_box">
                        <div className="refer_in_top">
                          <img
                            src={require("../assests/icons/refer-teamember.png")}
                            alt="Refer"
                            className="refer_icon_inbox"
                          />
                          <span className="top_startgy_inbottomadvance">
                            {" "}
                            Team members
                          </span>
                        </div>
                        {user.referralCount != null && user.referralCount != undefined ? (
                        <span className="refer_count_number">{user.referralCount}</span>
                        ) : (
                          <span className="refer_count_number">0</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="refer_third_main pb-0">
                  <div className="manage_btn_row">
                    <span className="home_botstatus_span">Recent Referral History</span>
                    <Link to="/referralhistory">
                      <div className="d-flex align-items-center gap-2 cursor-pointer">
                        <span className="home_starty_view">View All</span>
                        <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                      </div>
                    </Link>
                  </div>

                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                       <div className="loader_main loader_mainChange">
                                           <ScaleLoader color="#36d7b7" height={10} width={3} />
                                           <p>Loading...</p>
                                         </div>
                    ) : (
                      <>
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text_left">SI.no</th>
                            <th>User Name</th>
                            <th className="text_right">Date & Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referralHistory.length > 0 ? (
                            referralHistory.slice(0, 5).map((referral, index) => (
                              <tr key={referral.uuid}>
                                <td className="text_left">
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <span>{referral.displayname}</span>
                                </td>
                                <td className="text_right">
                                  <span>
                                  {isNaN(new Date(referral.createdAt))
                                      ? "null"
                                      : dayjs(referral.createdAt).format(
                                        "DD MMMM YYYY, hh:mm A"
                                      )}
                                    {/* {new Date(referral.createdAt).toLocaleString()} */}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">
                                <span className="depo_no_record">
                                  No referral history available.
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      </>
                      
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refferal;
