import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { getQrcode, changeTFA } from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from 'react-spinners'
import authService from "../services/authservices";
function SecurityTfa() {
  const [user, setUser] = useState({
    tfaStatus: 0,
    email: null,
    uid: null,
    referralCode: null,
    referralUrl: null,
    displayname: null,
    country: null,
    isAuthenticated: false,
  });
  const [toastVisible , setToastVisible] = useState(false);
  const [loader , setLoader] = useState(true);
  const fetchUserDetails = async () => {
    setLoader(true);
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const data = await authService.getUserDetails(token);
        if (data && data.status) {
          const userDetails = {
            isAuthenticated: true,
            tfaStatus: data.tfastatus,
            email: data.email,
            displayname: data.displayname,
            country: data.country,
            uid: data.uid,
            referralCode: data.referralCode,
            referralUrl: data.referralUrl,
          };
          setUser(userDetails);
          setLoader(false);
        } else {
          setUser({
            isAuthenticated: false,
            tfaStatus: 0,
            email: null,
            uid: null,
            referralCode: null,
            referralUrl: null,
          });
          setLoader(false);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setUser({
          isAuthenticated: false,
          tfaStatus: 0,
          email: null,
          uid: null,
          referralCode: null,
          referralUrl: null,
        });
        setLoader(false);
      }
    } else {
      setUser({
        isAuthenticated: false,
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
      });
    }
    setLoader(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  const tfaStatus = user.tfaStatus;
  const [qrCode, setQrCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(""); // State to store error message for 2FA validation


  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("tfaStatus-->", tfaStatus)
    if (!token) {
      navigate("/login");
      return;
    }
    console.log("tfaStatus-->",tfaStatus);
    if (tfaStatus === 0) {
      const fetchQrCode = async () => {
        try {
          const data = await getQrcode(token);
          console.log("QR Code Data:", data);
          if (data.status) {
            setQrCode(data.data.tfa_url);
            setSecretKey(data.data.tfaenablekey);
          } else {
            console.error("Failed to fetch QR code:", data.Message);
          }
        } catch (error) {
          console.error("Error fetching QR code:", error);
        }
      };
      fetchQrCode();
    }
  }, [tfaStatus, navigate]);

  const validate2FACode = (code) => {
    // Validate: Code should be exactly 6 digits
    return /^[0-9]{6}$/.test(code);
  };

  const handleToggle2FA = async (e) => {
    e.preventDefault();
    if (!validate2FACode(code)) {
      setError("Invalid 2FA code! It must be 6 digits.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const token = localStorage.getItem("token");
      const data = await changeTFA(token, code);

      if (data.status) {
        setCode("");
        const newStatus = tfaStatus === 1 ? 0 : 1;
        setUser((prev) => ({ ...prev, tfaStatus: newStatus }));
        showSuccessToast('TFA Status Changed Successfully')
        // setTimeout(() => {
          navigate("/security", { state: { tfaStatus: newStatus } });
        // }, 2000);

      } else {
        showErrorToast(data.Message)
      }
    } catch (error) {
      console.error("Error changing TFA status:", error);
      showErrorToast('Error changing TFA status. Please try again.')
      
    } finally {
      setLoading(false);
    }
  };
  const copyToClipboard = (() => {
    let toastVisible = false; 

    return (text) => {
        if (!text) {
            console.warn("No text provided for copying.");
            return;
        }

        if (toastVisible) return; 

        if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);

        copyToClipboard.timeout = setTimeout(() => {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    toastVisible = true; 
                    toast.success(
                        <div className="toast-ml">
                            <h4 className="toast-title">Success!</h4>
                            <p className="toast-message">Authenticated code is copied!</p>
                        </div>,
                        {
                            onClose: () => {
                                toastVisible = false; 
                            },
                        }
                    );
                })
                .catch((err) => {
                    console.error("Failed to copy text:", err);
                    toastVisible = true; // Show error toast
                    toast.error(
                        <div className="toast-ml">
                            <h4 className="toast-title">Error!</h4>
                            <p className="toast-message">Failed to copy code</p>
                        </div>,
                        {
                            onClose: () => {
                                toastVisible = false; 
                            },
                        }
                    );
                });
        }, 300); 
    };
})();

    const showsuccessToast = (message) => {
      toast.dismiss();
      toast.success(message);
    };
  
    const showerrorToast = (message) => {
      toast.dismiss();
      toast.error(message);
    };
const showErrorToast = (message) => {
          if (!toastVisible) {
            setToastVisible(true); // Set toast as visible
            toast.error(
              <div className="toast-ml">
                <h4 className="toast-title">Error!</h4>
                <p className="toast-message">{message}</p>
              </div>,
              {
                onClose: () => setToastVisible(false), // Reset toastVisible on close
                autoClose: 3000, // Optional: auto-close after 3 seconds
              }
            );
          }
        };
      
        const showSuccessToast = (message) => {
          if (!toastVisible) {
            setToastVisible(true);
            toast.success(
              <div className="toast-ml">
                <h4 className="toast-title">Success!</h4>
                <p className="toast-message">{message}</p>
              </div>,
              {
                onClose: () => setToastVisible(false), // Reset state on close
                autoClose: 3000, // Auto-close after 3 seconds
              }
            );
          }
        };


  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="container">
        <div className="row prof_row_sm">
          <div className="col-lg-2 dispaly_none_1200">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 deposit_pad_top">
            {loader == true ? (
                  <div className="secu_tfa_newload">
                      <div className="loader_main loader_mainChange">
                      <ScaleLoader color="#36d7b7" height={10} width={3} />
                      <p>Loading...</p>
                    </div>
                    </div>
            ) : (
              <>
            <div className="security_with_topmain">
              <Link to="/security">
                <span className="Security_center_navinner">
                  Security center
                </span>
              </Link>
              <i class="fa-solid fa-chevron-right Securitycenter_i_navinner "></i>
              <span className="Change_Password_secwith">
                Link authenticator app
              </span>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8 col-xl-6 col-md-8">
                <div className="secwith_main">
                  <span className="change-password">
                    Link authenticator app
                  </span>
                  {tfaStatus === 0 && (
                    <div className="tfa_securi_inbotmain">
                      <div className="tfa_qr_upper">
                        {qrCode ? (
                          <img
                            src={qrCode}
                            alt="QR code"
                            className="QR-sectfa-img"
                          />
                        ) : (
                          <p>Loading QR code...</p>
                        )}
                      </div>
                      <div className="reg_pos_rel w-100">
                        <input
                          className="secu_tfa_input"
                          type="text"
                          value={secretKey}
                          disabled
                          readOnly
                        />
                        <i
                          className="ri-file-copy-line cursor-pointer uid_copy_tfasec"
                          onClick={() => copyToClipboard(secretKey)}
                        ></i>
                      </div>
                    </div>
                  )}
                  <div className="tfa_top_imp">
                    <span className="tfa_sacnqr_subspan">2FA code</span>
                    <input
                      className="reg_input"
                      type="number"
                      min="0"
                      max="999999"
                      name="code"
                      value={code}
                      placeholder="Enter your 2FA code here"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 6) {
                          setCode(value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "e" ||
                          e.key === "E" ||
                          e.key === "+" ||
                          e.key === "-"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                     {error && <span className="error">{error}</span>}
                  </div>
                  <div
                    className="bin_bind_submit_div"
                    onClick={handleToggle2FA}
                  >
                    <span>
                      {loading
                        ? "Loading..."
                        : tfaStatus === 1
                          ? "Disable"
                          : "Enable"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityTfa;
