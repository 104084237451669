import React, { useEffect,useContext } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import FreeImage from "../assests/freeweb.webp"
import PreImage from "../assests/white_img.webp"
import ProImage from "../assests/yelllow_img.webp"
import { ScaleLoader } from 'react-spinners'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Upgrade = () => {
  const [coinType, setCoinType, coinTyperef] = useState("USDC");

  const options = [
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    {
      value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      label: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#f9f9f9",
      border: "1px solid #ccc",
      padding: "4px",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: isFocused ? "#292b31" : "#181a20",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#000",
    }),
  };

  useEffect(() => {
    getPlan();
    getPlanDetails()
  }, [0]);

  const [getplan, setGetplan] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [pageLoader, setpageLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [freemiumActive, setFreemiumActive] = useState(null);
  const [premiumActive, setPremiumActive] = useState(null);
  const [promiumActive, setPromiumActive] = useState(null);
  const [planLoader, setplanLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [premiumContent, setPremiumContent] = useState(null);

  const getPlan = async () => {
    try {
      setpageLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.getPlan(token);
      setLoading(false);
      if (response.status) {
        setGetplan(response.data);
        
      } else {
      }
    } catch (error) { setLoading(false) }
  };
  const getPlanDetails = async () => {
    try {
      setpageLoader(true);
      const token = localStorage.getItem("token");
      const response = await authService.getPlanDetails(token);
      setLoading(false);
  
      if (response.data && Array.isArray(response.data)) {
        const activePlans = {
          freemium: false,
          premium: false,
          promium: false,
        };
  
        response.data.forEach((plan) => {
          if (plan.subscriptionName === "Freemium" && plan.isActive) {
            activePlans.freemium = true;
          } else if (plan.subscriptionName === "Premium" && plan.isActive) {
            activePlans.premium = true;
          } else if (plan.subscriptionName === "Promium" && plan.isActive) {
            activePlans.promium = true;
          }
        });
  
        // Update state at once for better performance
        setFreemiumActive(activePlans.freemium);
        setPremiumActive(activePlans.premium);
        setPromiumActive(activePlans.promium);
  
        const freemiumActivated = response.data.some(
          (plan) => plan.subscriptionName === "Freemium" && plan.isFreemiumActivated === true
        );
        
        localStorage.setItem("freemiumActivated", freemiumActivated);
        setPlanDetails(response.data);
      } else {
        setPlanDetails([]); 
        localStorage.setItem("freemiumActivated", false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching plan details:", error);
    }
  };
  

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(
      <div className="toast-ml">
        <h4 className="toast-title">Success!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(
      <div className="toast-ml">
        <h4 className="toast-title">Error!</h4>
        <p className="toast-message">{message}</p>
      </div>
    );
  };

  const handleClose = () => {
    setShow(false);
    setPremiumContent(null);
  };

    const handleShow = (planName, subscription) => {
      console.log(subscription,"subscription")
      switch (planName) {
        case "Premium":
          setPremiumContent(subscription)
          break;
        case "Freemium":
          setPremiumContent(subscription)
          break;
        case "Promium":
          setPremiumContent(subscription)
          break;
        default: 
        break;
      }
      setShow(true);
    };

  const PlanUpgrade = async (data) => {
    try {
      var obj = {
        subid: data._id,
      };
      console.log(obj)
      const response = await authService.planUpgrade(obj);
      setLoading(false);
      if (response.status) {
        showsuccessToast(response.Message);
        getPlanDetails()
      } else {
        showerrorToast(response.Message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, "-----------------");
    }
    setShow(false)
  };
  const [faqs, setFaqs] = useState({});

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await authService.getFaq();
        if (response.status) {

          const withdrawFaqs = response.data.filter((faq) => faq.type === "Upgrade");
          setFaqs(withdrawFaqs);
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);


  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };


  const SubscriptionCard = ({ subscription, freemiumActive}) => {
    if (!subscription) {
      return (
        <div className="no_plan_message">
          <p>No plans available at the moment.</p>
        </div>
      );
    }
    switch (subscription.subscriptionName) {
      case "Freemium":
        return (

          <div className="free_upgradesec">
            <div className="free_version_section">
            {freemiumActive && (
      <div className="activated_tag">Activated</div>
    )}
              <img src={FreeImage} alt="Loading..." />
              <h5>{capitalizeFirstLetter(subscription.subscriptionName)}</h5>
              <p>Bots Strategies</p>
              <p className="free_intra">{subscription.Strategies}</p>
              <h1>Free</h1>
              {/* <p style={{visibility:'hidden'}} className="free_tag">Free for 1 month </p> */}
              <div className="activate_btn">
                <button disabled={freemiumActive} onClick={()=> handleShow('Freemium',subscription)}>Activate</button>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Exchanges:</span> {capitalizeFirstLetter(subscription.Exchanges)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Profit Split:</span> {subscription.ProfitSplit.user}(Users): {subscription.ProfitSplit.referral}(Referrals): {subscription.ProfitSplit.ipf}(IPF) + {subscription.ProfitSplit.admin}(Admins)</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Own Account Use:</span> {capitalizeFirstLetter(subscription.OwnAccountUse)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Minimum Balance Req To Trade:</span> {subscription.minbalancereq}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Referral Withdrawal:</span> {capitalizeFirstLetter(subscription.Refferalwithdraw)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list">Withdrawal Time For All
                    User, Referral:</span> {capitalizeFirstLetter(subscription.Withdrawtimeforallrefferaluser)}</p>
                </div>
              </div>
            </div>
          </div>

        );
      default:
        return null
    }
  };
  const SubscriptionCardtwo = ({ subscription, premiumActive }) => {
    if (!subscription) {
      return (
        <div className="no_plan_message">
          <p>No plans available at the moment.</p>
        </div>
      );
    }
    switch (subscription.subscriptionName) {
      case "Premium":
        return (
          <div className="perimium_upgradesec">
            <div className="premium_version_section">
              {premiumActive && (
                <div className="activated_tag_pre">Activated</div>
              )}
              <img src={PreImage} className="premium_img" alt="Loading..." />
              <h5>{capitalizeFirstLetter(subscription.subscriptionName)}</h5>
              <p>Bots Strategies</p>
              <p className="free_intra">{subscription.Strategies}</p>
              <h1>{parseFloat(subscription.subscriptionAmount).toFixed(2)} <span className="currency_name">{subscription.subscriptionCurrency}</span><span className="premium_cost">/month</span></h1>
              <p className="free_tag">Free for 1 month </p>
              <div className="activate_btn_white">
                <button disabled={premiumActive} onClick={()=> handleShow('Premium',subscription)}>Activate</button>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p>{capitalizeFirstLetter(subscription.Exchanges)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pre">Profit Split:</span> {subscription.ProfitSplit.user}(Users): {subscription.ProfitSplit.referral}(Referrals): {subscription.ProfitSplit.ipf}(IPF) + {subscription.ProfitSplit.admin}(Admins)</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pre">Own Account Use:</span> {capitalizeFirstLetter(subscription.OwnAccountUse)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pre">Minimum Balance Req To Trade:</span> {subscription.minbalancereq}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pre">Referral Withdrawal:</span> {capitalizeFirstLetter(subscription.Refferalwithdraw)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pre"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pre">Withdrawal Time For All
                    User, Referral:</span> {capitalizeFirstLetter(subscription.Withdrawtimeforallrefferaluser)}</p>
                </div>
              </div>
            </div>
          </div>

        )
      default:
        return null;
    }
  };

  const SubscriptionCardthree = ({ subscription,promiumActive }) => {
    if (!subscription) {
      return (
        <div className="no_plan_message">
          <p>No plans available at the moment.</p>
        </div>
      );
    }
    switch (subscription.subscriptionName) {
      case "Promium":
        return (
          <div className="extrapre_upgradesec">
            <div className="pro_version_section">
              {promiumActive && (
                <div className="activated_tag_pro">Activated</div>
              )}
              <img src={ProImage} className="premium_imgcost" alt="Loading..." />
              <h5>{capitalizeFirstLetter(subscription.subscriptionName)}</h5>
              <p>Bots Strategies</p>
              <p className="free_intra">{subscription.Strategies}</p>
              <h1>{parseFloat(subscription.subscriptionAmount).toFixed(2)}<span className="currency_name"> {subscription.subscriptionCurrency}</span><span className="premium_cost">/month</span></h1>
              <p className="free_tag"><span className="free_plan_pro">Free</span> for 1 month </p>
              <div className="activate_btn_pro">
                <button disabled={promiumActive} onClick={()=> handleShow('Promium',subscription)}>Activate</button>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p>{capitalizeFirstLetter(subscription.Exchanges)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pro">Profit Split:</span> {subscription.ProfitSplit.user}(Users): {subscription.ProfitSplit.referral}(Referrals): {subscription.ProfitSplit.ipf}(IPF) + {subscription.ProfitSplit.admin}(Admins)</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pro">Own Account Use:</span> {capitalizeFirstLetter(subscription.OwnAccountUse)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pro">Minimum Balance Req To Trade:</span> {subscription.minbalancereq}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pro">Referral Withdrawal:</span> {capitalizeFirstLetter(subscription.Refferalwithdraw)}</p>
                </div>
              </div>
              <div className="free_details_wholesec">
                <div className="free_details_list">
                  <span className="backgrd_circle_pro"><i class="fa-solid fa-check"></i></span>
                </div>
                <div className="free_details_list">
                  <p><span className="header_details_list_pro">Withdrawal Time For All
                    User, Referral:</span> {capitalizeFirstLetter(subscription.Withdrawtimeforallrefferaluser)}</p>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return null;
    }
  };



  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="withdraw-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <div className="upgrade_header">
                <h3 className="profile_span">
                  <span className="upgrade_text">Upgrade {' '}</span>
                  <span className="upgrade_profilespan">Your Profile</span>
                </h3>
                <p>Empower your trading with WYScale. smarter automation,
                  advanced strategies, and precision-driven success.</p>
              </div>
              <div className="row mt-4">
                {loading ? (<div className="loader_main loader_mainChange">
                  <ScaleLoader color="#36d7b7" height={10} width={3} />
                  <p>Loading...</p>
                </div>) : (
                  <>
                    <div className="col-lg-4 free_and_pro">
                      {getplan.map((subscription) => (
                        <SubscriptionCard key={subscription._id} subscription={subscription} freemiumActive={freemiumActive}  />
                      ))}
                    </div>
                    <div className="col-lg-4 ">
                      {getplan.map((subscription) => (
                        <SubscriptionCardtwo key={subscription._id} subscription={subscription} premiumActive= {premiumActive} />
                      ))}
                    </div>
                    <div className="col-lg-4 free_and_pro">
                      {getplan.map((subscription) => (
                        <SubscriptionCardthree key={subscription._id} subscription={subscription} promiumActive={promiumActive} />
                      ))}
                    </div>
                  </>

                )}

              </div>
              <Modal className="modal_section" centered show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Plan Upgrade</Modal.Title>
                </Modal.Header>
                <Modal.Body><div>
                  <h5
                    className={
                      premiumContent?.subscriptionName === "Premium"
                        ? "premium-header"
                        : premiumContent?.subscriptionName === "Promium"
                          ? "promium-header"
                          : "freemium-header"
                    }
                  >
                    {premiumContent?.subscriptionName}
                  </h5>
                  <p><strong className="stragte_head">Bots Strategies:</strong> {premiumContent?.Strategies}</p>
                  <p className="upgrade_text_modal">
                    Upgrading to the <strong className={
                      premiumContent?.subscriptionName === "Premium"
                        ? "premium-header highlight_text_mod"
                        : premiumContent?.subscriptionName === "Promium"
                          ? "promium-header highlight_text_mod"
                          : "freemium-header highlight_text_mod"
                    }>{premiumContent?.subscriptionName}</strong> plan will cost you{' '}
                    <strong className={
                      premiumContent?.subscriptionName === "Premium"
                        ? "premium-header highlight_text_mod"
                        : premiumContent?.subscriptionName === "Promium"
                          ? "promium-header highlight_text_mod"
                          : "freemium-header highlight_text_mod"
                    }>
                      {premiumContent?.subscriptionName === "Premium"
                        ? "$25.00"
                        : premiumContent?.subscriptionName === "Promium"
                          ? "$25.00"
                          : "Free"}
                    </strong>. Please confirm your choice.
                  </p>

                </div></Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" className="upgrade_btn" onClick={()=> PlanUpgrade(premiumContent)}>
                    Confirm Upgrade
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;
