import React, { useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";


function Sidebar() {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
    //  return path.some((path) => location.pathname.startsWith(path)) ? "active-link" : "";
  };

  return (
    <div>
      <div className="col-lg-2">
        <div className="dashboard_pages_list">
          <Link
            to="/profile"
            className={`dashboard_title ${isActive("profile")}`}
          >
            <img
              src={require("../assests/icons/user-icon.png")}
              alt="Profile"
            />
            <h3>Profile</h3>
          </Link>

          <Link
            to="/deposit"
            className={`dashboard_title ${isActive("deposit")}`}
          >
            <img
              src={require("../assests/icons/deposit-icon.png")}
              alt="Deposit"
            />
            <h3>Deposit</h3>
          </Link>

          <Link
            to="/withdraw"
            className={`dashboard_title ${isActive("withdraw")}`}
          >
            <img
              src={require("../assests/icons/withdraw-icon.png")}
              alt="Withdraw"
            />
            <h3>Withdraw</h3>
          </Link>

            <Link to="/apibind" className={`dashboard_title ${isActive("apibind")}`}>
              <img src={require("../assests/icons/api-bind.png")} alt="API Bind" />
              <h3>API Bind</h3>
            </Link>

            {/* <Link to="/transferwallet" className={`dashboard_title ${isActive("transferwallet")}`}>
              <img src={require("../assests/icons/api-bind.png")} alt="transferwallet" />
              <h3>Transfer Wallet</h3>
            </Link> */}
           
          <Link
            to="/upgrade"
            className={`dashboard_title ${isActive("upgrade")}`}
          >
            <img src={require("../assests/upgrade.webp")} alt="API Bind" />
            <h3>Upgrade</h3>
          </Link>
          <Link
            to="/transferwallet"
            className={`dashboard_title ${isActive("transferwallet")}`}
          >
            <img src={require("../assests/upgrade.webp")} alt="transferwallet" />
            <h3>Transfer</h3>
          </Link>

          <Link to="/team" className={`dashboard_title ${isActive("team")}`}>
            <img src={require("../assests/icons/team-ico.png")} alt="team" />
            <h3>Your Team</h3>
          </Link>

          <Link
            to="/security"
            className={`dashboard_title ${isActive("security")}`}
          >
            <img
              src={require("../assests/icons/Security-icon.png")}
              alt="Security"
            />
            <h3>Security</h3>
          </Link>

          <Link to="/faq" className={`dashboard_title ${isActive("faq")}`}>
            <img
              src={require("../assests/icons/question-icon.png")}
              alt="FAQs"
            />
            <h3>FAQs</h3>
          </Link>

          <Link
            to="/policy"
            className={`dashboard_title ${isActive("policy")}`}
          >
            <img
              src={require("../assests/icons/policy-icon.png")}
              alt="Policy"
            />
            <h3>Policy</h3>
          </Link>
          <Link
            to="/support"
            className={`dashboard_title ${isActive("support")}`}
          >
            <img
              src={require("../assests/support.webp")}
              alt="support"
            />
            <h3>Support</h3>
          </Link>
          <Link
            to="/history"
            className={`dashboard_title ${isActive("history")}`}
          >
            <img
              src={require("../assests/support.webp")}
              alt="support"
            />
            <h3>History</h3>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
