import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      if (timer) clearTimeout(timer); 
      timer = setTimeout(() => {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        navigate("/login");
      }, 15 * 60 * 1000); 
    };

    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
    
    const activityHandler = () => {
      resetTimer(); 
    };

    events.forEach((event) => {
      window.addEventListener(event, activityHandler);
    });

    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach((event) => {
        window.removeEventListener(event, activityHandler);
      });
    };
  }, [navigate]);

  return null; 
};

export default AutoLogout;
