import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authService from "../services/authservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Forgot() {
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [triedToEnterOtp, setTriedToEnterOtp] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [otpSent, setOtpSent] = useState(false);
  const [sentotpsuc, setSentotpsuc] = useState(false);
const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
const [toastVisible , setToastVisible] = useState(false);
const [emailValid, setEmailValid] = useState(false);
  const [timer, setTimer] = useState(120); // Start with 120 seconds
  const [emailSubmitted,setEmailSubmitted] = useState(false);
  const [otpLoad,setOtpload] = useState(false);

  const [otpEditable, setOtpEditable] = useState(false);

  const navigate = useNavigate();
  const sendOtpRequest = async () => {
    try {
      setOtpload(true);
      const response = await authService.forgotPassword({
        email: formData.email,
      });
      setOtpload(false);
      if (response.status) {
        // OTP sent successfully
        setOtpSent(true);
        setEmailSubmitted(true);
        setTimer(120); // Reset timer
       showSuccessToast('OTP sent successfully!')
      } else {
        showErrorToast(response.Message)
      }
    } catch (error) {
      showErrorToast('An error occurred while sending OTP. Please try again.')
    }
  };
  const sendOtp = async (value) => {
    if (!emailValid) {
      return; 
    }
    if (value === "Send OTP") {
      await sendOtpRequest();
      setSentotpsuc(true)
    } else if (value === "Resend") {
      await sendOtpRequest();
      setSentotpsuc(false);
    }
    setSentotpsuc(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };
   useEffect(() => {
          if (theme === 'dark') {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
          } else {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
          }
      
          localStorage.setItem('theme', theme);
        }, [theme])
  useEffect(() => {
    let countdown;
    if (timer > 0 && otpSent) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      // setOtpSent(false); // Enable "Resend" button after timer ends
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer, otpSent]);

  const showErrorToast = (message) => {
      if (!toastVisible) {
        setToastVisible(true); // Set toast as visible
        toast.error(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">{message}</p>
          </div>,
          {
            onClose: () => setToastVisible(false), // Reset toastVisible on close
            autoClose: 3000, // Optional: auto-close after 3 seconds
          }
        );
      }
    };
  
    const showSuccessToast = (message) => {
      if (!toastVisible) {
        setToastVisible(true);
        toast.success(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">{message}</p>
          </div>,
          {
            onClose: () => setToastVisible(false), // Reset state on close
            autoClose: 3000, // Auto-close after 3 seconds
          }
        );
      }
    };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "email") {
      setTriedToEnterOtp(false);
    }
    let newErrors = { ...errors };
    if (name === "email") {
      setEmailValid(validateEmail(value));
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) newErrors.email = "Email is required";
      else if (!emailRegex.test(value))
        newErrors.email = "Invalid email format";
      else delete newErrors.email;
    } else if (name === "otp") {
      if (!value) {
        newErrors.otp = "OTP is required";
      } else if (!/^\d{6}$/.test(value)) {
        newErrors.otp = "OTP must be a 6-digit number";
      } else {
        delete newErrors.otp;
      }
    } else if (name === "password") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
      if (!value) newErrors.password = "Password is required";
      else if (!passwordRegex.test(value))
        newErrors.password =
          "Password must be 8-15 characters long, include uppercase, lowercase, number, and special character";
      else delete newErrors.password;
    } else if (name === "confirmPassword") {
      if (!value) newErrors.confirmPassword = "Confirm Password is required";
      else if (value !== formData.password)
        newErrors.confirmPassword = "Passwords do not match";
      else delete newErrors.confirmPassword;
    }

    setErrors(newErrors);
  };

  const handleKeyDown = (e, type) => {
    // Allow Backspace and Arrow keys for all cases
    if (e.key === "Backspace" || e.key.startsWith("Arrow")) {
      return; // Let these keys be used
    }
  
    if (type === "email") {
      const regex = /^[a-zA-Z0-9@._-]+$/;
      if (
        !regex.test(e.key) || 
        (e.target.value.length >= 250 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    } else if (type === "password" || type === "confirmPassword") {
      if (
        e.key === " " || 
        (e.target.value.length >= 15 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    } else if (type === "Number") {
      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
      if (e.target.value.length >= 5 && e.key !== "Backspace") {
        e.preventDefault(); // Ensure max length for numeric input
      }
    }
  };
  

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!verifyStatus) {
      if (!formData.otp) {
        newErrors.otp = "OTP is required";
      }
    } else {
      if (!formData.password) {
        newErrors.password = "Password is required";
        return newErrors;
      } else if (!passwordRegex.test(formData.password)) {
        newErrors.password =
          "Password must be 8-15 characters long, include uppercase, lowercase, number, and special character";
        return newErrors;
      }

      if (!formData.confirmPassword) {
        newErrors.confirmPassword = "Confirm Password is required";
        return newErrors;
      } else if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
        return newErrors;
      }
    }

    return newErrors;
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("Validation errors:", validationErrors);
      return;
    }

    try {
      console.log("Sending OTP verification request with data:", {
        ...formData,
        emailOtp: Number(formData.otp),
      });
      const response = await authService.verifyOtp({
        ...formData,
        emailOtp: Number(formData.otp),
      });

      console.log("OTP verification response:", response);
      if (response.status) {
        setVerifyStatus(true);
        setOtpSent(false);
        setTimer(0);
        showSuccessToast('OTP verified successfully!')
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          otp: response?.Message || "OTP verification failed",
        }));
        showErrorToast(response?.Message || "OTP verification failed")
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: "An error occurred during OTP verification. Please try again.",
      }));
      showErrorToast("An error occurred during OTP verification. Please try again.")
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const firstErrorField = Object.keys(validationErrors)[0];
      const firstErrorElement = document.getElementsByName(firstErrorField)[0];
      if (firstErrorElement) {
        firstErrorElement.focus();
      }
      return;
    }
    const resetPasswordData = {
      email: formData.email,
      newPassword: formData.password,
      confirmPassword: formData.confirmPassword,
    };

    try {
      const response = await authService.resetPassword(resetPasswordData);
      if (response.status) {
        showSuccessToast('Password Changed Successfully')
        // setTimeout(() => {
          navigate("/login");
        // }, 3000);
      } else {
        showSuccessToast('Please try again later')
        setErrors({ ...errors, email: response.Message });
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setErrors({
        ...errors,
        email: "An error occurred during password reset. Please try again.",
      });
    }
  };

  return (
    <div>
      <div className="row register_main container-fluid">
        <div className="col-lg-6">
          <div className="d-flex flex-column mb-5">
            <div className="register_head_logo">
              <Link to="/">
                <img
                  src={require("../assests/Logo.webp")}
                  alt="Logo"
                  className="register_logo cursor-pointer"
                />
              </Link>
            </div>
            <div className="register_left_main mt-3">
              <div className="hero_center">
                {verifyStatus == false ? (
                  <div className="register_form">
                    <span className="register_head_sign">Forget Password</span>
                    <div className="register_inner_main">
                      <div className="getting_card_content w-100">
                        <div className="register_inner_submain w-100">
                          <div className="reg_inner_small">
                            <span className="register_spans">
                              Registered Email
                            </span>
                            <div className="reg_inner_small">
                              <div className="pos_rel">
                                <input
                                  type="text"
                                  name="email"
                                  placeholder="Enter the email address"
                                  className="reg_input_forgot_change"
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  onKeyDown={(e) => handleKeyDown(e, "email")}
                                  disabled={otpSent}
                                />
                                <span
                                  className="forgot_send_changedspan"
                                  // onClick={emailValid && !otpSent ? sendOtp : undefined}
                                  onClick={() => emailValid && !otpSent ? sendOtp("Send OTP") : undefined} 
                                  style={{
                                    cursor: otpSent ? "not-allowed" : "pointer",
                                    display: otpSent ? "none" : ""
                                  }}
                                >
                                  {otpLoad == true && !otpSent ? "Loading .." : "Send OTP"}
                                </span>
                              </div>

                              {triedToEnterOtp && !otpEditable && (
                                <span className="error">
                                  First enter email and click "Send OTP"
                                </span>
                              )}
                              {errors.email && (
                                <span className="error">{errors.email}</span>
                              )}
                            </div>
                            {/* <button onClick={sendOtp} disabled={otpSent}>
                              Send OTP
                            </button> */}
                          </div>
                          {emailSubmitted == true &&
                          <div className="reg_inner_small">
                            <span className="register_spans">OTP</span>
                            <div className="reg_pos_rel">
                              <input
                                type="Number"
                                name="otp"
                                placeholder="Enter the OTP"
                                className="reg_input"
                                maxLength={6}
                                value={formData.otp}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value.length <= 6) {
                                    handleInputChange(e); // Call your handler if the length is within limits
                                  }
                                }}
                                onKeyDown={(e) => handleKeyDown(e, "Number")}
                              />
                              {timer > 0 ? (
                                <span className="pos_abs_resend">
                                  Resend ({timer}s)
                                </span>
                              ) : (
                                <span
                                  className="pos_abs_resend"
                                  // onClick={sendOtp}
                                  onClick={() => sendOtp("Resend")} 
                                  style={{ cursor: !emailValid || otpSent ? "not-allowed" : "pointer" }}
                                >
                                   {otpLoad == true ? "Loading .." : "Resend"}
                                </span>
                              )}
                            </div>
                            {errors.otp && (
                              <span className="error">{errors.otp}</span>
                            )}
                          </div>
}

                        </div>
                      </div>
                      {emailSubmitted == true &&
                      <div className="getting_card_content">
                        <div className="reg_btn" onClick={handleOtpSubmit}>
                          <span className="hero_invest"> Submit </span>
                        </div>
                      </div>
}
                    </div>
                  </div>
                ) : (
                  <div className="register_form">
                    <span className="register_head_sign">Reset Password</span>
                    <div className="register_inner_main">
                      <div className="getting_card_content w-100">
                        <div className="register_inner_submain w-100">
                          <div className="reg_inner_small">
                            <span className="register_spans">Password</span>
                            <div className="reg_pos_rel">
                              <input
                                type="password"
                                name="password"
                                placeholder="Enter the password"
                                className="reg_input"
                                value={formData.password}
                                onChange={handleInputChange}
                                onKeyDown={(e) => handleKeyDown(e, "password")}
                              />
                              <i className="fa-regular fa-eye-slash reg_eye"></i>
                              {errors.password && (
                                <span className="error">{errors.password}</span>
                              )}
                            </div>
                          </div>
                          <div className="reg_inner_small">
                            <span className="register_spans">
                              Confirm Password
                            </span>
                            <div className="reg_pos_rel">
                              <input
                                type="password"
                                name="confirmPassword"
                                placeholder="Re-enter the password"
                                className="reg_input"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                onKeyDown={(e) =>
                                  handleKeyDown(e, "confirmPassword")
                                }
                              />
                              <i className="fa-regular fa-eye-slash reg_eye"></i>
                              {errors.confirmPassword && (
                                <span className="error">
                                  {errors.confirmPassword}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="getting_card_content">
                        <div className="reg_btn" onClick={handleSubmit}>
                          <span className="hero_invest"> Reset Password </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <span className="reg_bottom_span">
                  <Link to="/login">
                    {" "}
                    <span className="reg_check_span">Back to login</span>{" "}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 forgot_right_main">
          <div className="login_right">
            <div className="footer_links_div">
              <span className="register_head">Reset Your Password</span>
              <span className="register_subhead">
                {" "}
                Forgot your password? Enter your email to reset it and <br />{" "}
                get back to trading!{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
