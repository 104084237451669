import React, { useState, useRef, useEffect, useCallback,useContext} from "react";
import useStateRef from "react-usestateref";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import Header from "./Header";
import { UserContext } from "../contexts/UserContext";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ScaleLoader } from 'react-spinners'

import service from "../services/authservices";

function Deposit() {
  
  const [currency, setCurrency] = useState("USDC");
  const [network, setNetwork] = useState("BEP20");
  const [addressData, setAddressData] = useState(null);
  const [toastVisible , setToastVisible] = useState(false);
  const [dropdownKey, setDropdownKey] = useState(0); 
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const [loading, setLoading] = useState(true);
  const coinTyperef = useRef(currency);
  const { theme } = useContext(UserContext);
  const fetchAddressData = async () => {
    try {
      const data = await service.getUserAddressNetwork(currency, network);
      if (data.status) {
        setAddressData(data.data);
      } else {
        console.log(data.Message);
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };
  const [faqs, setFaqs] = useState({});

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await service.getFaq();
        if (response.status) {
          // Filter FAQs with type 'Withdraw' only
          const withdrawFaqs = response.data.filter((faq) => faq.type === "Deposit");
          setFaqs(withdrawFaqs); // Store filtered FAQs directly
        } else {
          console.error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);


  const handleDropdownReload = () => {
    setDropdownKey((prevKey) => prevKey + 1); // Change key to force re-render
  };


  const showErrorToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true); // Set toast as visible
              toast.error(
                <div className="toast-ml">
                  <h4 className="toast-title">Error!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset toastVisible on close
                  autoClose: 3000, // Optional: auto-close after 3 seconds
                }
              );
            }
          };
        
          const showSuccessToast = (message) => {
            if (!toastVisible) {
              setToastVisible(true);
              toast.success(
                <div className="toast-ml">
                  <h4 className="toast-title">Success!</h4>
                  <p className="toast-message">{message}</p>
                </div>,
                {
                  onClose: () => setToastVisible(false), // Reset state on close
                  autoClose: 3000, // Auto-close after 3 seconds
                }
              );
            }
          };
    

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await service.getDepositHistory();
      if (response.status) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          // Filter unique transactions based on transactionId
          const uniqueTransactions = response.data.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.transactionId === value.transactionId)
          );
          const totalAmount = uniqueTransactions.filter((res) => coinTyperef.current === res.currency) // Filter matching currency.reduce((sum, res) => sum + res.amount, 0);
          setTotalAmount(totalAmount)
          setTransactions(uniqueTransactions);
        } else {
          setTransactions([]);
        }
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      showErrorToast('Failed to load transactions')
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddressData();
    fetchTransactions();
  }, [currency, network]);


  // useEffect(() => {
  //   fetchAddressData();
  //   fetchTransactions();
  // }, [currency, network]);

  const currencyOptions = [
    { value: "USDC", label: "USDC" },
    { value: "USDT", label: "USDT" },
  ];

  const networkOptions = [
    { value: "BEP20", label: "BEP20" },
    { value: "TRC20", label: "TRC20" },
  ];

  const handleCopy = (txID) => {
    if (handleCopy.toastVisible) return;
  
    handleCopy.toastVisible = true; 
  
    if (handleCopy.timeout) clearTimeout(handleCopy.timeout);
  
    handleCopy.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(txID)
        .then(() => {
          showsuccessToast(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">
                Transaction ID copied to clipboard!
              </p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          showerrorToast(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy Transaction ID</p>
            </div>
          );
        })
        .finally(() => {
          setTimeout(() => {
            handleCopy.toastVisible = false;
          }, 2000); 
        });
    }, 300);
  };
  

  const handleCopyAdd = (address) => {

    if (handleCopy.toastVisible) return; 
    
    handleCopy.toastVisible = true; 

    // Prevent triggering multiple times rapidly
    if (handleCopyAdd.timeout) clearTimeout(handleCopyAdd.timeout);

    handleCopyAdd.timeout = setTimeout(() => {
      navigator.clipboard
        .writeText(address)
        .then(() => {
          showsuccessToast(
            <div className="toast-ml">
              <h4 className="toast-title">Success!</h4>
              <p className="toast-message">Address copied to clipboard!</p>
            </div>
          );
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          showerrorToast(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">Failed to copy address</p>
            </div>
          );
        }).finally(() => {
          setTimeout(() => {
            handleCopy.toastVisible = false; 
          }, 2000);  
        });
    }, 300); // 300ms debounce time
  };

// const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  useEffect(() => {
          if (theme === 'dark') {
            document.body.classList.add('dark');
            document.body.classList.remove('light');
          } else {
            document.body.classList.add('light');
            document.body.classList.remove('dark');
          }
      
          localStorage.setItem('theme', theme);
        }, [theme])

  // const customStyles = {
  //   control: (styles) => ({
  //     ...styles,
  //     backgroundColor: "#f9f9f9",
  //     border: "1px solid #ccc",
  //     padding: "4px",
  //   }),
  //   option: (styles, { isFocused }) => ({
  //     ...styles,
  //     color: "#fff",
  //     backgroundColor: isFocused ? "#292b31" : "#181a20",
  //     cursor: "pointer",
  //   }),
  //   singleValue: (styles) => ({
  //     ...styles,
  //     color: "#000",
  //   }),
  // };
  const customStyles = {
    control: (styles) => ({
      ...styles,
      // backgroundColor: "#f9f9f9",
      // border: "1px solid #ccc",
      // padding: "4px",
      backgroundColor: theme === "dark" ? "transparent" : "#f9f9f9",
      //   border: `1px solid ${theme === "dark" ? "#2b3139" : "#ccc"}`,
      padding: "4px",
      color: theme === "dark" ? "#fff" : "#000",
      //   border: "none",
      boxShadow: "none", // Removes the box-shadow
      outline: "none", // Removes any outline
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
      backgroundColor: isFocused
        ? theme === "dark"
          ? "#444"
          : "#e0e0e0"
        : theme === "dark"
          ? "#181a20"
          : "#fff",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme === "dark" ? "#fff" : "#000",
    }),
  };

    const showsuccessToast = (message) => {
      toast.dismiss();
      toast.success(message);
    };
  
    const showerrorToast = (message) => {
      toast.dismiss();
      toast.error(message);
    };

  return (
    <div>
      <section>
        <Header />
      </section>

      <div className="deposit-section">
        <div className="container">
          <div className="row prof_row_sm">
            <div className="col-lg-2 dispaly_none_1200">
              <Sidebar />
            </div>
            <div className="col-xl-10 col-lg-12 deposit_pad_top">
              <span className="profile_span">Deposit</span>
              <div className="row mt-4">
                <div className="col-lg-7">
                  <div className="deposit_left_main">
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">Select crypto</span>
                      <div className="depo_nav_design">
                        <div
                          className={
                            coinTyperef.current === "USDC"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => {
                            setCurrency("USDC");
                            coinTyperef.current = "USDC";
                          }}
                        >
                          <img
                            src={require("../assests/usdc-logo.png")}
                            alt="USDC"
                            className="logout-img"
                          />
                          <span>USDC</span>
                        </div>
                        <div
                          className={
                            coinTyperef.current === "USDT"
                              ? "depo_nav_active"
                              : "depo_nav_notactive"
                          }
                          onClick={() => {
                            setCurrency("USDT");
                            coinTyperef.current = "USDT";
                          }}
                        >
                          <img
                            src={require("../assests/usdt-logo.png")}
                            alt="USDT"
                            className="logout-img"
                          />
                          <span>USDT</span>
                        </div>
                      </div>
                    </div>
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">
                        Select Network
                      </span>
                      <Select
                        options={networkOptions}
                        styles={customStyles}
                        key={dropdownKey}
                        onChange={(e) => setNetwork(e.value)}
                        className="withdraw-dropdown-menu"
                      />
                    </div>
                    <div className="depo_in_notesdiv">
                      <div className="depoin_notes_icon">
                        <i class="bi bi-exclamation-triangle depo_note_ico"></i>
                      </div>
                      <div className="depo_notes_defspan">
                        Only deposit {coinTyperef?.current || 'your currency'} to the {network || 'correct'} network.
                        Depositing any other cryptocurrency or using a different network may result in the permanent loss of your funds.
                      </div>

                    </div>
                    <div className="depo_inner_divmain">
                      <span className="home-viewasset-span">
                        Network Address
                      </span>
                      <div className="depo_qr_maindiv">
                        {addressData ? (
                          <>
                            <div className="depo_qr_imgmain">
                              <div className="depo_qr_imgsize">
                                <img
                                  src={addressData.qrcode}
                                  alt="QR code"
                                  className="QR-depo-img"
                                />
                              </div>
                              <span className="scan_or_enter">
                                Scan the QR code or copy the address to deposit
                              </span>
                            </div>
                            <div className="depo_add_dis">
                              <span className="address_span">
                                {addressData.address}
                              </span>
                              <i
                                className="ri-file-copy-line cursor-pointer add_depo_copy"
                                onClick={() =>
                                  handleCopyAdd(addressData.address)
                                }
                              ></i>
                            </div>
                            {/* {totalAmount.map((res, index) => (
                              <div className="depo_add_disbal" key={index}>
                                <span className="address_spanbalance">{'Available Balance'}</span>
                                <span className="address_balancedata">{coinTyperef.current === res.currency ? res.amount : 0}</span>
                              </div>
                            ))} */}

                          <div className="depo_add_disbal">
                            <span className="address_spanbalance">{'Available Balance'}</span>
                            <span className="address_balancedata">{addressData.balance}</span>
                          </div>
                           
                          </>
                        ) : (
                          <div className="loader_main loader_mainChange">
                            <ScaleLoader color="#36d7b7" height={10} width={3} />
                            <p>Loading...</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="container m-top-20 p-0">
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="faq">
                        <h5 className="faq-title">FAQs</h5>
                        {faqs.length > 0 ? (
                          faqs.map((faq, index) => (
                            <div className="accordion-item font-satoshi" key={faq._id}>
                              <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-collapse-${index}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-collapse-${index}`}
                                >
                                  {index + 1}. {faq.question}
                                  <span className="icon">
                                    <i className="fas fa-chevron-down"></i>
                                  </span>
                                </button>
                              </h2>
                              <div
                                id={`flush-collapse-${index}`}
                                className="accordion-collapse collapse"
                                aria-labelledby={`flush-heading-${index}`}
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {faq.status === "Active" ? faq.answer : "Content not available."}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className="faq_cate_span">No FAQs available for Deposit.</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recent_depo_main">
                <div className="manage_btn_row">
                  <span className="home_right_marketspan">Recent Deposit</span>
                  <Link to="/deposithistory">
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <span className="home_starty_view">View All</span>
                      <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                    </div>
                  </Link>
                </div>
                <div className="recent_depo_tablemain pb-0">
                  <div className="table-responsive table-borderless home_recent_table">
                    {loading ? (
                      <div className="loader_main loader_mainChange">
                        <ScaleLoader color="#36d7b7" height={10} width={3} />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text_left">Currency</th>
                            <th>Date & Time</th>
                            <th>Network</th>
                            <th>Amount</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions.length > 0 ? (
                            transactions.slice(0, 5).map((transaction, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="home_recent_firstdata">
                                    <img
                                      src={require("../assests/usdt-logo.png")}
                                      alt="USDT"
                                      className="usdt_home_recent"
                                    />
                                    <span>{transaction.currency}</span>
                                  </div>
                                </td>
                                <td>
                                  <span>
                                    {isNaN(new Date(transaction.date))
                                      ? "null"
                                      : dayjs(transaction.date).format(
                                        "DD MMMM YYYY, hh:mm A"
                                      )}
                                  </span>
                                </td>
                                <td>
                                  <span>{transaction.network}</span>
                                </td>
                                <td>
                                  <span>{transaction.amount}</span>
                                </td>
                                <td>
                                  <div className="home_uid_main">
                                    <span>{transaction.transactionId}</span>
                                    <i
                                      className="ri-file-copy-line cursor-pointer uid_copy_recent"
                                      onClick={() => {
                                        handleCopy(transaction.transactionId);
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">
                                <span className="depo_no_record">
                                  No transactions found
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
