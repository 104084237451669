import React from "react";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../services/authservices";
// import { useState } from 'react';

function SecurityLoginpass() {
  const [currentpasswordVisible, setcurrentPasswordVisible] = useState(false);
  const [newpasswordVisible, setnewPasswordVisible] = useState(false);
  const [toastVisible , setToastVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [buttonLoader,setButtonLoader] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let newErrors = { ...errors };

    if (name === "currentPassword") {
      if (!value) newErrors.currentPassword = "Current Password is required";
      else delete newErrors.currentPassword;
    } else if (name === "newPassword") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,15}$/;
      if (!value) newErrors.newPassword = "New Password is required";
      else if (!passwordRegex.test(value))
        newErrors.newPassword =
          "Password must be 12-15 characters long, include uppercase, lowercase, number, and special character";
      else if (value === formData.currentPassword)
        newErrors.newPassword =
          "New Password cannot be the same as Current Password";
      else delete newErrors.newPassword;
    } else if (name === "confirmPassword") {
      if (!value) newErrors.confirmPassword = "Confirm Password is required";
      else if (value !== formData.newPassword)
        newErrors.confirmPassword = "Passwords do not match";
      else delete newErrors.confirmPassword;
    }

    setErrors(newErrors);
  };

  const handleKeyDown = (e, type) => {
    if (type === "password") {
      if (
        e.key === " " ||
        (e.target.value.length >= 15 && e.key !== "Backspace")
      ) {
        e.preventDefault();
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,15}$/;

    if (!formData.currentPassword) {
      newErrors.currentPassword = "Current Password is required";
      return newErrors;
    }

    if (!formData.newPassword) {
      newErrors.newPassword = "New Password is required";
      return newErrors;
    } else if (!passwordRegex.test(formData.newPassword)) {
      newErrors.newPassword =
        "Password must be 12-15 characters long, include uppercase, lowercase, number, and special character";
      return newErrors;
    } else if (formData.currentPassword === formData.newPassword) {
      newErrors.newPassword =
        "New Password cannot be the same as Current Password";
      return newErrors;
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
      return newErrors;
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      return newErrors;
    }

    return newErrors;
  };

   const showErrorToast = (message) => {
              if (!toastVisible) {
                setToastVisible(true); // Set toast as visible
                toast.error(
                  <div className="toast-ml">
                    <h4 className="toast-title">Error!</h4>
                    <p className="toast-message">{message}</p>
                  </div>,
                  {
                    onClose: () => setToastVisible(false), // Reset toastVisible on close
                    autoClose: 3000, // Optional: auto-close after 3 seconds
                  }
                );
              }
            };
          
            const showSuccessToast = (message) => {
              if (!toastVisible) {
                setToastVisible(true);
                toast.success(
                  <div className="toast-ml">
                    <h4 className="toast-title">Success!</h4>
                    <p className="toast-message">{message}</p>
                  </div>,
                  {
                    onClose: () => setToastVisible(false), // Reset state on close
                    autoClose: 3000, // Auto-close after 3 seconds
                  }
                );
              }
            };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      setButtonLoader(true);
      const response = await authService.changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });
      if (response.status) {
        setButtonLoader(false);
        const maskedPassword =
          formData.newPassword.charAt(0) +
          "*".repeat(formData.newPassword.length - 1);
        localStorage.setItem("maskedPassword", maskedPassword);
        // setTimeout(() => {
          navigate("/login", {
            state: { passwordUpdated: true, newPassword: formData.newPassword },
          });
        // }, 1000);

        showsuccessToast(
          <div className="toast-ml">
            <h4 className="toast-title">Success!</h4>
            <p className="toast-message">
              Password reset successfully, please login!
            </p>
          </div>
        );
      } else {
        if (response.Message === "TokenExpired") {
          setButtonLoader(false);
          showerrorToast(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">
                Session expired. Please log in again.
              </p>
            </div>
          );
        } else if (response.Message === "Current password is incorrect") {
          setButtonLoader(false);
          setErrors({ currentPassword: "Current Password is incorrect" });
        } else {
          setButtonLoader(false);
          showerrorToast(
            <div className="toast-ml">
              <h4 className="toast-title">Error!</h4>
              <p className="toast-message">{response.Message}</p>
            </div>
          );
          setErrors({ currentPassword: response.Message });
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "TokenExpired"
      ) {
        setButtonLoader(false);
        showerrorToast(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">
              Session expired. Please log in again.
            </p>
          </div>
        );
      } else {
        setButtonLoader(false);
        showerrorToast(
          <div className="toast-ml">
            <h4 className="toast-title">Error!</h4>
            <p className="toast-message">
              An error occurred. Please try again.
            </p>
          </div>
        );
      }
    }
  };

    const showsuccessToast = (message) => {
      toast.dismiss();
      toast.success(message);
    };
  
    const showerrorToast = (message) => {
      toast.dismiss();
      toast.error(message);
    };

  const togglePasswordVisibility = () => {
    setcurrentPasswordVisible(!currentpasswordVisible);
  };
  const togglenewPasswordVisibility = () => {
    setnewPasswordVisible(!newpasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  return (
    <div>
      <section>
        <Header />
      </section>
      <div className="container">
        <div className="row prof_row_sm">
          <div className="col-lg-2 dispaly_none_1200">
            <Sidebar />
          </div>
          <div className="col-xl-10 col-lg-12 deposit_pad_top">
            <div className="security_with_topmain">
              <Link to="/security">
                <span className="Security_center_navinner">
                  Security center
                </span>
              </Link>
              <i class="fa-solid fa-chevron-right Securitycenter_i_navinner "></i>
              <span className=" Change_Password_secwith">Change Password</span>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8 col-xl-6 col-md-8">
                <div className="secwith_main">
                  <span className="change-password">Change Login Password</span>
                  <div className="with_securi_inbotmain">
                    <div className="with_securi_inbotmain_top">
                      <div className="reg_inner_small">
                        <span className="register_spans">
                          {" "}
                          Current Password
                        </span>
                        <div className="reg_pos_rel">
                          <input
                            type={currentpasswordVisible ? "text" : "password"}
                            name="currentPassword"
                            className="reg_input"
                            placeholder="Enter the current password"
                            value={formData.currentPassword}
                            onChange={handleInputChange}
                            onKeyDown={(e) => handleKeyDown(e, "password")}
                          />
                          {errors.currentPassword && (
                            <p className="error">{errors.currentPassword}</p>
                          )}
                          {/* <i class="fa-regular fa-eye reg_eye" ></i> */}
                          <i
                            className={`fa-regular ${
                              currentpasswordVisible ? "fa-eye" : "fa-eye-slash"
                            } reg_eye`}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans"> New Password</span>
                        <div className="reg_pos_rel">
                          <input
                            type={newpasswordVisible ? "text" : "password"}
                            name="newPassword"
                            className="reg_input"
                            placeholder="Enter the new password"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            onKeyDown={(e) => handleKeyDown(e, "password")}
                          />
                          {errors.newPassword && (
                            <p className="error">{errors.newPassword}</p>
                          )}
                          {/* <i class="fa-regular fa-eye reg_eye" ></i> */}
                          <i
                            className={`fa-regular ${
                              newpasswordVisible ? "fa-eye" : "fa-eye-slash"
                            } reg_eye`}
                            onClick={togglenewPasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className="reg_inner_small">
                        <span className="register_spans">
                          {" "}
                          Re-enter the new Password
                        </span>
                        <div className="reg_pos_rel">
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="confirmPassword"
                            className="reg_input"
                            placeholder="Re-enter the new password"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            onKeyDown={(e) => handleKeyDown(e, "password")}
                          />
                          {errors.confirmPassword && (
                            <p className="error">{errors.confirmPassword}</p>
                          )}
                          {/* <i class="fa-regular fa-eye reg_eye" ></i> */}
                          <i
                            className={`fa-regular ${
                              confirmPasswordVisible ? "fa-eye" : "fa-eye-slash"
                            } reg_eye`}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        </div>
                      </div>
                    </div>
                    {buttonLoader == false ? 
                    <div
                      className="bin_bind_submit_div"
                      onClick={handlePasswordChange}
                    >
                      <span className="bin_bin_sub_span">Confirm</span>
                    </div>
                    :
                    <div
                    className="bin_bind_submit_div"
                  >
                    <span className="bin_bin_sub_span">Loading..</span>
                  </div>
}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityLoginpass;
