import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { verifyTFA } from "../services/authservices"; // Import the new verifyTFA function
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TfaLogin() {
  const [tfaCode, setTfaCode] = useState("");
  const navigate = useNavigate();
  const { user, login } = useContext(UserContext);
const [toastVisible , setToastVisible] = useState(false);

 

  const handleTfaSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await verifyTFA(token, tfaCode);
      if (response.status) {
        login(response.token);
        showSuccessToast('Login successful!')
        
        // setTimeout(() => {
          navigate("/home");
        // }, 2000); // 2 seconds delay
      } else {
        alert("Invalid 2FA code. Please try again.");
      }
    } catch (error) {
      console.error("Error during 2FA verification:", error);
      alert("An error occurred during 2FA verification. Please try again.");
    }
  };

  const showSuccessToast = (message) => {
    if (!toastVisible) {
      setToastVisible(true);
      toast.success(
        <div className="toast-ml">
          <h4 className="toast-title">Success!</h4>
          <p className="toast-message">{message}</p>
        </div>,
        {
          onClose: () => setToastVisible(false), // Reset state on close
          autoClose: 3000, // Auto-close after 3 seconds
        }
      );
    }
  };

  return (
    <div>
      <div className="row register_main container-fluid">
        <div className="col-lg-6">
          <div className="d-flex flex-column mb-5">
            <div className="register_head_logo">
              <Link to="/">
                <img
                  src={require("../assests/Logo.webp")}
                  alt="Logo"
                  className="register_logo cursor-pointer"
                />
              </Link>
            </div>
            <div className="register_left_main mt-4">
              <div className="hero_center">
                <div className="register_form">
                  <span className="register_head_sign">2FA</span>
                  <form onSubmit={handleTfaSubmit}>
                    {" "}
                    {/* Add form submission handler */}
                    <div className="register_inner_main">
                      <div className="getting_card_content w-100">
                        <div className="register_inner_submain w-100">
                          <div className="reg_inner_small">
                            <span className="register_spans">2FA Code</span>
                            <input
                              type="text"
                              placeholder="Enter the 2FA code"
                              className="reg_input"
                              value={tfaCode}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 6) {
                                  setTfaCode(value);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="getting_card_content">
                        <button type="submit" className="reg_btn">
                          {" "}
                          {/* Change to button element */}
                          <span className="hero_invest">Submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <span className="reg_bottom_span">
                  <Link to="/login">
                    <span className="reg_check_span">Back To Login</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 tfa_right_main">
          <div className="login_right">
            <div className="footer_links_div">
              <span className="register_head">Enter Your 2FA Code!</span>
              <span className="register_subhead">
                To secure your account, enter your 2FA code which has <br />{" "}
                sent to your registered email address
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TfaLogin;
