import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ScaleLoader } from 'react-spinners'
import Header from "./Header";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import ApiService from "../services/authservices";
function Refferal() {
    const [referralHistory, setReferralHistory] = useState([]);
    const [currentPageRef, setCurrentPageRef] = useState(1);
    const [totalPagesRef, setTotalPagesRef] = useState(0);
    const [limitRef, setLimitRef] = useState(10); 
    const [loading, setLoading] = useState(true);


     useEffect(() => {
        fetchReferralHistory(currentPageRef,limitRef);
      }, [currentPageRef,limitRef]);
    
      const handlePageChangeRef = (event, value) => {
        setCurrentPageRef(value);
    };

      
    const copyToClipboard = (() => {
        let toastVisible = false; 
    
        return (text) => {
            if (!text) {
                console.warn("No text provided for copying.");
                return;
            }
    
            if (toastVisible) return; 
    
            if (copyToClipboard.timeout) clearTimeout(copyToClipboard.timeout);
    
            copyToClipboard.timeout = setTimeout(() => {
                navigator.clipboard
                    .writeText(text)
                    .then(() => {
                        toastVisible = true; 
                        toast.success(
                            <div className="toast-ml">
                                <h4 className="toast-title">Success!</h4>
                                <p className="toast-message">Copied to clipboard!</p>
                            </div>,
                            {
                                onClose: () => {
                                    toastVisible = false; 
                                },
                            }
                        );
                    })
                    .catch((err) => {
                        console.error("Failed to copy text:", err);
                        toastVisible = true; 
                        toast.error(
                            <div className="toast-ml">
                                <h4 className="toast-title">Error!</h4>
                                <p className="toast-message">Failed to copy text</p>
                            </div>,
                            {
                                onClose: () => {
                                    toastVisible = false; 
                                },
                            }
                        );
                    });
            }, 300); 
        };
    })();
    

    const [user, setUser] = useState({
        tfaStatus: 0,
        email: null,
        uid: null,
        referralCode: null,
        referralUrl: null,
        displayname: null,
        country: null,
        referralCount: null,
        directReferralCount: null,
        indirectReferralCount: null,
        isAuthenticated: false,
    });
    const fetchUserDetails = async () => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const data = await ApiService.getUserDetails(token);
                if (data && data.status) {
                    const userDetails = {
                        isAuthenticated: true,
                        tfaStatus: data.tfastatus,
                        email: data.email,
                        displayname: data.displayname,
                        country: data.country,
                        uid: data.uid,
                        referralCode: data.referralCode,
                        referralUrl: data.referralUrl,
                        referralCount: data.referralCount,
                        directReferralCount: data.directReferralCount,
                        indirectReferralCount: data.indirectReferralCount,
                    };
                    setUser(userDetails);
                } else {
                    setUser({
                        isAuthenticated: false,
                        tfaStatus: 0,
                        email: null,
                        uid: null,
                        referralCode: null,
                        referralUrl: null,
                        referralCount: null,
                        directReferralCount: null,
                        indirectReferralCount: null,
                    });
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
                setUser({
                    isAuthenticated: false,
                    tfaStatus: 0,
                    email: null,
                    uid: null,
                    referralCode: null,
                    referralUrl: null,
                    referralCount: null,
                    directReferralCount: null,
                    indirectReferralCount: null,
                });
            }
        } else {
            setUser({
                isAuthenticated: false,
                tfaStatus: 0,
                email: null,
                uid: null,
                referralCode: null,
                referralUrl: null,
                referralCount: null,
                directReferralCount: null,
                indirectReferralCount: null,
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);



     const fetchReferralHistory = async (page, limit) => {
        try {
          setLoading(true);
          const data = await ApiService.getReferralHistory(page, limit);
          if (data && data.status && Array.isArray(data.referralHistory)) {
            setReferralHistory(data.referralHistory);
            setTotalPagesRef(Math.ceil(data.pagination.totalPages / limit));
            setLoading(false);
          } else {
            setReferralHistory([]);
            setLoading(false)
          }
        } catch (error) {
          console.error("Error fetching referral history:", error);
          setReferralHistory([]);
          setLoading(false)
        }
      };

    return (
        <div className="recent_depo_main">
            <div className="row">
                <div className="manage_btn_row">
                    <span className="home_botstatus_span"> Referral History</span>
                    <Link to="/referral">
                        <div className="d-flex align-items-center gap-2 cursor-pointer">
                            <span className="home_starty_view">Back to Referral & Earn</span>
                            <i class="fa-solid fa-chevron-right home_starty_viewarrow"></i>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-12 p-0">
                    <div className="refer_third_main pb-0">

                        <div className="table-responsive table-borderless home_recent_table">
                            {loading ? (
                                <div className="loader_main loader_mainChange">
                                    <ScaleLoader color="#36d7b7" height={10} width={3} />
                                    <p>Loading...</p>
                                </div>
                            ) : (
                                <>
                                  <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text_left">SI.no</th>
                                            <th>User Name</th>
                                            <th className="text_right">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referralHistory.length > 0 ? (
                                            referralHistory.map((referral, index) => (
                                                <tr key={referral.uuid}>
                                                    <td className="text_left">
                                                        <span>{index + 1}</span>
                                                    </td>
                                                    <td>
                                                        <span>{referral.displayname}</span>
                                                    </td>
                                                    <td className="text_right">
                                                        <span>
                                                            {new Date(referral.createdAt).toLocaleString()}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    <span className="depo_no_record">
                                                        No referral history available.
                                                    </span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {totalPagesRef > 1 && (
                                                                                  <div className="pagination history_pagination_all ">
                                                                                      <Stack spacing={2}>
                                                                                          <Pagination
                                                                                              count={totalPagesRef}
                                                                                              page={currentPageRef}
                                                                                              onChange={handlePageChangeRef}
                                                                                              size="small"
                                                                                              sx={{
                                                                                                "& .MuiPaginationItem-root": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .Mui-selected": {
                                                                                                    backgroundColor: "#ffc630 !important",
                                                                                                    color: "#000",
                                                                                                    "&:hover": {
                                                                                                        backgroundColor: "#ffc630",
                                                                                                        color: "#000",
                                                                                                    },
                                                                                                },
                                                                                                "& .MuiPaginationItem-ellipsis": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                                "& .MuiPaginationItem-icon": {
                                                                                                    color: "#fff",
                                                                                                },
                                                                                            }}
                                                                                          />
                                                                                      </Stack>
                                                                                  </div>
                                                                              )}
                                </>
                              
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Refferal;