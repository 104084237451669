import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useNavigate } from "react-router-dom";
import { verifyAuth } from "../services/authservices";

function Landing() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const advanceSectionRef = useRef(null);
  const [userAuth, setAuth, userAuthref] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (advanceSectionRef.current) {
        const sectionTop = advanceSectionRef.current.offsetTop;
        const sectionHeight = advanceSectionRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    checkuserAuth();
  }, [0]);

  const checkuserAuth = async () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      try {
        const data = await verifyAuth(token);
        if (data && data.status) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (error) {
        console.error("Error auth user details:", error);
        setAuth(false);
      }
    } else {
      setAuth(false);
    }
  };

  const nav_page = () => {
    if(!userAuthref.current){
      navigate("/register");
    }else{
      navigate("/home");
    }
  };

  return (
    <>
      <main className="wyscale_landing_main">
        <section>
          <Header />
        </section>
        <section className="hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-xl-6">
                <div className="hero_top" data-aos="fade-up">
                  <div className="hero_top_main">
                    <span className="hero_top_main_span">
                      Effortless Profits Await
                    </span>
                  </div>
                  <div className="hero_center">
                    <div className="hero_top">
                      <span className="hero_main">
                        Automate Your Crypto Trades with AI Precision
                      </span>
                      <span className="hero_submain">
                        Say farewell to manual trading and embrace smarter
                        decisions. Wyscale's AI-powered bots optimize your
                        strategies, execute trades in real-time, and adapt to
                        market changes to ensure you stay ahead. Automate
                        success and trade like a pro!
                      </span>
                    </div>
                    {/* {!userAuthref.current ? ( */}
                      <div class="hero-btn" onClick={nav_page}>
                        <span class="invest_now">
                          Explore Wyscale Bots
                        </span>
                        <span class="invest_new">
                          Explore Wyscale Bots
                        </span>
                      </div>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hero_sub_section">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 col-xl-8 hero_sub_back">
                <img
                  src={require("../assests/hero-img-copy.webp")}
                  className="hero_img"
                  alt="Trade-view"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="card_section smarter_new-section">
          <div className="container">
            <div className="card_top_main">
              <div className="">
                <img
                  src={require("../assests/whyWyscale-left.png")}
                  alt="Why WYscale description"
                  className="wyWyscale-left"
                  data-aos="fade-right"
                />
              </div>
              <div className="card_top_center">
                <div className="card_center_top">
                  <span className="card_center_topspan">Smarter, Faster</span>
                </div>
                <span className="card_top_content" data-aos="fade-up">
                  Why Wyscale? <br /> Master Your Trades
                </span>
              </div>
              <div className="">
                <img
                  src={require("../assests/whyWyscale-right.png")}
                  alt="Why WYscale description"
                  className="wyWyscale-right"
                  data-aos="fade-left"
                />
              </div>
            </div>
            <div className="card_sub_main">
              <div className="card_first_main">
                <div className="card_first_top" data-aos="fade-up">
                  <img
                    src={require("../assests/card-first.png")}
                    alt="Automated Trading"
                    className="card_first_img"
                  />
                  <div className="card_first_content">
                    <span className="card_first_head">
                      24/7 Automated Trading
                    </span>
                    <span className="card_first_subhead">
                      Wyscale works around the clock, ensuring your <br />{" "}
                      trades are managed even when you're offline.
                    </span>
                  </div>
                </div>
                <img
                  src={require("../assests/card-first-main.webp")}
                  alt="Automated Trading"
                  className="card_first_mainimg"
                />
              </div>
              <div className="card_second_main">
                <div className="card_second_first">
                  <div className="card_scndfirst_top" data-aos="fade-up">
                    <img
                      src={require("../assests/card-second-first.png")}
                      alt="User friendly Interface"
                      className="card_first_img"
                    />
                    <div className="card_first_content">
                      <span className="card_first_head">
                        User-Friendly Interface
                      </span>
                      <span className="card_first_subhead">
                        Seamless and intuitive, Wyscale makes trading <br />{" "}
                        simple for both beginners and experts.
                      </span>
                    </div>
                  </div>
                  <div className="card_scndfrst_sub">
                    <div className="card_scndfrst_subfrst">
                      <div className="card_scndfrst_subfrst_inner">
                        <img
                          src={require("../assests/card-secndfirst-first.webp")}
                          alt="ard description"
                          className="card_secondfirst_img"
                        />
                      </div>
                    </div>
                    <div className="card_scndfrst_subcenter">
                      <div className="card_scndfrst_subfrst_inner">
                        <img
                          src={require("../assests/card-secndfirst-second.webp")}
                          alt="ard description"
                          className="card_secondfirst_img"
                        />
                      </div>
                    </div>
                    <div className="card_scndfrst_subfrst">
                      <div className="card_scndfrst_subfrst_inner">
                        <img
                          src={require("../assests/card-secndfirst-third.webp")}
                          alt="ard description"
                          className="card_secondfirst_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card_second_second">
                  <div className="card_scndfirst_top">
                    <img
                      src={require("../assests/card-second-second.png")}
                      alt="User friendly Interface"
                      className="card_first_img"
                    />
                    <div className="card_first_content">
                      <span className="card_first_head">
                        Customizable Strategies
                      </span>
                      <span className="card_first_subhead">
                        Customize strategies for your trading goals,
                        <br /> suitable for novices and experts alike.
                      </span>
                    </div>
                  </div>
                  <div className="card_firstscnd_content">
                    <div className="card_scndscnd_subfrst">
                      <div className="scnd_scnd_subfrst">
                        <div className="card_scndfrst_sub">
                          <img
                            src={require("../assests/card-scnd-bot.png")}
                            alt="Bot status"
                            className="card_scnd_bot"
                          />
                          <span className="card_first_subhead">DCA</span>
                        </div>
                        <img
                          src={require("../assests/card-scnd-toggle.png")}
                          alt="Bot toggle"
                          className="scnd_toggle_bot"
                        />
                      </div>
                      <div className="scnd_scnd_subfrst">
                        <div className="card_scndfrst_sub">
                          <img
                            src={require("../assests/card-scnd-bot.png")}
                            alt="Bot status"
                            className="card_scnd_bot"
                          />
                          <span className="card_first_subhead">INTRA</span>
                        </div>
                        <img
                          src={require("../assests/card-scnd-toggle.png")}
                          alt="Bot toggle"
                          className="scnd_toggle_bot"
                        />
                      </div>
                    </div>
                    <div className="card_scndscnd_subscnd">
                      <div className="scnd_scnd_subfrst">
                        <div className="card_scndfrst_sub">
                          <img
                            src={require("../assests/card-scnd-bot.png")}
                            alt="Bot status"
                            className="card_scnd_bot"
                          />
                          <span className="card_first_subhead">Triangular</span>
                        </div>
                        <img
                          src={require("../assests/card-scnd-toggle.png")}
                          alt="Bot toggle"
                          className="scnd_toggle_bot"
                        />
                      </div>
                      <div className="scnd_scnd_subfrst">
                        <div className="card_scndfrst_sub">
                          <img
                            src={require("../assests/card-scnd-bot.png")}
                            alt="Bot status"
                            className="card_scnd_bot"
                          />
                          <span className="card_first_subhead">Arbitrage</span>
                        </div>
                        <img
                          src={require("../assests/card-scnd-toggle.png")}
                          alt="Bot toggle"
                          className="scnd_toggle_bot"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div ref={advanceSectionRef}>
          <section className="getting_section">
            <div className="container">
              <div className="row d-flex justify-content-center w-100 m-0">
                <div className="col-lg-9 getting-card-back">
                  <div className="getting_main">
                    <div className="getting_top" data-aos="fade-up">
                      <div className="card_top_center">
                        <div className="hero_top_main">
                          <span className="hero_top_main_span">
                            Steps To Start
                          </span>
                        </div>
                        <span className="getting_head">How It Works</span>
                      </div>
                      <span className="hero_submain hero_mb-10">
                        Our platform makes crypto trading simple and efficient
                        with a fully automated AI-powered system. Follow these
                        easy steps to get started and let our advanced trading
                        algorithms work for you:
                      </span>
                    </div>
                    <div className="row w-100 m-0">
                      <div className="col-lg-4 p-0">
                        <div className="getting_card">
                          <div class="corner_box"></div>
                          <div class="corner_box_second"></div>
                          <div class="corner_box_third"></div>
                          <div class="corner_box_fourth"></div>
                          <div className="getting_card_num" data-aos="fade-up">
                            <span className="getting_card_numcontent">1</span>
                          </div>
                          <div
                            className="getting_card_content"
                            data-aos="fade-up"
                          >
                            <span className="getcard_sub_head">
                              Step 01 - Create Account
                            </span>
                            <span className="getcard_sub_subhead">
                              Get started by creating your account in just a few
                              clicks using your email.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="getting_card_backsecond">
                          <div className="getting_card border_bottom_none">
                            <div
                              className="getting_card_num"
                              data-aos="fade-up"
                            >
                              <span className="getting_card_numcontent">2</span>
                            </div>
                            <div
                              className="getting_card_content"
                              data-aos="fade-up"
                            >
                              <span className="getcard_sub_head">
                                Step 02 - Deposit Amount
                              </span>
                              <span className="getcard_sub_subhead">
                                Securely fund your wallet with crypto and
                                prepare for automated trading.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="getting_card border_right_none">
                          <div class="corner_box"></div>
                          <div class="corner_box_second"></div>
                          <div class="corner_box_third"></div>
                          <div class="corner_box_fourth"></div>
                          <div className="getting_card_num" data-aos="fade-up">
                            <span className="getting_card_numcontent">3</span>
                          </div>
                          <div
                            className="getting_card_content"
                            data-aos="fade-up"
                          >
                            <span className="getcard_sub_head">
                              Step 03 - Enable Exchange
                            </span>
                            <span className="getcard_sub_subhead">
                              Connect your preferred exchange by adding your API
                              and secret keys.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row w-100 m-0">
                      <div className="col-lg-4 p-0">
                        <div className="getting_card">
                          <div class="corner_box"></div>
                          <div class="corner_box_second"></div>
                          <div class="corner_box_third"></div>
                          <div class="corner_box_fourth"></div>
                          <div className="getting_card_num" data-aos="fade-up">
                            <span className="getting_card_numcontent">4</span>
                          </div>
                          <div
                            className="getting_card_content"
                            data-aos="fade-up"
                          >
                            <span className="getcard_sub_head">
                              Step 04 - Configure Strategy
                            </span>
                            <span className="getcard_sub_subhead">
                              Customize trading strategies that align with your
                              goals and risk tolerance.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="getting_card_backsecond">
                          <div className="getting_card border_bottom_none">
                            <div
                              className="getting_card_num"
                              data-aos="fade-up"
                            >
                              <span className="getting_card_numcontent">5</span>
                            </div>
                            <div
                              className="getting_card_content"
                              data-aos="fade-up"
                            >
                              <span className="getcard_sub_head">
                                Step 05 - Activate Bot
                              </span>
                              <span className="getcard_sub_subhead">
                                Turn on your trading bot, manage your portfolio,
                                and let it work for you.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 p-0">
                        <div className="getting_card border_right_none">
                          <div class="corner_box"></div>
                          <div class="corner_box_second"></div>
                          <div class="corner_box_third"></div>
                          <div class="corner_box_fourth"></div>
                          <div className="getting_card_num" data-aos="fade-up">
                            <span className="getting_card_numcontent">6</span>
                          </div>
                          <div
                            className="getting_card_content"
                            data-aos="fade-up"
                          >
                            <span className="getcard_sub_head">
                              Step 06 - Withdraw Profits
                            </span>
                            <span className="getcard_sub_subhead">
                              Seamlessly transfer your profits anytime with a
                              few simple steps.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="landing-advanced-section">
            <div className="container">
              <div className="advance_border">
                <div className="advance_main">
                  <div className="row">
                    <div className="col-lg-6 p-0">
                      <div className="advance_left_main">
                        <div className="getting_card_content">
                          <span className="getting_head text_left">
                            Advanced Trading Bots Designed to Surpass Human
                            Precision
                          </span>
                          <span className="hero_submain text_left">
                            With a stunning 4-year ROI of 7035.8% and an
                            annualized ROI of +188.53%, Wyscale’s automated
                            trading delivers exceptional results. In Q1 2025, it
                            achieved a 70.6% ROI, with a 53.39% win rate and top
                            trades earning up to 57.19%. Wyscale is engineered
                            to elevate your profits.
                          </span>
                        </div>
                        {/* {!userAuthref.current ? ( */}
                          <div class="hero-invest-btn" onClick={nav_page}>
                            <span class="invest_now">
                              Invest now
                            </span>
                            <span class="invest_new">
                              Invest now
                            </span>
                          </div>
                        {/* ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div className="col-lg-6 p-0">
                      <div className="advance_right_main">
                        <div
                          className="advance_scroll_images"
                          style={{
                            transform: `translateY(${-offset * 0.6}px)`,
                          }}
                        >
                          <img
                            src={require("../assests/advance-right-scroll-third.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-scnd.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-third.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-scnd.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-third.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-scnd.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                          <img
                            src={require("../assests/advance-right-scroll-third.webp")}
                            alt="Advance trading"
                            className="advance_right_img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="manage_section">
          <div className="container">
            <div className="manage_main">
              <div className="card_top_center">
                <div className="card_center_top">
                  <span className="card_center_topspan">All In One Place</span>
                </div>
                <div className="getting_top" data-aos="fade-up">
                  <span className="card_top_content">
                    Manage exchanges seamlessly.
                  </span>
                  <span className="manage_subhead">
                    Connect your exchange accounts via the official Fast Connect
                    integration or manually <br /> using the API keys
                  </span>
                </div>
              </div>
              <div className="manage_button_main">
                <div className="manage_btn_row" data-aos="fade-up">
                  <div class="button_container button-varient1">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/binance-logo.webp")}
                        alt="Binance"
                        className="binanace_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient2">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/kucoin-logo.webp")}
                        alt="kucoin"
                        className="binanace_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient3">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/okx-logo.webp")}
                        alt="okx"
                        className="okx_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient4">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/gateio-logo.webp")}
                        alt="gateio"
                        className="binanace_logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="manage_btn_row" data-aos="fade-up">
                  <div class="button_container button-varient5">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/bitget-logo.webp")}
                        alt="bitget"
                        className="bitget_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient6">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/bitmart-logo.webp")}
                        alt="bitmart"
                        className="bitget_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient7">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/mexc-logo.webp")}
                        alt="mexc"
                        className="mexc_logo"
                      />
                    </div>
                  </div>
                  <div class="button_container button-varient8">
                    <div class="button_border"></div>
                    <div class="button_cuboid">
                      <img
                        src={require("../assests/bybit-logo.webp")}
                        alt="bybit"
                        className="mexc_logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footgif_section">
          <div className="manage_button_main align-center">
            <span className="hero_main" data-aos="fade-up">Profit Your Way with Wyscale!</span>
            <div className="footgif_main">
              <div className="footgif_invest w-100 d-flex justify-content-center" data-aos="fade-up">
                {/* {!userAuthref.current ? ( */}
                  <div class="hero-invest-btn" onClick={nav_page}>
                    <span class="invest_now">
                      Invest now
                    </span>
                    <span class="invest_new">
                      Invest now
                    </span>
                  </div>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </section>

        <section className="footer_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="getting_card_content">
                  <img
                    src={require("../assests/Logo.webp")}
                    alt="logo"
                    className="footer_logo"
                  />
                  <span className="getcard_sub_subhead padding-right">
                    Unlock the Power of Automated Crypto Trading with Wyscale.
                    Leverage cutting-edge algorithms, AI-driven strategies, and
                    smooth API integration across top exchanges. Enjoy 24/7
                    trading automation while maintaining complete control over
                    your assets. Begin your journey to smarter trading with
                    Wyscale!
                  </span>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Quick Links</span>
                  <div className="footer_links_div">
                    <Link to="/home">
                      <span className="footer_links_span">Home</span>
                    </Link>
                    {/* <Link to="">
                      <span className="footer_links_span">Portfolio</span>
                    </Link> */}
                    <Link to="/strategies">
                      <span className="footer_links_span">Strategies</span>
                    </Link>
                    <Link to="/assets">
                      <span className="footer_links_span">Assets</span>
                    </Link>
                    <Link to="/referral">
                      <span className="footer_links_span">Refer & Earn</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Pages</span>
                  <div className="footer_links_div">
                    <Link to="/termscondition" target="_blank">
                      <span className="footer_links_span">Terms and Conditions</span>
                    </Link>
                    <Link to="/privacypolicy" target="_blank">
                      <span className="footer_links_span">Privacy Policy</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">About US</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="footer_second_main">
                  <span className="footer_head">Social Media</span>
                  <div className="footer_links_div">
                    <Link to="">
                      <span className="footer_links_span">LinkedIn</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Instagram</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Facebook</span>
                    </Link>
                    <Link to="">
                      <span className="footer_links_span">Twitter</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="footer_last">
          <span className="footer_copyrights">
            ©2025 WYScale. All Rights Reserved.
          </span>
        </div>
      </main>
    </>
  );
}

export default Landing;
